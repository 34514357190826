import { Box, Grid, Stack, TextField, Typography } from "@mui/material";
import { getPeriodsLabelsFromPeriodsIds } from "../../viewDashboardNew/utils";
import { GridColDef } from "@mui/x-data-grid";
import { IdOption } from "../../configCriteria/types";
import { selectStyles } from "../../configCriteria/select.styles";
import { transformDataPreview } from "../groupManagement/Step2/Step2";
import { useApiQuery } from "../../../hooks/useApiQuery";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../buttons/Button";
import CheckmarkSelect1 from "../../periods/CheckmarkSelect1";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfigOptions from "./ConfigOptions";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import CurrencySelect from "./CurrencySelect";
import Dedynamicize from "./Dedynamicize";
import Dialog from "../../dialog/Dialog";
import DownloadIcon from "@mui/icons-material/Download";
import EditColumnsModal from "./EditColumnsModal";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FilterForm from "./FilterForm";
import FormSelectLayout from "../../forms/FormSelectLayout";
import GetReportConfigModal from "./GetReportConfigModal";
import MeasureAgregationForm from "./MeasureAgregationForm";
import MessageBlock from "../../forms/MessageBlock";
import SaveNameModal from "../../dialog/SaveNameModal";
import Select from "../../forms/Select";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import TablePreview from "./TablePreview";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import useMultiSelect from "../../../hooks/useMultiSelect";
import useSingleSelect from "../../../hooks/useSingleSelect";
import {
  expand_more_icon_styles,
  reset_button_styles,
  title_layout_styles,
} from "../../../styles/app-styles";
import {
  ANALYSIS_URL,
  GET_ANALYSIS_URL,
  INCOMING_VALUES,
  DATA_EXPORT,
  GENERATED_RESOURCES,
  GET_PERIODS_BY_ANALYSIS_URL,
  COMPANY_URL,
  GET_REPORTS_CONFIG_LIST_URL,
  POST_REPORT_CONFIG_URL,
  GET_REPORT_CONFIG_BY_ID_URL,
  DELETE_REPORT_CONFIG_URL,
} from "../../../api/axios";
import {
  convertAnalysisArrayToSelectOptions,
  transformToPeriodsSelectOptions1,
} from "../utils";

interface DownloadReportProps {
  defaultInfo?: any;
  getReportConfigById: any;
  setReportConfig: any;
}

const DownloadReport = ({
  defaultInfo,
  getReportConfigById,
  setReportConfig,
}: DownloadReportProps) => {
  const { t } = useLanguage();
  const navigate = useNavigate();
  const [openConfirmDownloadModal, toggleConfirmDownloadModal] = useDialog();
  const [openSaveNameModal, toggleSaveNameModal] = useDialog();
  const [openItemsListModal, toggleItemsListModal] = useDialog();
  const [openDeleteConfigModal, toggleDeleteConfigModal] = useDialog();
  const [openConfirmationDeleteModal, toggleConfirmationDeleteModal] =
    useDialog();
  const [openEditColumnsModal, toggleEditColumnsModal] = useDialog();
  const [openConfirmUpdateConfigModal, toggleConfirmUpdateConfigModal] =
    useDialog();
  const analysisSelectRef: any = useRef(null);
  const formatSelectRef: any = useRef(null);
  const agrupationColumnsSelectRef: any = useRef(null);
  const aggregationOperatorRef: any = useRef(null);
  const columnFilterSelectedRef: any = useRef(null);
  const periocitySelectRef: any = useRef(null);
  const measuresSelectRef: any = useRef(null);
  const indexColumnsRef: any = useRef(null);
  const desdynamizeColumnsRef: any = useRef(null);
  const [referenceName, setReferenceName] = useState(
    defaultInfo ? defaultInfo.nombre : ""
  );
  const [columnsPreview, setColumnsPreview] = useState<
    GridColDef<any, any, any>[]
  >(
    defaultInfo && defaultInfo.columnas_finales
      ? transformDataPreview(defaultInfo.columnas_finales)?.columns
      : []
  );
  const [incomingValuesOptions, setIncomingValuesOptions] = useState<string[]>(
    []
  );
  const [selectedValues, setSelectedValues] = useState<string[] | null>([]);
  const [valuesSelectedOptions, setValuesSelectedOptions] =
    useState<IdOption[]>();
  const [isLoadingIncomingValuesOptions, setIsLoadingIncomingValuesOptions] =
    useState(false);
  const [updatedColumns, setUpdatedColumns] = useState<any[]>(
    defaultInfo && defaultInfo.columnas_finales
      ? defaultInfo.columnas_finales
      : []
  );
  const [updatedEditableColumns, setUpdatedEditableColumns] = useState<any[]>(
    []
  );

  /**************************** CONFIGURACIONES DE REPORTE ********************************/

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickConfigOptions = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelection = (type: string) => {
    switch (type) {
      case "CARGAR":
        toggleItemsListModal();
        break;
      case "GUARDAR":
        reportConfigId &&
          setReportConfigName(
            getReportsConfigData?.find((el) => el.id == reportConfigId)
              ?.nombre_plantilla
          );
        toggleSaveNameModal();
        break;
      case "ACTUALIZAR":
        setReportConfigName(
          getReportsConfigData?.find((el) => el.id == reportConfigId)
            ?.nombre_plantilla
        );
        toggleConfirmUpdateConfigModal();
        break;
      case "ELIMINAR":
        toggleDeleteConfigModal();
        break;
      default:
        break;
    }
    handleClose();
  };

  const handleAcceptUpdateConfig = () => {
    const completedInfo = prepareInfo();
    putSaveReport(`${POST_REPORT_CONFIG_URL}/${reportConfigId}`, {
      ...completedInfo,
      nombre_plantilla: reportConfigName,
      analisis: analysisSelectedOption?.value,
    });
    toggleConfirmUpdateConfigModal();
  };

  /******************************* FILTROS ***********************************/

  const [filters, setFilters] = useState<any[]>(
    defaultInfo
      ? Object.keys(defaultInfo?.filtros)
          .filter((columna) => columna !== "PERIODO")
          .map((columna) => {
            return {
              id: columna,
              column: { value: columna, label: columna, original: columna },
              values: defaultInfo?.filtros[columna].map((value) => {
                return { value: value, label: value };
              }),
            };
          })
      : []
  );

  const [addedMeasures, setAddedMeasures] = useState<any[]>(
    defaultInfo
      ? Object.keys(defaultInfo?.medidas).map((medida) => {
          return {
            id: Object.keys(defaultInfo?.medidas).length,
            measures: [medida],
            aggregationOperator: {
              value: defaultInfo?.medidas[medida],
              label: defaultInfo?.medidas[medida],
            },
          };
        })
      : []
  );
  const [selectedMeasuresOptions, setSelectedMeasuresOptions] = useState([]);
  const [selectedPeriods, setSelectedPeriods] = useState<number[]>([]);
  const [currencySelectedOptionsState, setCurrencySelectedOptionsState] =
    useState<any>();
  const [reportConfigName, setReportConfigName] = useState("");
  const [itemSelected, setItemSelected] = useState<any>();
  const [desdinamizar, setDesdinamizar] = useState<any>(
    defaultInfo &&
      defaultInfo.desdinamizar &&
      defaultInfo.desdinamizar.columnas_indices &&
      defaultInfo.desdinamizar.columnas_indices.length > 0
      ? true
      : false
  );

  const periocityOptions = [
    { value: "mensual", label: t("dataModelText.downloadReport.monthly") },
    { value: "trimestral", label: t("dataModelText.downloadReport.quarterly") },
    { value: "semestral", label: t("dataModelText.downloadReport.biannual") },
    { value: "anual", label: t("dataModelText.downloadReport.annual") },
  ];
  const [lastChange, setLastChange] = useState<"SELECTION" | "RENAME">(
    "SELECTION"
  );

  /********************* CARGA Y OBTENCIÓN DE DATOS *******************/

  useEffect(() => {
    defaultInfo && localStorage.setItem("reportConfigId", defaultInfo.id);
    setReportConfig(undefined);
  }, []);

  const reportConfigId = localStorage.getItem("reportConfigId");

  const { data: generatedDownloads, isLoading: isLoadingGeneratedDownloads } =
    useApiQuery(
      `${GENERATED_RESOURCES}/?tipo=ANALISIS_DATAEXPORT`,
      true,
      t("taskManager.getDownloads.error"),
      undefined
    );

  const { data: dataGetUserCompany } = useApiQuery(
    COMPANY_URL,
    true,
    t("company.getCompanyError")
  );

  const { data: getReportsConfigData, refetch: refetchGetReportsConfig } =
    useApiQuery(GET_REPORTS_CONFIG_LIST_URL);

  const onSuccessPostVisualizeAnalysis = () => {
    navigate("/descargas-generadas", {
      state: { tab: "DATA EXPORT" },
      replace: false,
    });
  };
  const onSuccessIncomingValuesOptionsResponse = (data: any) => {
    setIncomingValuesOptions(data?.valores);
    setIsLoadingIncomingValuesOptions(false);
  };

  const {
    isLoading: isLoadingPostDownloadAnalysis,
    callApi: postDownloadAnalysis,
  } = useApi(
    "",
    "POST",
    t("dataModel.postDownloadAnalysis.codes"),
    undefined,
    onSuccessPostVisualizeAnalysis,
    undefined,
    true
  );

  const onSuccessSaveReport = () => {
    refetchGetReportsConfig();
  };

  const { isLoading: isLoadingPostSaveReport, callApi: postSaveReport } =
    useApi(
      POST_REPORT_CONFIG_URL,
      "POST",
      undefined,
      onSuccessSaveReport,
      undefined,
      undefined
    );

  const { isLoading: isLoadingPutSaveReport, callApi: putSaveReport } = useApi(
    `${POST_REPORT_CONFIG_URL}/${reportConfigId}`,
    "PUT",
    undefined,
    undefined,
    onSuccessSaveReport,
    undefined,
    undefined
  );

  const { callApi: postGetIncomingValues } = useApi(
    "",
    "POST",
    undefined,
    undefined,
    onSuccessIncomingValuesOptionsResponse,
    undefined,
    false
  );

  const { isLoading: isLoadingGetAnalysis, data: dataGetAnalysis } =
    useApiQuery(ANALYSIS_URL, true, t("dataModel.getAnalysis.error"));

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  const onColumnChange = (data) => {
    setSelectedValues([]);
    setValuesSelectedOptions(undefined);
    handleGetIncomingValues(data);
  };

  const [
    columnFilterSelectedOption,
    setColumnFilterSelectedOptions,
    columnFilterOptionsChangeHandler,
  ] = useSingleSelect((data) => onColumnChange(data));

  const onAnalysisChange = (analysis) => {
    if (analysis) {
      getTotalPeriods(GET_PERIODS_BY_ANALYSIS_URL(analysis?.value));
      getAnalysisById(GET_ANALYSIS_URL(Number(analysis?.value), true));
    }
    handleGetIncomingValues();
    cleanValues(true);
    setLastChange("SELECTION");
  };

  const [
    analysisSelectedOption,
    setAnalysisSelectedOptions,
    analysisOptionsChangeHandler,
  ] = useSingleSelect(
    (analysis) => onAnalysisChange(analysis),
    defaultInfo ? defaultInfo.analisis : undefined
  );

  const [
    formatSelectedOption,
    setFormatSelectedOption,
    formatOptionChangeHandler,
  ] = useSingleSelect(
    undefined,
    defaultInfo ? defaultInfo?.formato_de_archivo : undefined
  );

  const { data: totalPeriodsData, callApi: getTotalPeriods } = useApi(
    GET_PERIODS_BY_ANALYSIS_URL(analysisSelectedOption?.value),
    "GET",
    t("report.getPeriods.codes"),
    undefined,
    undefined,
    undefined,
    false
  );

  useEffect(() => {
    if (analysisSelectedOption) {
      getTotalPeriods(
        GET_PERIODS_BY_ANALYSIS_URL(analysisSelectedOption?.value)
      );
      getAnalysisById(
        GET_ANALYSIS_URL(Number(analysisSelectedOption?.value), true)
      );
    }
    handleGetIncomingValues();
  }, []);

  const onAgrupationChange = (newAgrupationColumnsValues) => {
    handleSetColumns(
      newAgrupationColumnsValues,
      periodicitySelectedOptions,
      addedMeasures
    );
    setLastChange("SELECTION");
  };

  const [
    agrupationColumnsSelectedOptions,
    setAgrupationColumnsSelectedOptions,
    agrupationColumnsOptionsChangeHandler,
  ] = useMultiSelect(
    defaultInfo
      ? defaultInfo.columnas.map((col) => {
          return {
            value: col,
            label: col,
          };
        })
      : undefined,
    (newAgrupationColumnsValues) =>
      onAgrupationChange(newAgrupationColumnsValues)
  );

  const [
    aggregationSelectedOption,
    setAggregationSelectedOption,
    aggregationSelectedOptionChangeHandler,
  ] = useSingleSelect(undefined);

  const onPeriodityChange = (newPeriodicity) => {
    handleSetColumns(
      agrupationColumnsSelectedOptions,
      newPeriodicity,
      addedMeasures
    );
    setLastChange("SELECTION");
  };

  const [
    periodicitySelectedOptions,
    setPeriodicitySelectedOptions,
    periodicityOptionsChangeHandler,
  ] = useSingleSelect(
    (newPeriodicity) => onPeriodityChange(newPeriodicity),
    defaultInfo && defaultInfo.periodicidad
      ? {
          value: defaultInfo.periodicidad,
          label: defaultInfo.periodicidad,
        }
      : undefined
  );

  const onDesdinamizarChange = () => {
    setLastChange("SELECTION");
  };

  const [
    selectedIndexColumns,
    setSelectedIndexColumnsOptions,
    indexColumnsOptionsChangeHandler,
  ] = useMultiSelect(
    defaultInfo &&
      defaultInfo.desdinamizar &&
      defaultInfo.desdinamizar.columnas_indices &&
      defaultInfo.desdinamizar.columnas_indices.length > 0
      ? defaultInfo.desdinamizar.columnas_indices
      : undefined,
    () => onDesdinamizarChange()
  );

  const [
    selectedDesdynamizeColumns,
    setSelectedDesdynamizeColumns,
    desdynamizeColumnsOptionsChangeHandler,
  ] = useMultiSelect(
    defaultInfo &&
      defaultInfo.desdinamizar &&
      defaultInfo.desdinamizar.columnas_indices &&
      defaultInfo.desdinamizar.columnas_indices.length > 0
      ? defaultInfo.desdinamizar.columnas_desdinamizar
      : undefined,
    () => onDesdinamizarChange()
  );

  /************************* VIRTUAL SELECT *************************/

  const handleGetIncomingValues = (data?) => {
    if ((data || columnFilterSelectedOption) && analysisSelectedOption) {
      setIsLoadingIncomingValuesOptions(true);
      const filtros = {};
      filters &&
        filters?.forEach((item) => {
          const columnLabel = item.column.label;
          const values = item.values.map((value) => value.value);
          filtros[columnLabel] = values;
        });
      const filterBody = {
        filtros,
        columna: data?.label || columnFilterSelectedOption?.label,
      };
      postGetIncomingValues(
        INCOMING_VALUES(Number(analysisSelectedOption?.value)),
        filterBody
      );
    } else {
      setIncomingValuesOptions([]);
    }
  };

  /********************* MANEJO DE DATOS - TABLA *******************/

  const periodicityYearColumn = {
    value: t("dataModelText.downloadReport.year"),
    label: t("dataModelText.downloadReport.year"),
  };
  const periodicityColumnsMap = {
    mensual: [
      {
        value: t("dataModelText.downloadReport.month"),
        label: t("dataModelText.downloadReport.month"),
      },
      periodicityYearColumn,
    ],
    trimestral: [{ value: "Q", label: "Q" }, periodicityYearColumn],
    semestral: [{ value: "H", label: "H" }, periodicityYearColumn],
    anual: [periodicityYearColumn],
  };

  const indexColumnsOptions = () => {
    const cols: any[] = [];
    if (
      agrupationColumnsSelectedOptions &&
      agrupationColumnsSelectedOptions.length > 0
    ) {
      cols.push(...agrupationColumnsSelectedOptions);
    }
    if (periodicitySelectedOptions) {
      cols.push(periodicityColumnsMap[periodicitySelectedOptions.value]);
    }
    return cols.flat();
  };

  const desdynamizeColumnsOptions = () => {
    const cols: any[] = [];
    if (addedMeasures && addedMeasures.length > 0) {
      cols.push(
        ...addedMeasures
          .map((measure) =>
            measure.measures.map((m) => {
              return {
                value: m,
                label: m,
              };
            })
          )
          .flat()
      );
    }
    return cols;
  };

  const collectColumns = (
    agrupationColumnsSelectedOptions,
    periodicitySelectedOptions,
    addedMeasures
  ) => {
    const temp: any[] = [];
    if (
      agrupationColumnsSelectedOptions &&
      agrupationColumnsSelectedOptions.length > 0
    ) {
      temp.push(...agrupationColumnsSelectedOptions);
    }
    if (periodicitySelectedOptions) {
      temp.push(...periodicityColumnsMap[periodicitySelectedOptions.value]);
    }
    if (addedMeasures && addedMeasures.length > 0) {
      temp.push(
        ...addedMeasures.flatMap((measureGroup) =>
          measureGroup.measures.map((measure) => ({
            label: measure,
            value: measure,
          }))
        )
      );
    }
    return temp;
  };

  const collectDedymanicizeColumns = () => {
    const dedynamicizeColumns: any[] = [];
    if (selectedIndexColumns && selectedIndexColumns.length > 0) {
      dedynamicizeColumns.push(...selectedIndexColumns);
      dedynamicizeColumns.push({ value: "CATEGORIA", label: "CATEGORIA" });
      dedynamicizeColumns.push({ value: "VALORES", label: "VALORES" });
    }
    return dedynamicizeColumns;
  };

  const transformColumns = (
    agrupationColumnsSelectedOptions,
    periodicitySelectedOptions,
    addedMeasures
  ) => {
    const cols: any =
      updatedColumns && updatedColumns.length > 0 ? [...updatedColumns] : [];
    const temp: any = desdinamizar
      ? collectDedymanicizeColumns()
      : collectColumns(
          agrupationColumnsSelectedOptions,
          periodicitySelectedOptions,
          addedMeasures
        );
    if (cols.length === 0) {
      cols.push(...temp);
    } else {
      if (lastChange === "RENAME") {
        temp.forEach((column) => {
          const index = cols.findIndex(
            (col) => col.value == column.value || col.label === column.label
          );
          if (index === -1) {
            cols.push(column);
          }
        });
      } else if (lastChange === "SELECTION") {
        // En caso de selección, eliminar columnas de cols que no están en temp
        const columnsToRemove: any[] = [];
        const columnsToAdd: any[] = [];

        cols.forEach((column) => {
          const index = temp.findIndex(
            (t) => t.value === column.value || t.label === column.label
          );
          if (index === -1) {
            columnsToRemove.push(column);
          }
        });
        temp.forEach((column) => {
          const index = cols.findIndex(
            (col) => col.value === column.value || col.label === column.label
          );
          if (index === -1) {
            columnsToAdd.push(column);
          }
        });
        columnsToRemove.forEach((column) => {
          const indexToRemove = cols.findIndex(
            (col) => col.value === column.value || col.label === column.label
          );
          if (indexToRemove !== -1) {
            cols.splice(indexToRemove, 1);
          }
        });
        columnsToAdd.forEach((column) => {
          cols.push(column);
        });
      }
    }

    return cols;
  };

  const handleSetColumns = (
    agrupationColumnsSelectedOptions,
    periodicitySelectedOptions,
    addedMeasures
  ) => {
    const cols = transformColumns(
      agrupationColumnsSelectedOptions,
      periodicitySelectedOptions,
      addedMeasures
    );
    if (!defaultInfo) {
      setColumnsPreview(transformDataPreview(cols)?.columns);
    }
    if (!updatedColumns || updatedColumns.length === 0) {
      setUpdatedEditableColumns(cols);
    }
  };

  useEffect(() => {
    handleSetColumns(
      agrupationColumnsSelectedOptions,
      periodicitySelectedOptions,
      addedMeasures
    );
  }, [
    addedMeasures?.length,
    updatedColumns?.length,
    selectedIndexColumns?.length,
    desdinamizar,
  ]);

  const handleOpenEditColumnsModal = () => {
    const cols = transformColumns(
      agrupationColumnsSelectedOptions,
      periodicitySelectedOptions,
      addedMeasures
    );
    if (!desdinamizar) {
      setUpdatedEditableColumns(cols);
    } else {
      const dedynamicizeColumns: any[] = [];
      if (selectedIndexColumns && selectedIndexColumns.length > 0) {
        dedynamicizeColumns.push(...selectedIndexColumns);
        dedynamicizeColumns.push({
          value: "CATEGORIA",
          label: "CATEGORIA",
        });
        dedynamicizeColumns.push({ value: "VALORES", label: "VALORES" });
      }
      setUpdatedEditableColumns(dedynamicizeColumns);
    }
    toggleEditColumnsModal();
  };

  const handleApplyEditColumnsModal = () => {
    setLastChange("RENAME");
    setUpdatedColumns(updatedEditableColumns);
    toggleEditColumnsModal();
  };

  /********************* MANEJO Y LIMPIEZA DE DATOS *******************/

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReferenceName(e.target.value);
  };

  const cleanValues = (analysisSelectedChange: boolean) => {
    if (!analysisSelectedChange) {
      setAnalysisSelectedOptions(undefined);
      analysisSelectRef.current.clearValue();
    }
    setAgrupationColumnsSelectedOptions(undefined);
    agrupationColumnsSelectRef.current.clearValue();
    setPeriodicitySelectedOptions(undefined);
    periocitySelectRef.current.clearValue();
    setSelectedMeasuresOptions([]);
    measuresSelectRef.current?.clearValue();
    setColumnsPreview([]);
    setFilters([]);
    columnFilterSelectedRef?.current?.clearValue();
    setAddedMeasures([]);
  };

  const referenceNameAlreadyExists =
    generatedDownloads &&
    generatedDownloads
      .map((download) => download.nombre_archivo.toLowerCase())
      .includes(`dataexport_${referenceName.toLowerCase()}`);

  const isFormComplete = (type) => {
    switch (type) {
      case "download":
        return (
          analysisSelectedOption &&
          agrupationColumnsSelectedOptions &&
          agrupationColumnsSelectedOptions.length > 0 &&
          addedMeasures.length > 0 &&
          periodicitySelectedOptions &&
          currencySelectedOptionsState &&
          formatSelectedOption &&
          referenceName !== ""
        );
      case "save":
        return (
          analysisSelectedOption &&
          agrupationColumnsSelectedOptions &&
          agrupationColumnsSelectedOptions.length > 0 &&
          addedMeasures.length > 0 &&
          currencySelectedOptionsState &&
          periodicitySelectedOptions &&
          referenceName !== ""
        );
      default:
        return false;
    }
  };

  const prepareInfo = () => {
    const filtros = {};
    filters &&
      filters?.forEach((item) => {
        const columnLabel = item.column.label;
        const values = item.values.map((value) => value.value);
        filtros[columnLabel] = values;
      });
    const transformedMeasures =
      addedMeasures &&
      addedMeasures?.reduce((result, measureGroup) => {
        measureGroup.measures.forEach((measure) => {
          result[measure] = measureGroup.aggregationOperator.label;
        });
        return result;
      }, {});

    const completedInfo = {
      nombre: referenceName,
      columnas: agrupationColumnsSelectedOptions?.map((column) => column.label),
      periodicidad: periodicitySelectedOptions?.value,
      medidas: transformedMeasures,
      formato_de_archivo: formatSelectedOption?.value,
      filtros: {
        ...filtros,
        PERIODO: getPeriodsLabelsFromPeriodsIds(
          selectedPeriods,
          totalPeriodsData
        ),
      },
      monedas: currencySelectedOptionsState?.map((currency) => currency.value),
      columnas_finales:
        updatedColumns && updatedColumns.length > 0
          ? updatedColumns
          : updatedEditableColumns,
      desdinamizar: {
        columnas_indices: selectedIndexColumns?.map((column) => column.label),
        columnas_desdinamizar: selectedDesdynamizeColumns?.map(
          (column) => column.label
        ),
      },
    };
    return completedInfo;
  };

  const handleAcceptDownload = () => {
    const completedInfo = prepareInfo();
    postDownloadAnalysis(
      DATA_EXPORT(Number(analysisSelectedOption?.value)),
      completedInfo
    );
    toggleConfirmDownloadModal();
  };

  const columnLabels =
    dataGetAnalysisById &&
    filters &&
    dataGetAnalysisById?.agrupacion?.columnas_a_usar?.filter((opcion) => {
      return !filters.some((param) => param.column.label === opcion.label);
    });

  const handleSaveReport = () => {
    const completedInfo = prepareInfo();
    if (
      reportConfigId &&
      getReportsConfigData?.find((el) => el.id == reportConfigId)
        ?.nombre_plantilla === reportConfigName
    ) {
      toggleConfirmUpdateConfigModal();
    } else {
      postSaveReport(undefined, {
        ...completedInfo,
        nombre_plantilla: reportConfigName,
        analisis: analysisSelectedOption?.value,
      });
    }
    toggleSaveNameModal();
  };

  const handleSaveReportName = (obj) => {
    setReportConfigName(obj.payload);
  };

  const isValidName = (namesList, value) => {
    const elementNameExists = namesList?.find(
      (el: any) =>
        el?.nombre_plantilla?.trim().toLowerCase() ===
        value?.trim()?.toLowerCase()
    );
    if (elementNameExists) {
      return false;
    } else {
      return true;
    }
  };

  const onSuccessDeleteReportConfig = () => {
    refetchGetReportsConfig();
    toggleConfirmationDeleteModal();
  };

  const {
    isLoading: isLoadingDeleteReportConfig,
    callApi: deleteReportConfigById,
  } = useApi(
    DELETE_REPORT_CONFIG_URL(itemSelected),
    "DELETE",
    undefined,
    undefined,
    onSuccessDeleteReportConfig,
    undefined,
    true
  );

  const handleDeleteItemName = (item) => {
    deleteReportConfigById();
  };

  const handleApply = () => {
    getReportConfigById(GET_REPORT_CONFIG_BY_ID_URL(itemSelected));
    localStorage.setItem("reportConfigId", itemSelected);
    toggleItemsListModal();
  };

  const getCurrencyOptions = () => {
    const currencyOptions: any[] = [];
    if (
      dataGetUserCompany &&
      dataGetUserCompany.moneda_actual &&
      dataGetUserCompany.moneda_actual.value
    ) {
      currencyOptions.push(dataGetUserCompany.moneda_actual);
    }
    if (dataGetUserCompany && dataGetUserCompany.tipos_de_cambio) {
      currencyOptions.push(...dataGetUserCompany.tipos_de_cambio);
    }
    return currencyOptions;
  };

  const handleCancelEditColumnsModal = () => {
    toggleEditColumnsModal();
  };

  const handleAddedMeasures = (measures) => {
    setAddedMeasures(measures);
    setLastChange("SELECTION");
  };

  return (
    <ComponentLayout
      title={t("dataModelText.downloadReport.downloadReport")}
      icon={<DownloadIcon />}
    >
      <SimpleBackdrop
        open={
          isLoadingGetAnalysis ||
          isLoadingGetAnalysisById ||
          isLoadingPostDownloadAnalysis ||
          isLoadingGeneratedDownloads ||
          isLoadingDeleteReportConfig ||
          isLoadingPostSaveReport ||
          isLoadingPutSaveReport
        }
        message={t("dataModelText.downloadReport.loading")}
      />
      <Stack width="100%" direction="row" justifyContent={"center"} mb="15px">
        <Box
          onClick={handleClickConfigOptions}
          style={reset_button_styles(false)}
        >
          {t("OPCIONES DE CONFIGURACIÓN")}
          <Box style={expand_more_icon_styles}>
            <ExpandMoreIcon />
          </Box>
        </Box>
      </Stack>
      <ConfigOptions
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        handleSelection={handleSelection}
        reportConfig={reportConfigId}
      />
      <Grid
        container
        spacing={2}
        sx={{
          width: "70%",
          gap: 2,
          pt: 2,
        }}
      >
        {!dataGetUserCompany?.moneda_actual?.value && (
          <MessageBlock
            message={{
              type: "error",
              text: t(
                "No existe moneda parametrizada para la empresa, debe seleccionar la moneda local desde parametrización de empresa."
              ),
            }}
          />
        )}
        <Grid
          container
          sx={{
            width: "100%",
          }}
        >
          <TextField
            type="text"
            label={t("dataModelText.downloadReport.referenceName")}
            variant="outlined"
            fullWidth
            value={referenceName}
            onChange={handleChangeName}
            error={referenceName === ""}
            FormHelperTextProps={{
              sx: {
                color: "var(--text-error)",
                margin: 0,
              },
            }}
            helperText={
              referenceNameAlreadyExists &&
              t("dataModelText.downloadReport.referenceNameAlreadyExists")
            }
            size="small"
          />
        </Grid>
        <Grid
          container
          sx={{
            width: "100%",
          }}
        >
          <Grid item xs={12}>
            <FormSelectLayout
              title={t("dataModelText.downloadReport.analysis")}
              required={true}
            >
              <Select
                reference={analysisSelectRef}
                styles={selectStyles(analysisSelectedOption)}
                options={
                  dataGetAnalysis &&
                  dataGetAnalysis.filter(
                    (analysis) => analysis.calculado === true
                  )
                    ? convertAnalysisArrayToSelectOptions(
                        dataGetAnalysis.filter(
                          (analysis) => analysis.calculado === true
                        )
                      )
                    : []
                }
                onChange={analysisOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "dataModelText.downloadReport.chooseAnalysisToDownload"
                )}
                isClearable
                isDisabled={isLoadingGetAnalysis}
                defaultValue={defaultInfo ? defaultInfo.analisis : undefined}
              />
            </FormSelectLayout>
          </Grid>
          <Grid item xs={12}>
            <Stack width="100%" mt="15px">
              <CheckmarkSelect1
                dates={transformToPeriodsSelectOptions1(
                  totalPeriodsData?.periodos
                )}
                selectedPeriods={selectedPeriods}
                setSelectedPeriods={setSelectedPeriods}
                width="100%"
              />
            </Stack>
          </Grid>
          {dataGetUserCompany &&
            dataGetUserCompany?.moneda_actual &&
            dataGetUserCompany?.moneda_actual?.value && (
              <Grid item xs={12}>
                <FormSelectLayout
                  title={t("general.currencyy")}
                  required={true}
                >
                  <CurrencySelect
                    setCurrencySelectedOptionsState={
                      setCurrencySelectedOptionsState
                    }
                    defaultCurrency={
                      defaultInfo
                        ? defaultInfo?.monedas
                        : dataGetUserCompany?.moneda_actual?.value
                        ? [dataGetUserCompany?.moneda_actual]
                        : undefined
                    }
                    currencyOptions={getCurrencyOptions()}
                  />
                </FormSelectLayout>
              </Grid>
            )}

          <Grid item xs={12}>
            <FormSelectLayout title={t("Formato de descarga")} required={true}>
              <Select
                reference={formatSelectRef}
                styles={selectStyles(formatSelectedOption)}
                options={[
                  {
                    value: "csv",
                    label: "csv",
                  },
                  {
                    value: "xlsx",
                    label: "xlsx",
                  },
                  {
                    value: "todos",
                    label: "Todos",
                  },
                ]}
                onChange={formatOptionChangeHandler}
                closeMenuOnSelect
                placeholder={t("Seleccionar formato de descarga")}
                isClearable
                isDisabled={isLoadingGetAnalysis}
                defaultValue={
                  defaultInfo ? defaultInfo.formato_de_archivo : undefined
                }
              />
            </FormSelectLayout>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormSelectLayout
              title={t("dataModelText.downloadReport.columns")}
              required={true}
            >
              <Select
                reference={agrupationColumnsSelectRef}
                styles={selectStyles(agrupationColumnsSelectedOptions)}
                options={
                  dataGetAnalysisById &&
                  dataGetAnalysisById?.agrupacion?.columnas_a_usar
                }
                onChange={agrupationColumnsOptionsChangeHandler}
                closeMenuOnSelect={false}
                placeholder={t("dataModelText.downloadReport.chooseColumns")}
                isClearable
                isMulti
                isDisabled={analysisSelectedOption ? false : true}
                defaultValue={
                  defaultInfo
                    ? defaultInfo.columnas.map((col) => {
                        return {
                          value: col,
                          label: col,
                        };
                      })
                    : undefined
                }
              />
            </FormSelectLayout>
          </Grid>
          <Grid item xs={6}>
            <FormSelectLayout
              title={t("dataModelText.downloadReport.periodicity")}
              required={true}
            >
              <Select
                reference={periocitySelectRef}
                styles={selectStyles(periodicitySelectedOptions)}
                options={periocityOptions}
                onChange={periodicityOptionsChangeHandler}
                closeMenuOnSelect
                placeholder={t(
                  "dataModelText.downloadReport.choosePeriodicity"
                )}
                isClearable
                isDisabled={analysisSelectedOption ? false : true}
                defaultValue={
                  defaultInfo && defaultInfo.periodicidad
                    ? {
                        value: defaultInfo.periodicidad,
                        label: defaultInfo.periodicidad,
                      }
                    : undefined
                }
              />
            </FormSelectLayout>
          </Grid>
        </Grid>
        <MeasureAgregationForm
          addedMeasures={addedMeasures}
          setAddedMeasures={handleAddedMeasures}
          measuresSelectRef={measuresSelectRef}
          dataGetAnalysisById={dataGetAnalysisById}
          selected={selectedMeasuresOptions}
          setSelected={setSelectedMeasuresOptions}
          analysisSelectedOption={analysisSelectedOption}
          aggregationOperatorRef={aggregationOperatorRef}
          aggregationSelectedOption={aggregationSelectedOption}
          aggregationSelectedOptionChangeHandler={
            aggregationSelectedOptionChangeHandler
          }
          setAggregationSelectedOption={setAggregationSelectedOption}
        />
        <FilterForm
          filters={filters}
          setFilters={setFilters}
          setColumnFilterSelectedOptions={setColumnFilterSelectedOptions}
          columnFilterSelectedRef={columnFilterSelectedRef}
          columnLabels={columnLabels}
          columnFilterOptionsChangeHandler={columnFilterOptionsChangeHandler}
          columnFilterSelectedOption={columnFilterSelectedOption}
          analysisSelectedOption={analysisSelectedOption}
          selectedValues={selectedValues}
          incomingValuesOptions={incomingValuesOptions}
          setValuesSelectedOptions={setValuesSelectedOptions}
          setSelectedValues={setSelectedValues}
          isLoadingIncomingValuesOptions={isLoadingIncomingValuesOptions}
          valuesSelectedOptions={valuesSelectedOptions}
          selectOneOption={false}
        />
        <Dedynamicize
          defaultInfo={defaultInfo}
          desdinamizar={desdinamizar}
          setDesdinamizar={setDesdinamizar}
          selectedIndexColumns={selectedIndexColumns}
          selectedDesdynamizeColumns={selectedDesdynamizeColumns}
          indexColumnsRef={indexColumnsRef}
          desdynamizeColumnsRef={desdynamizeColumnsRef}
          indexColumnsOptions={indexColumnsOptions()}
          indexColumnsOptionsChangeHandler={indexColumnsOptionsChangeHandler}
          desdynamizeColumnsOptions={desdynamizeColumnsOptions()}
          desdynamizeColumnsOptionsChangeHandler={
            desdynamizeColumnsOptionsChangeHandler
          }
          setSelectedIndexColumnsOptions={setSelectedIndexColumnsOptions}
          setSelectedDesdynamizeColumns={setSelectedDesdynamizeColumns}
          setUpdatedColumns={setUpdatedColumns}
        />
        <Stack
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Typography
            sx={{
              ...title_layout_styles,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
          >
            {t("dataModelText.downloadReport.preview")}
          </Typography>
          <Button
            title={t("Editar columnas")}
            color="blue-greeny"
            type="button"
            onClick={handleOpenEditColumnsModal}
            width={180}
          />
        </Stack>
        <Stack width={"100%"} alignItems={"center"}></Stack>
        <TablePreview columnsPreview={columnsPreview} />
        <SaveNameModal
          open={openSaveNameModal}
          handleClose={toggleSaveNameModal}
          handleAccept={handleSaveReport}
          title={t("dataModelText.downloadReport.saveReportConfig")}
          text={t("dataModelText.downloadReport.confirmSaveReportConfig")}
          label={t("general.name")}
          placeholder={t("dataModelText.downloadReport.configReportName")}
          dispatchFunction={handleSaveReportName}
          dispatchType={"saveReportName"}
          valueState={reportConfigName}
          namesList={getReportsConfigData || []} //Modificar después con GET de reportes del back
          isValidName={() => true}
        />
        <Grid
          container
          spacing={2}
          sx={{
            width: "100%",
            pt: 1,
            justifyContent: "center",
          }}
        >
          <Button
            title={t("dataModelText.downloadReport.deleteData")}
            color="grey"
            type="button"
            onClick={() => cleanValues(false)}
          />
          <Button
            title={t("dataModelText.downloadReport.downloadReport")}
            color="blue"
            type="button"
            onClick={toggleConfirmDownloadModal}
            disabled={!isFormComplete("download")}
          />
        </Grid>
      </Grid>
      <Dialog
        open={openItemsListModal}
        handleClose={toggleItemsListModal}
        title={t("dataModelText.downloadReport.getReportConfig")}
        maxWidth={"lg"}
      >
        <Stack sx={{ width: "600px" }}>
          <GetReportConfigModal
            dataInfo={
              getReportsConfigData && getReportsConfigData.length
                ? getReportsConfigData
                : []
            }
            toggleItemsListModal={toggleItemsListModal}
            openConfirmationModal={openConfirmationDeleteModal}
            handleToggleConfirmationModal={toggleConfirmationDeleteModal}
            handleDeleteItemName={handleDeleteItemName}
            setItemSelected={setItemSelected}
            itemSelected={itemSelected}
            handleApply={handleApply}
          />
        </Stack>
      </Dialog>
      <Dialog
        open={openDeleteConfigModal}
        handleClose={toggleDeleteConfigModal}
        title={t("Eliminar configuración")}
        maxWidth={"lg"}
      >
        <Stack sx={{ width: "600px" }}>
          <GetReportConfigModal
            dataInfo={
              getReportsConfigData && getReportsConfigData.length
                ? getReportsConfigData
                : []
            }
            toggleItemsListModal={toggleDeleteConfigModal}
            openConfirmationModal={openConfirmationDeleteModal}
            handleToggleConfirmationModal={toggleConfirmationDeleteModal}
            handleDeleteItemName={handleDeleteItemName}
            setItemSelected={setItemSelected}
            itemSelected={itemSelected}
          />
        </Stack>
      </Dialog>
      <Dialog
        open={openEditColumnsModal}
        handleClose={toggleEditColumnsModal}
        title={t("Editar columnas")}
        maxWidth={"lg"}
      >
        <Stack sx={{ width: "600px" }}>
          <EditColumnsModal
            columns={updatedEditableColumns}
            setColumns={setUpdatedEditableColumns}
            toggleEditColumnsModal={toggleEditColumnsModal}
            handleApply={handleApplyEditColumnsModal}
            handleCancel={handleCancelEditColumnsModal}
          />
        </Stack>
      </Dialog>

      <ConfirmationModal
        open={openConfirmDownloadModal}
        handleClose={toggleConfirmDownloadModal}
        handleAccept={handleAcceptDownload}
        title={t("dataModelText.downloadReport.downloadReport")}
        message={t("dataModelText.downloadReport.downloadAnalysisSelected")}
      />

      <ConfirmationModal
        open={openConfirmUpdateConfigModal}
        handleClose={toggleConfirmUpdateConfigModal}
        handleAccept={handleAcceptUpdateConfig}
        title={t("Actualizar configuración")}
        message={t("sobreescribir la configuración") + " " + reportConfigName}
      />
    </ComponentLayout>
  );
};

export default DownloadReport;
