import { GET_REPORT_CONFIG_BY_ID_URL } from "../../../api/axios";
import { useEffect, useState } from "react";
import DownloadReport from "./DownloadReport";
import useApi from "../../../hooks/useApi";

const PreDownloadReport = () => {
  const [reportConfigInfo, setReportConfig] = useState<any>();

  const onSuccessGetReportConfig = (data: any) => {
    setReportConfig(data);
  };

  const {
    isLoading: isLoadingGetReportConfig,
    data: dataReportConfigById,
    callApi: getReportConfigById,
  } = useApi(
    GET_REPORT_CONFIG_BY_ID_URL,
    "GET",
    undefined,
    undefined,
    onSuccessGetReportConfig,
    undefined,
    true
  );

  useEffect(() => {
    return localStorage.removeItem("reportConfigId");
  }, []);
  
  return (
    <>
      {isLoadingGetReportConfig ? (
        <>Loading...</>
      ) : reportConfigInfo ? (
        <DownloadReport
          defaultInfo={reportConfigInfo}
          getReportConfigById={getReportConfigById}
          setReportConfig={setReportConfig}
        />
      ) : (
        <DownloadReport
          getReportConfigById={getReportConfigById}
          setReportConfig={setReportConfig}
        />
      )}
    </>
  );
};

export default PreDownloadReport;
