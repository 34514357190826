import { Box, Stack, Typography } from "@mui/material";
import { Button } from "../buttons";
import { COMPANY_URL, GET_ALL_COMPANIES_URL } from "../../api/axios";
import { formatCompanyOptions } from "../../utils/util";
import { handleMenuAndNavigation, updateAuthAndCompany } from "./utils";
import { selectStyles } from "../configCriteria/select.styles";
import { useApiQuery } from "../../hooks/useApiQuery";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import CustomSnackbar from "../snackbar/CustomSnackbar";
import logoPhi from "../../assets/logoPhi.png";
import Select from "../forms/Select";
import SimpleBackdrop from "../backdrop/SimpleBackdrop";
import useApi from "../../hooks/useApi";
import useAuth from "../../hooks/useAuth";
import useInfoContext from "../../hooks/useInfoContext";
import useLanguage from "../../context/LanguageProvider";
import useLogout from "../../hooks/useLogout";
import useSingleSelect from "../../hooks/useSingleSelect";
import {
  company_buttons_container_styles,
  company_container_styles,
  company_form_box_styles,
  company_form_container_styles,
  company_logo_container_styles,
  company_logo_styles,
  company_title_styles,
  container_100_styles,
  error_text_styles,
  video_container_styles,
} from "../../styles/app-styles";
/**
 * @component
 * @name CompanySelector
 * @description - Componente que muestra el formulario para elegir la compañia
 * @param {Object} props - Props del componente
 * @returns {JSX.Element}
 * @category Login
 */
interface CompanySelectorProps {
  menu?: boolean;
  handleCloseBasicModal?: any;
}

const CompanySelector = ({
  menu,
  handleCloseBasicModal,
}: CompanySelectorProps) => {
  const { t } = useLanguage();
  const { setAuth, auth }: any = useAuth();
  const navigate = useNavigate();
  const companySelectRef: any = useRef(null);
  const {
    setCompany: setCompanyContext,
    company: companyContext,
    setCompanyData,
  }: any = useInfoContext();
  const [
    logout,
    isLoadingLogout,
    openLogoutSnackbar,
    handleToggleLogoutSnackbar,
    messageLogoutSnackbar,
    severityLogout,
  ] = useLogout();

  /****************************** LLAMADAS AL BACK ******************************/

  const {
    data: dataGetAllCompanies,
    isLoading: isLoadingAllCompanies,
    isError: isErrorAllCompanies,
  } = useApiQuery(GET_ALL_COMPANIES_URL, true, t("company.getCompaniesError"));

  const {
    data: dataGetUserCompany,
    isLoading: isLoadingGetUserCompany,
    isError: isErrorGetUserCompany,
  } = useApiQuery(COMPANY_URL, true, t("company.getCompanyError"));

  const [
    companySelectedOption,
    setCompanySelectedOption,
    companyOptionChangeHandler,
  ] = useSingleSelect(undefined, companyContext ? companyContext : undefined);

  /*************************** CARGA DE DATOS ***********************************/

  useEffect(() => {
    if (dataGetUserCompany) {
      if (dataGetUserCompany.id) {
        const companyObj = {
          value: dataGetUserCompany.id,
          label: dataGetUserCompany.nombre_visual,
        };
        setCompanySelectedOption(companyObj);
        setCompanyContext(companyObj);
        setCompanyData(dataGetUserCompany);
      } else {
        setCompanyContext(null);
        setCompanyData(null);
      }
    }
  }, [dataGetUserCompany]);

  /********************************** ENVÍO DE FORMULARIO *********************************/

  const onSuccessChangeCompany = () => {
    updateAuthAndCompany(companySelectedOption, setAuth, setCompanyContext);
    localStorage.removeItem("cardInfo");
    localStorage.removeItem("selectedBucket");
    localStorage.removeItem("selectedStage");
    handleMenuAndNavigation(menu, auth, handleCloseBasicModal, navigate);
  };

  const { isLoading: isLoadingChangeCompany, callApi: changeCompany } = useApi(
    COMPANY_URL,
    "PUT",
    t("company.codes"),
    undefined,
    onSuccessChangeCompany,
    undefined,
    false
  );

  const handleConfirmCompany = () => {
    if (companySelectedOption) {
      const data = {
        id: companySelectedOption?.value,
      };
      changeCompany(COMPANY_URL, data);
    }
  };

  /********************************* DESLOGUEO *********************************/

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <SimpleBackdrop
        open={
          isLoadingAllCompanies ||
          isLoadingGetUserCompany ||
          isLoadingChangeCompany
        }
        message={t("company.loadingCompanies")}
      />
      <Stack sx={menu ? undefined : company_container_styles}>
        {!menu && (
          <Box sx={video_container_styles}>
            <img
              className="login_logo"
              src={"https://i.imgur.com/WdjAwrF.png"}
              alt="logo"
              style={{ objectFit: "cover", width: "100%", height: "100%" }}
            />
          </Box>
        )}
        <Stack sx={company_form_container_styles}>
          <Box sx={company_logo_container_styles}>
            <img style={company_logo_styles} src={logoPhi} alt="logo" />
          </Box>
          <h2 style={company_title_styles}>{t("company.selectCompany")}</h2>
          <Stack sx={company_form_box_styles}>
            {!isErrorAllCompanies &&
              !isLoadingGetUserCompany &&
              /* company && */
              typeof companyContext !== "string" && (
                <Box sx={container_100_styles}>
                  <Select
                    options={formatCompanyOptions(
                      dataGetAllCompanies?.filter((company: any) => {
                        return company.habilitada;
                      })
                    )}
                    styles={selectStyles(companySelectedOption)}
                    reference={companySelectRef}
                    onChange={companyOptionChangeHandler}
                    isClearable
                    closeMenuOnSelect
                    isSearchable
                    placeholder={t("company.chooseCompany")}
                    defaultValue={companyContext}
                    value={companySelectedOption}
                  />
                  {isErrorGetUserCompany && (
                    <Typography variant="body1" sx={error_text_styles}>
                      {t("company.userCompanyError")}
                    </Typography>
                  )}
                </Box>
              )}
            {isErrorAllCompanies && (
              <Typography variant="body1" sx={error_text_styles}>
                {t("company.companiesError")}
              </Typography>
            )}
            <Stack sx={company_buttons_container_styles}>
              <Button
                title={t("company.cancel")}
                color="grey"
                type="button"
                onClick={menu ? handleCloseBasicModal : handleLogout}
              />
              <Button
                title={t("company.start")}
                color="blue"
                type="submit"
                onClick={handleConfirmCompany}
                disabled={!companyContext || !companySelectedOption}
              />
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <SimpleBackdrop
        open={isLoadingLogout}
        message={t("company.closingSession")}
      />
      <CustomSnackbar
        open={openLogoutSnackbar}
        handleClose={handleToggleLogoutSnackbar}
        message={messageLogoutSnackbar}
        severity={severityLogout}
      />
    </>
  );
};

export default CompanySelector;
