import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useLanguage from "../../../context/LanguageProvider";
import { button_option_styles, reset_button_styles } from "../../../styles/app-styles";

interface CalculateExpensesoptionsI {
  anchorEl: null | HTMLElement;
  open: boolean;
  handleClose: () => void;
  handleSelection: (type: string) => void;
  reportConfig?: any;
}

const ConfigOptions = ({
  anchorEl,
  open,
  handleClose,
  handleSelection,
}: CalculateExpensesoptionsI) => {
  const { t } = useLanguage();
  const reportConfigId = localStorage.getItem("reportConfigId");
  const texts = {
    load: t("Cargar configuración"),
    update: t("Actualizar configuración"),
    save: t("Guardar configuración"),
    delete: t("Eliminar configuración"),
  };

  return (
    <Menu
      id="basic-menu"
      className="checkBoxMiniSelect"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem
        onClick={() => handleSelection("CARGAR")}
        sx={{ width: "255px" }}
      >
        {texts.load}
      </MenuItem>
      <MenuItem
        onClick={() => reportConfigId ? handleSelection("ACTUALIZAR") : <></>}
        sx={button_option_styles(!reportConfigId)}
      >
        {texts.update}
      </MenuItem>
      <MenuItem onClick={() => handleSelection("GUARDAR")}>
        {texts.save}
      </MenuItem>
      <MenuItem onClick={() => handleSelection("ELIMINAR")}>
        {texts.delete}
      </MenuItem>
    </Menu>
  );
};

export default ConfigOptions;
