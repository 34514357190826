import { Button } from "../../../../../buttons";
import { getColumnsRetentionRoutes } from "./Step3ModelTable";
import { getInformationColumn } from "../../../../utils";
import { Stack, Typography } from "@mui/material";
import { transformDataCancelRoutes } from "./transformDataCancelRoutes";
import { useEffect, useState } from "react";
import AssignRetentionsModal from "./AssignRetentionsModal";
import InfoIcon from "@mui/icons-material/Info";
import useDialog from "../../../../../../hooks/useDialog";
import useLanguage from "../../../../../../context/LanguageProvider";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-pro";

export function CustomToolbar() {
    if (toolbar) {
        return (
            <GridToolbarContainer
                sx={{
                    color: "#F90D4A",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "40px",
                    width: "100%",
                }}
            >
                {/* @ts-ignore */}
                <GridToolbarColumnsButton
                    sx={{
                        display: "flex",
                        color: "var(--button-cancel)",
                        width: "90px",
                        background: "transparent",
                        margin: "2px 5px 2px 18px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                            color: "#F90D4A",
                        },
                    }}
                ></GridToolbarColumnsButton>
                {/* @ts-ignore */}
                <GridToolbarFilterButton
                    sx={{
                        display: "flex",
                        color: "var(--button-cancel)",
                        width: "90px",
                        background: "transparent",
                        margin: "2px 5px 2px 15px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                            color: "#F90D4A",
                        },
                        "& .MuiFormControl.root": {
                            backgroundColor: "yellow",
                            color: "white",
                        },
                    }}
                />
                {/* @ts-ignore */}
                <GridToolbarDensitySelector
                    sx={{
                        display: "flex",
                        color: "var(--button-cancel)",
                        width: "90px",
                        background: "transparent",
                        margin: "2px 5px 2px 15px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                            color: "#F90D4A",
                        },
                    }}
                />
                <GridToolbarExport
                    sx={{
                        display: "none",
                        color: "var(--button-cancel)",
                        width: "90px",
                        background: "transparent",
                        margin: "2px 5px 2px 15px",
                        fontSize: "14px",
                        fontWeight: "bold",
                        alignItems: "center",
                        justifyContent: "center",
                        "&:hover": {
                            color: "#F90D4A",
                        },
                    }}
                />
            </GridToolbarContainer>
        );
    } else {
        return <></>;
    }
}

const Step3NewCancelRouteModal = ({
  filteredRoutes,
  retentions,
  setRetentions,
  informationsColumns,
}: any) => {
  const { t } = useLanguage();
  const [rowsPreview, setRowsPreview] = useState<any>();

  useEffect(() => {
    if (filteredRoutes && filteredRoutes.length > 0) {
      const { rows } = transformDataCancelRoutes(
        filteredRoutes,
        filteredRoutes,
        informationsColumns
      );
      setRowsPreview(rows);
    } else {
      setRowsPreview([]);
    }
  }, [filteredRoutes]);

  /**************************** ASIGNAR RETENCIÓN A VARIAS RUTAS ********************************/
  const [selectedRoutes, setSelectedRoutes] = useState<any[]>([]);
  const [openAssignRetentionsModal, handleToggleAssignRetentionsModal] =
    useDialog();
  const [retention, setRetention] = useState<number>(0);
  const codRutaColumn = getInformationColumn(
    informationsColumns,
    "Identificador único de ruta"
  );

  const handleChangeRetention = (route, value) => {
    const rutaEncontrada = retentions.find((item) => item.ruta === route);
    if (rutaEncontrada) {
      const nuevaRetention = retentions.map((item) =>
        item.ruta === route ? { ...item, retencion: value } : item
      );
      setRetentions(nuevaRetention);
    }
  };

  const handleAssignRetentions = () => {
    const routesSelections =
      codRutaColumn &&
      filteredRoutes
        .filter(
          (item) =>
            selectedRoutes && selectedRoutes.includes(item._id as number)
        )
        .map((route) => route[codRutaColumn]);
    routesSelections.forEach((ruta) => {
      // Busca la ruta en el array retentions
      const retencionEncontrada = retentions.find((item) => item.ruta === ruta);

      // Si se encuentra la retención, actualiza el valor
      if (retencionEncontrada) {
        retencionEncontrada.retencion = retention; // Puedes cambiar 1 por el valor que desees
      }
    });
    setRetention(0);
    setSelectedRoutes([]);
    handleToggleAssignRetentionsModal();
  };

  const handleOpenAssignRetentionsModal = () => {
    handleToggleAssignRetentionsModal();
  };

  return (
    <>
      <Stack
        sx={{
          position: "relative",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: "60px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {t(
            "simulatorTexts.canceledRoutes.step3NewCancelRouteModal.routesToCanceled"
          )}
        </Typography>
        <Stack
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <Button
            title={t(
              "simulatorTexts.canceledRoutes.modifyCancelRouteModal.assignToVarios"
            )}
            color="blue-greeny"
            type="button"
            onClick={handleOpenAssignRetentionsModal}
            disabled={selectedRoutes.length < 1}
          />
        </Stack>
      </Stack>
      <Stack
        sx={{
          pt: 1,
          pb: 2,
          width: "900px",
          justifyContent: "space-around",
          alignItems: "center",
          height: "350px",
          flexDirection: "row",
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: "var(--bg-secondary)",
          },
          "& .MuiDataGrid-columnHeadersInner span": {
            color: "white",
          },
        }}
      >
        {rowsPreview && informationsColumns && (
          <>
            <DataGridPro
              rows={rowsPreview}
              columns={getColumnsRetentionRoutes(
                retentions,
                handleChangeRetention,
                t,
                informationsColumns
              )}
              rowsPerPageOptions={[5, 10, 20]}
              rowHeight={45}
              components={{
                Toolbar: CustomToolbar,
              }}
              componentsProps={{
                panel: {
                  sx: {
                    [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                      display: "none",
                    },
                  },
                },
              }}
              disableColumnMenu={true}
              hideFooter={true}
              checkboxSelection={true}
              disableSelectionOnClick
              selectionModel={selectedRoutes}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectedRoutes(newSelectionModel);
              }}
            />
          </>
        )}
      </Stack>
      <Stack
        sx={{
          width: "100%",
          flexDirection: "row",
          gap: "5px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <InfoIcon color="primary" fontSize="medium" />
        <Typography style={{ color: "var(--text-main)", fontWeight: "600" }}>
          {t(
            "simulatorTexts.canceledRoutes.modifyCancelRouteModal.retentionPercentageInfo"
          )}
        </Typography>
      </Stack>
      <AssignRetentionsModal
        handleFinish={handleAssignRetentions}
        open={openAssignRetentionsModal}
        handleClose={handleToggleAssignRetentionsModal}
        retention={retention}
        setRetention={setRetention}
      />
    </>
  );
};

export default Step3NewCancelRouteModal;
