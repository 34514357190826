import { Button as CustomButton } from "../../../buttons";
import { Button, Stack, Typography } from "@mui/material";
import { convertNumberToMoney } from "../../utils";
import { GET_GSV_GNT_FILE_URL } from "../../../../api/axios";
import { Iteration } from "../../types";
import { optionalSelectStyles } from "../../../configCriteria/select.styles";
import { useNavigate } from "react-router-dom";
import { useParameterization } from "../../../../hooks/useParameterization";
import { useStyles } from "../../../dataManagement/utils";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CriteriaButtons from "../criteriaButtons/CriteriaButtons";
import Dialog from "../../../dialog/Dialog";
import FormSelectLayout from "../../../forms/FormSelectLayout";
import GetAppIcon from "@mui/icons-material/GetApp";
import Select from "../../../forms/Select";
import SimpleBackdrop from "../../../backdrop/SimpleBackdrop";
import useApi from "../../../../hooks/useApi";
import useDialog from "../../../../hooks/useDialog";
import useInfoContext from "../../../../hooks/useInfoContext";
import useLanguage from "../../../../context/LanguageProvider";
import useSingleSelect from "../../../../hooks/useSingleSelect";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  columns_button_styles,
  export_button_styles,
  grid_toolbar_container_styles,
  stack_toolbar_container_styles,
  total_number_styles,
  typography_total_styles,
} from "../../../../styles/app-styles";

interface ICustomToolbar {
  columnsButton?: boolean;
  rows: any;
  total: number;
  refetchRowsData: () => void;
  iteration?: any | undefined;
}

export function CustomToolbar({
  columnsButton,
  rows,
  total,
  refetchRowsData,
}: ICustomToolbar) {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const { parameterizationState } = useParameterization();
  const navigate = useNavigate();
  const { bucketSelectedOption, selectedStage, tabValue } =
    parameterizationState;
  const [openDownloadConfirmModal, toggleDownloadConfirmModal] = useDialog();
  const [openDownloadFileModal, toggleDownloadFileModal] = useDialog();

  const classes = useStyles();

  const onSuccessGetCsvFile = () => {
    toggleDownloadFileModal();
  };

  const { isLoading: isLoadingGetCsvFile, callApi: getCsvFile } = useApi(
    "",
    "POST",
    t("dataManagement.downloadFile.codes"),
    undefined,
    onSuccessGetCsvFile,
    undefined
  );

  const handleGetCsvFileDetail = () => {
    toggleDownloadConfirmModal();
    if (bucketSelectedOption) {
      getCsvFile(GET_GSV_GNT_FILE_URL, {
        bucket: bucketSelectedOption.value,
        etapa: selectedStage.etapa,
        iteracion: iterationSelectedOption?.value,
      });
    }
  };

  const [
    iterationSelectedOption,
    setIterationSelectedOptions,
    iterationOptionsChangeHandler,
  ] = useSingleSelect();

  const handleGoToDownloads = () => {
    navigate(t("dataManagementTexts.dataManagement.taskManager"), {
      state: { tab: "downloads" },
      replace: false,
    });
  };

  const iterations = () => {
    if (bucketSelectedOption && selectedStage?.iteraciones) {
      return selectedStage?.iteraciones?.map((stage: Iteration) => {
        return {
          value: stage.iteracion,
          label: stage.iteracion,
        };
      });
    } else {
      return [];
    }
  };

  return (
    <>
      <SimpleBackdrop
        open={isLoadingGetCsvFile}
        message={t("modelParameterizationTexts.customToolbar.downloadingCSV")}
      />
      <GridToolbarContainer sx={grid_toolbar_container_styles}>
        <Stack sx={stack_toolbar_container_styles}>
          <Stack direction="row" spacing={2}>
            {columnsButton && (
              <>
              {/* @ts-ignore */}
                <GridToolbarColumnsButton sx={columns_button_styles} />
                <GridToolbarExport sx={columns_button_styles} />
              </>
            )}
            {tabValue ===
              t(
                "modelParameterizationTexts.step2.tabValueExpensesNotTransferred"
              ) && (
              <Button
                variant="text"
                onClick={toggleDownloadConfirmModal}
                sx={export_button_styles}
                startIcon={<GetAppIcon />}
              >
                {t("Descargar detalles de GNT")}
              </Button>
            )}
          </Stack>
          {total && (
            <Typography sx={typography_total_styles}>
              {t("modelParameterizationTexts.customToolbar.totalCost")}
              <span style={total_number_styles}>
                {total && convertNumberToMoney(total)}
              </span>
            </Typography>
          )}
          {userRolData &&
          userRolData.parametrizacion_de_preprorrateo_y_prorrateo
            .visualizacion_y_edicion ? (
            <CriteriaButtons rows={rows} refetchRowsData={refetchRowsData} />
          ) : (
            <div></div>
          )}
        </Stack>
      </GridToolbarContainer>

      <Dialog
        open={openDownloadConfirmModal}
        handleClose={toggleDownloadConfirmModal}
        title={t("dataManagementTexts.dataManagement.fileDownload")}
        maxWidth="sm"
      >
        <Stack
          alignItems="center"
          minHeight="280px"
          minWidth={"450px"}
          justifyContent={"space-between"}
        >
          <Stack alignItems="center" width="100%">
            <Typography
              variant="h6"
              mb="15px"
              sx={{
                color: "var(--text-main)",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
              }}
            >
              {t("¿Qué iteración desea descargar?")}
            </Typography>
            <Stack width="100%">
              <FormSelectLayout title={t("Iteración")} required={false}>
                <Select
                  styles={optionalSelectStyles(iterationSelectedOption)}
                  options={[
                    ...iterations(),
                    { value: 2, label: 2 },
                    { value: 3, label: 3 },
                    { value: 4, label: 4 },
                    { value: 5, label: 5 },
                    { value: 6, label: 6 },
                  ]}
                  onChange={iterationOptionsChangeHandler}
                  closeMenuOnSelect
                  placeholder={t("Todas las iteraciones")}
                  isClearable
                />
              </FormSelectLayout>
            </Stack>
          </Stack>

          <Stack width="100%" direction="row" justifyContent={"center"} gap={2}>
            <CustomButton
              title={t("dataManagementTexts.dataManagement.close")}
              color="grey"
              type="button"
              onClick={toggleDownloadConfirmModal}
            />
            <CustomButton
              title={t("general.accept")}
              color="blue"
              type="button"
              onClick={handleGetCsvFileDetail}
            />
          </Stack>
        </Stack>
      </Dialog>
      <Dialog
        open={openDownloadFileModal}
        handleClose={toggleDownloadFileModal}
        title={t("dataManagementTexts.dataManagement.fileDownload")}
        maxWidth="sm"
        actions={
          <>
            <CustomButton
              title={t("dataManagementTexts.dataManagement.close")}
              color="grey"
              type="button"
              onClick={toggleDownloadFileModal}
            />
            <CustomButton
              title={t("dataManagementTexts.dataManagement.goDownloads")}
              color="blue"
              type="button"
              onClick={handleGoToDownloads}
            />
          </>
        }
      >
        <Stack alignItems="center">
          <Typography
            variant="h6"
            mb="15px"
            sx={{
              color: "var(--text-main)",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
            }}
          >
            <CloudDownloadIcon fontSize="large" className={classes.icon} />
            {t("dataManagementTexts.dataManagement.downloadProccesStarted")}
          </Typography>
          <Typography sx={{ color: "var(--text-main)" }}>
            {t("dataManagementTexts.dataManagement.viewDownloadStatus")}
          </Typography>
        </Stack>
      </Dialog>
    </>
  );
}
