import { Checkbox, IconButton, ListItemText, MenuItem } from "@mui/material";
import { Period } from "./PeriodsFilter";
import { getYear } from "./utils";
import { getIconByState } from "../dataModel/utils";

type ListMonthsItems = {
  dates: Period[];
  selectedPeriods: number[];
  yearItem: string;
};

const ListMonthsItems = (
  dates: Period[],
  selectedPeriods: number[],
  yearItem: string
) => {
  const filteredMonths = dates
    ?.filter((date: Period) => getYear(date) === yearItem)
    .map((date: Period) => (
      <MenuItem
        key={date.id}
        dense={true}
        value={date.id}
        sx={{ color: "var(--text-main)" }}
        disabled={date.estado !== "ACTUALIZADO"}
      >
        <Checkbox
          checked={selectedPeriods.indexOf(date.id) > -1}
          sx={{
            color: "var(--text-main)",
            "& svg": { stroke: "var(--text-main)" },
          }}
          
        />
        <ListItemText
          primary={date.fecha}
          sx={{
            color: "var(--text-main)",
            whiteSpace: "normal",
            height: "auto",
          }}
        /> 
        <IconButton
          sx={{
            backgroundColor: "var(--cream)",
          }}
        >
          {getIconByState(date?.estado || "NO_CALCULADO")}
        </IconButton>
      </MenuItem>
    ));
  return filteredMonths;
};

export default ListMonthsItems;
