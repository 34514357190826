import { columnTitleStyle, useColumnStyles } from "../../../../styles/app-styles";
import { getColumnTitle } from "../../utils";
import { GridColumns, DataGridProProps } from "@mui/x-data-grid-pro";
import { Typography } from "@mui/material";
import CustomModelCell from "./CustomModelCell";
import useLanguage from "../../../../context/LanguageProvider";

export const getModelColumns = (
  rows: any,
  selectedStage: any, 
  handleCopyCriteria: any,
  setRowCopyCriteria: any,
  tabValue?: any
) => {
  const { t } = useLanguage();
  const classes = useColumnStyles();
  
  const columnsNames: string[] =
    tabValue ==="GASTOS NO TRASLADADOS"  //t("modelParameterizationTexts.calculationTabValue.nonTransferredExpenses")
      ? selectedStage.etapa !== 0
        ? [
            "causa_error",
            "destino_anterior",
            "receptor_destino",
            "destino",
            "monto",
            "gastos_trasladados",
            "receptor_criterio",
            "criterio_prorrateo",
          ]
        : [
            "causa_error",
            "destino_anterior",
            "receptor_destino",
            "destino",
            "monto",
            "gastos_trasladados",
          ]
      : selectedStage.etapa !== 0
      ? [
          "destino_anterior",
          "receptor_destino",
          "destino",
          "monto",
          "receptor_criterio",
          "criterio_prorrateo",
        ]
      : [
          "destino_anterior",
          "receptor_destino",
          "destino",
          "monto",
        ];

  const getFlexValue = (column: string) => {
    if (
      column === "receptor_destino" ||
      column === "destino_anterior" ||
      column === "destino"
    ) {
      return 1.2;
    }
    if (column === "criterio_prorrateo") {
      return 1.5;
    }
    return 1;
  };

  const columns: GridColumns = columnsNames.map((column: any) => {
    return {
      field: column,
      headerClassName: classes.headerColumn,
      renderHeader: () => (
        <Typography sx={columnTitleStyle}>{getColumnTitle(column, t)}</Typography>
      ),
      flex: getFlexValue(column),
      headerAlign: "center",
      minWidth: 150,
      align: "center",
      wrapText: true,
      renderCell: (params: any) => (
        <CustomModelCell
          {...params}
          rows={rows}
          column={column}         
          handleCopyCriteria={handleCopyCriteria}
          setRowCopyCriteria={setRowCopyCriteria}
        />
      ),
    };
  });

  return columns;
};

export const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) =>
  row.hierarchy;
