import { Base } from "../../context/types";
import { IdOption } from "../configCriteria/types";
import { MultiValue, SingleValue } from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const filterDataByName = (
  data: any,
  value: string,
  setFilteredData: (value: any) => void
) => {
  if (value && data) {
    setFilteredData(
      data?.filter((object: any) => {
        return object.nombre.toLowerCase().includes(value?.toLowerCase());
      })
    );
  } else {
    data && setFilteredData(data);
  }
};

export const findTypeByColumn = (column: any, columnsWithType: any) => {
  const columnWithType = columnsWithType?.find(
    (columnWithType: any) => columnWithType.id === column?.value
  );
  return columnWithType?.tipo;
};

/* Esta función tiene el objetivo recibir la lista de opciones totales de select, y recibir la lista de las que ya fueron seleccionadas. Si ya se seleccionó la opción cuyo label es "VALORES ÚNICOS", entonces del listado de opciones se deben sacar las demás opciones y dejar solamente elegir "VALORES ÚNICOS".  Por el contrario, si se eligió otra opción, se debe sacar dle liatdo la opción "VALORES ÚNICOS" para que ya no se pueda elegir */

export const filterOptions = (options: any[], selectedOptions: any[]) => {
  let filteredOptions = options;
  if (selectedOptions?.length === 0) return filteredOptions;
  if (
    selectedOptions?.find(
      (option: any) => option.function.value === "VALORES_UNICOS"
    )
  ) {
    filteredOptions = options?.filter(
      (option) => option?.value === "VALORES_UNICOS"
    );
  } else {
    filteredOptions = options?.filter(
      (option) => option?.value !== "VALORES_UNICOS"
    );
  }
  return filteredOptions;
};

export const validateGroupByRow = (
  columnSelectedOption: SingleValue<IdOption> | undefined,
  functionSelectedOption: SingleValue<IdOption> | undefined,
  groupByRows: any[],
  setMessage: any
) => {
  let result = false;
  if (columnSelectedOption && functionSelectedOption) {
    const repeatedRows =
      groupByRows !== undefined
        ? groupByRows?.filter((row) => {
            return (
              row.column.value === columnSelectedOption?.value &&
              row.function.value === functionSelectedOption?.value
            );
          })
        : [];
    if (repeatedRows && repeatedRows.length > 0) {
      result = false;
      setMessage("No se pueden agregar filas repetidas");
    } else {
      result = true;
    }
  } else {
    setMessage("Se deben completar todos los campos");
  }

  return result;
};

export const formatRow = (
  columnSelectedOption: any,
  functionSelectedOption: any
) => {
  return {
    column: columnSelectedOption,
    function: functionSelectedOption,
  };
};

export const filterIdFromColumns = (dataColumns: any) => {
  return dataColumns?.filter((column: any) => column.columna != "id");
};

export const formatColumns = (data: any[]) => {
  const sheets = data?.map((item: any) => {
    return {
      value: item.id,
      label: item.columna,
    };
  });
  return sheets;
};

export const formatColumns2 = (data: any[]) => {
  const sheets = data?.map((item: any) => {
    return {
      value: item.id,
      label: item.nombre,
    };
  });
  return sheets;
};

export const formatObject = (data: any) => {
  return {
    value: data?.id,
    label: data?.nombre,
  };
};

export const isGrouperNameValid = (name: string) => {
  return name.length > 2 && name.length < 61;
};

export const handleRepeatedName = (name, grouperList, configMode) => {
  if (name) {
    const nameExists =
      grouperList &&
      grouperList?.find(
        (grouper: any) => grouper.nombre.toLowerCase() === name.toLowerCase()
      );
    if (nameExists && (configMode === "CREATE" || configMode === "COPY")) {
      return true;
    } else {
      return false;
    }
  }
};

export const isBaseDuplicated = (bases: Base[]) => {
  return bases.some((obj1, index1) =>
    bases.some(
      (obj2, index2) =>
        index1 !== index2 && obj1.archivo?.value === obj2.archivo?.value
    )
  );
};
export const tranformArrayWithNombreToString = (array: any[]) => {
  const nombres = array?.map((item) => item.nombre);
  const ultimoIndice = nombres?.length - 1;
  const resultadoFinal = nombres?.reduce((cadena, nombre, indice) => {
    if (indice === ultimoIndice) {
      return cadena + nombre;
    } else {
      return cadena + nombre + ", ";
    }
  }, "");

  return resultadoFinal;
};

export const convertAnalysisArrayToSelectOptions = (originalArray: any[]) => {
  return originalArray.map((item) => ({
    value: item.id,
    label: item.nombre,
  }));
};

export const convertPeriodsArrayToSelectOptions = (originalArray: any[]) => {
  return originalArray.map((item) => ({
    value: item.id,
    label: item.fecha,
  }));
};

export const handleValidateDeleteWord = (word: string) => {
  return word.toLowerCase() !== "eliminar";
};

export const isAnalysisCalculated = (
  analysisArray: any[],
  analysisId?: number | undefined
) => {
  const analysisFound = analysisArray?.find((item) => item.id === analysisId);
  return analysisFound?.calculado;
};

export const concatItemsCommas = (array) => {
  return array?.map((data, idx) => {
    if (idx === array.length - 1) {
      return data.value ? data.value : data;
    } else {
      return data.value ? data.value + ", " : data + ", ";
    }
  });
};

export const handleElementRepeatedName = (
  t,
  name,
  elementsList,
  elementId,
  configMode,
  setMessage
) => {
  if (elementsList && name) {
    console.log(elementsList, "elementsList");
    console.log(elementId, "elementId");

    const elementInfo = elementsList?.find((an: any) => an.id === elementId);
    const elementNameExists = elementsList?.find(
      (an: any) =>
        an?.nombre?.trim().toLowerCase() === name?.trim()?.toLowerCase()
    );
    if (
      elementNameExists &&
      (configMode === "CREATE" || configMode === "COPY")
    ) {
      setMessage(t("general.repeatedName"));
    } else if (
      elementNameExists &&
      configMode === "EDIT" &&
      elementInfo?.nombre?.trim().toLowerCase() !== name?.trim().toLowerCase()
    ) {
      setMessage(t("general.repeatedName"));
    } else {
      setMessage("");
    }
  }
};

export const columnIsRepeated = (
  columnas: MultiValue<IdOption> | undefined
) => {
  // Utilizar el método some para verificar si hay algún label repetido
  return columnas?.some((columna, index) => {
    // Verificar si el label se repite en algún ítem posterior del array
    return columnas
      ?.slice(index + 1)
      .some((otraColumna) => otraColumna.label === columna.label);
  });
};

export const transformToPeriodsSelectOptions = (periods: any[]) => {
  return periods?.map((period) => ({
    id: period.value,
    fecha: period.label,
    posee_gastos: true,
  }));
};

export const transformToPeriodsSelectOptions1 = (periods: any[]) => {
  return periods?.map((period) => ({
    id: period.value,
    fecha: period.label,
    posee_gastos: true,
    estado: period.estado
  }));
};

export const getIconByState = (state: string) => {
  switch (state) {
    case "ACTUALIZADO": {
      return (
        <DoneOutlineIcon
          sx={{
            color: "var(--icon-success) !important",
          }}
        />
      );
    }
    case "NO_CALCULADO": {
      return (
        <CloseIcon
          sx={{
            color: "var(--icon-error) !important",
          }}
        />
      );
    }
    case "DESACTUALIZADO": {
      return (
        <WarningAmberIcon
          sx={{
            color: "var(--icon-warning) !important",
          }}
        />
      );
    }
    case "EN_CALCULO": {
      return (
        <HourglassEmptyIcon
          sx={{
            color: "var(--icon-info) !important",
          }}
        />
      );
    }
    default: {
      return (
        <HourglassEmptyIcon
          sx={{
            color: "var(--icon-info) !important",
          }}
        />
      );
    }
  }
};
