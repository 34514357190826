import { useApiQuery } from "../../../hooks/useApiQuery";
import { useState, useEffect } from "react";
import AnalysisCard from "./AnalysisCard";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BasicGrid from "../../basicGrid/BasicGrid";
import Box from "@mui/material/Box";
import Button from "../../buttons/Button";
import ComponentLayout from "../../layout/ComponentLayout";
import ConfigAnalysis from "./configAnalysis/ConfigAnalysis";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import FixedAddButton from "../../buttons/FixedAddButton";
import FormSelectLayout from "../../forms/FormSelectLayout";
import ImportButton from "../../importElements/ImportButton";
import PeriodsFilter from "../../periods/PeriodsFilter";
import SearchInput from "../../forms/SearchInput";
import SimpleBackdrop from "../../backdrop/SimpleBackdrop";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useApi from "../../../hooks/useApi";
import useDialog from "../../../hooks/useDialog";
import useInfoContext from "../../../hooks/useInfoContext";
import useLanguage from "../../../context/LanguageProvider";
import ViewInfoAnalysisModal from "./ViewInfoAnalysisModal";
import {
  ANALYSIS_URL,
  DELETE_ANALYSIS_URL,
  GET_ANALYSIS_FOR_IMPORT_URL,
  GET_ANALYSIS_URL,
  GROUPERS_URL,
  MEASURES_URL,
  POST_IMPORT_ANALYSIS_URL,
} from "../../../api/axios";
import {
  filterDataByName,
  handleValidateDeleteWord,
  isAnalysisCalculated,
} from "../utils";

const AnalysisAdmin = () => {
  const { t } = useLanguage();
  const { userRolData }: any = useInfoContext();
  const [searchValue, setSearchValue] = useState<string>("");
  const [filteredData, setFilteredData] = useState<any>();
  const [analysisToDelete, setAnalysisToDelete] = useState<
    number | undefined
  >();
  const [analysisToEdit, setAnalysisToEdit] = useState<number | undefined>();
  const [configMode, setConfigMode] = useState<
    "CREATE" | "EDIT" | "INFO" | "COPY"
  >("CREATE");
  const [dataToShow, setDataToShow] = useState<any>();
  const [openViewInfoAnalysisModal, toggleViewInfoAnalysisModal] = useDialog();
  const [openConfigAnalysis, setOpenConfigAnalysis] = useState<boolean>(false);
  const [deleteWord, setDeleteWord] = useState<string>("");
  const [openPeriodsDialog, handleTogglePeriodsDialog] = useDialog();

  const [
    openDeleteMeasureConfirmationModal,
    toggleDeleteMeasureConfirmationModal,
  ] = useDialog();
  const handleToggleOpenConfigAnalysis = () => {
    setNewAnalysisName("");
    setOpenConfigAnalysis(!openConfigAnalysis);
  };

  /********************************* LLAMADAS PARA PEDIR INFO *****************************/

  const { isLoading: isLoadingGetExpenses, data: dataGetExpenses } =
    useApiQuery(MEASURES_URL("GASTOS"), true, t("dataModel.getMeasures.error"));
  const { isLoading: isLoadingGetSales, data: dataGetSales } = useApiQuery(
    MEASURES_URL("VENTAS"),
    true,
    t("dataModel.getMeasures.error")
  );
  const { isLoading: isLoadingGetComposed, data: dataGetComposed } =
    useApiQuery(
      MEASURES_URL("COMPUESTOS"),
      true,
      t("dataModel.getMeasures.error")
    );
  const {
    isLoading: isLoadingGetAnalysis,
    data: dataGetAnalysis,
    refetch: refetchGetAnalysis,
  } = useApiQuery(ANALYSIS_URL, true, t("dataModel.getAnalysis.error"));
  const { isLoading: isLoadingGetAgrupations, data: dataGetAgrupations } =
    useApiQuery(GROUPERS_URL, true, t("dataModel.getGroups.error"));

  const onSuccessDeleteAnaylisis = () => {
    refetchGetAnalysis();
    setDeleteWord("");
  };

  const { isLoading: isLoadingDeleteAnalysis, callApi: deleteAnalysisById } =
    useApi(
      DELETE_ANALYSIS_URL(analysisToDelete),
      "DELETE",
      t("dataModel.deleteAnalysis.codes"),
      undefined,
      onSuccessDeleteAnaylisis
    );

  const onSuccessGetAnalysis = (data: any) => {
    setDataToShow(data);
  };

  const {
    isLoading: isLoadingGetAnalysisById,
    data: dataGetAnalysisById,
    callApi: getAnalysisById,
  } = useApi(
    undefined,
    "GET",
    t("dataModel.getAnalysis.codes"),
    undefined,
    onSuccessGetAnalysis,
    undefined,
    false
  );

  const [newAnalysisName, setNewAnalysisName] = useState<string>("");

  const onSuccessImportAnalysis = () => {
    refetchGetAnalysis();
  };

  const {
    isLoading: isLoadingPostImportAnalysis,
    callApi: postImportAnalysis,
  } = useApi(
    POST_IMPORT_ANALYSIS_URL,
    "POST",
    undefined,
    undefined,
    onSuccessImportAnalysis,
    undefined,
    true
  );

  const {
    callApi: getElements,
    data: dataGetElements,
    isLoading: isLoadingGetElements,
  } = useApi(
    GET_ANALYSIS_FOR_IMPORT_URL,
    "GET",
    undefined,
    undefined,
    undefined,
    undefined,
    false
  );

  /*************************** FUNCIONES DE LAS CARDS ***********************************/

  const handleSearch = (e: any) => {
    setSearchValue(e.target.value);
    filterDataByName(dataGetAnalysis, e.target.value, setFilteredData);
  };

  const handleEdit = (e: any, id: number) => {
    setConfigMode("EDIT");
    getAnalysisById(GET_ANALYSIS_URL(id));
    setAnalysisToEdit(id);
  };

  const handleCopy = (e: any, id: number) => {
    setConfigMode("COPY");
    getAnalysisById(GET_ANALYSIS_URL(id));
  };

  const handleInfo = (e: any, id: number) => {
    setConfigMode("INFO");
    getAnalysisById(GET_ANALYSIS_URL(id));
  };

  const handleToggleDeleteAnalysisModal = () => {
    setDeleteWord("")
    toggleDeleteMeasureConfirmationModal();
  }

  const handleDelete = () => {
    deleteAnalysisById();
    handleToggleDeleteAnalysisModal()
  };

  const handleClickOnDelete = (e: any, id: number) => {
    setAnalysisToDelete(id);
    handleToggleDeleteAnalysisModal();
  };

  const goToConfigMeasure = () => {
    setConfigMode("CREATE");
    handleToggleOpenConfigAnalysis();
  };

  const handleToggleAnalysisModal = () => {
    setConfigMode("CREATE");
    setDataToShow(undefined);
    toggleViewInfoAnalysisModal();
  };

  useEffect(() => {
    if (configMode === "EDIT" && dataToShow) {
      handleToggleOpenConfigAnalysis();
    } else if (configMode === "INFO" && dataToShow) {
      setTimeout(() => {
        toggleViewInfoAnalysisModal();
      }, 500);
    } else if (configMode === "COPY" && dataToShow) {
      handleToggleOpenConfigAnalysis();
    }
  }, [dataToShow]);

  const handleClosePeriodsModal = () => {
    refetchGetAnalysis();
    handleTogglePeriodsDialog();
  };

  return (
    <>
      {!openConfigAnalysis ? (
        <>
          <SimpleBackdrop
            open={
              isLoadingGetAnalysis
            }
            message={t(
              "dataModelText.analysisManagement.analysisAdmin.searchingAnalysis"
            )}
          />
          <SimpleBackdrop
            open={isLoadingDeleteAnalysis}
            message={t("dataModel.deleteAnalysis.loading")}
          />
          <ComponentLayout
            title={t(
              "dataModelText.analysisManagement.analysisAdmin.analysisAdmin"
            )}
            icon={<AnalyticsIcon />}
            rightComponent={
              <Stack
                direction="row"
                justifyContent={"space-between"}
                gap={2}
                alignItems={"center"}
              >
                <Button
                  title={t("modelParameterizationTexts.stagesSettings.periods")}
                  color="blue"
                  type="button"
                  onClick={() => handleTogglePeriodsDialog()}
                />
                <ImportButton
                  postImportElements={postImportAnalysis}
                  getElements={getElements}
                  dataGetElements={dataGetElements}
                  GET_ELEMENTS_URL={GET_ANALYSIS_FOR_IMPORT_URL}
                />
              </Stack>
            }
          >
            <Stack spacing={2} sx={{ width: "100%", mb: "10px" }}>
              <SearchInput
                value={searchValue}
                handleChange={handleSearch}
                placeholder={t(
                  "dataModelText.analysisManagement.analysisAdmin.searchByName"
                )}
              />
            </Stack>
            {filteredData?.length || dataGetAnalysis?.length ? (
              <BasicGrid
                data={filteredData ? filteredData : dataGetAnalysis}
                Card={AnalysisCard}
                handleEdit={
                  userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion &&
                  handleEdit
                }
                handleCopy={
                  userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion &&
                  handleCopy
                }
                handleInfo={handleInfo}
                handleDelete={
                  userRolData &&
                  userRolData.administracion_de_medidas_agrupaciones_y_analisis
                    .visualizacion_y_edicion &&
                  handleClickOnDelete
                }
                showInfo={true}
              />
            ) : (
              <Box
                sx={{
                  width: "95%",
                  height: "50vh",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "700", color: "var(--text-info)" }}
                >
                  {t(
                    "dataModelText.analysisManagement.analysisAdmin.noAnalysisYet"
                  )}
                </Typography>
              </Box>
            )}
            {userRolData &&
              (userRolData.administracion_de_medidas_agrupaciones_y_analisis
                .visualizacion_y_creacion ||
                userRolData.administracion_de_medidas_agrupaciones_y_analisis
                  .visualizacion_y_edicion) && (
                <FixedAddButton handleClick={goToConfigMeasure} />
              )}
          </ComponentLayout>
        </>
      ) : (
        <ComponentLayout
          title={t(
            "dataModelText.analysisManagement.analysisAdmin.analysisAdmin"
          )}
          icon={
            <ArrowBackIcon
              sx={{
                fontSize: "35px",
                marginRight: "10px",
                "& :hover": { cursor: "pointer", color: "#f90d4a" },
              }}
              onClick={handleToggleOpenConfigAnalysis}
            />
          }
        >
          <ConfigAnalysis
            dataGetExpenses={dataGetExpenses}
            dataGetSales={dataGetSales}
            dataGetComposed={dataGetComposed}
            handleToggleOpenConfigAnalysis={handleToggleOpenConfigAnalysis}
            newAnalysisName={newAnalysisName}
            setNewAnalysisName={setNewAnalysisName}
            dataGetAgrupations={dataGetAgrupations}
            dataGetAnalysis={dataGetAnalysis}
            configMode={configMode}
            dataGetAnalysisById={dataGetAnalysisById}
            refetchGetAnalysis={refetchGetAnalysis}
            analysisToEdit={analysisToEdit}
            setConfigMode={setConfigMode}
            isCalculated={isAnalysisCalculated(
              dataGetAnalysis || [],
              analysisToDelete
            )}
          />
        </ComponentLayout>
      )}
      <ConfirmationModal
        open={openDeleteMeasureConfirmationModal}
        handleClose={handleToggleDeleteAnalysisModal}
        handleAccept={handleDelete}
        message={
          isAnalysisCalculated(dataGetAnalysis || [], analysisToDelete)
            ? t(
                "dataModelText.analysisManagement.analysisAdmin.deleteAnalysisConfirmCalculate"
              )
            : t(
                "dataModelText.analysisManagement.analysisAdmin.deleteAnalysisConfirm"
              )
        }
        customMessage={true}
        title={t("dataModelText.analysisManagement.analysisAdmin.confirm")}
        disabledAcceptButton={
          isAnalysisCalculated(dataGetAnalysis || [], analysisToDelete)
            ? handleValidateDeleteWord(deleteWord)
            : false
        }
      >
        {isAnalysisCalculated(dataGetAnalysis || [], analysisToDelete) ? (
          <FormSelectLayout
            title={t(
              "dataModelText.analysisManagement.analysisAdmin.writeDelete"
            )}
            required={true}
          >
            <TextField
              type="text"
              placeholder={t(
                "dataModelText.analysisManagement.analysisAdmin.delete"
              )}
              variant="outlined"
              fullWidth
              value={deleteWord}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setDeleteWord(event.target.value);
              }}
              error={handleValidateDeleteWord(deleteWord)}
              FormHelperTextProps={{
                sx: {
                  color: "var(--text-error)",
                  margin: 0,
                },
              }}
            />
          </FormSelectLayout>
        ) : (
          <></>
        )}
      </ConfirmationModal>
      {dataToShow && (
        <ViewInfoAnalysisModal
          info={dataToShow}
          open={openViewInfoAnalysisModal}
          handleClose={handleToggleAnalysisModal}
          title={dataToShow?.nombre}
        />
      )}
      <PeriodsFilter
        openPeriodsDialog={openPeriodsDialog}
        handleTogglePeriodsDialog={handleClosePeriodsModal}
      />
    </>
  );
};

export default AnalysisAdmin;
