import Avatar from "@mui/material/Avatar";
import Button from "../../buttons/Button";
import ConfirmationModal from "../../dialog/ConfirmationModal";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import Typography from "@mui/material/Typography";
import useLanguage from "../../../context/LanguageProvider";

interface GetReportConfigModalProps {
  dataInfo: any;
  toggleItemsListModal: () => void;
  openConfirmationModal: boolean;
  handleToggleConfirmationModal: () => void;
  handleDeleteItemName: any;
  setItemSelected: (item: any) => void;
  itemSelected: any;
  handleApply?: () => void;
}

const GetReportConfigModal = ({
  dataInfo,
  toggleItemsListModal,
  openConfirmationModal,
  handleToggleConfirmationModal,
  handleDeleteItemName,
  setItemSelected,
  itemSelected,
  handleApply,
}: GetReportConfigModalProps) => {
  const { t } = useLanguage();

  const handleDeleteConfirmation = (item: any) => {
    setItemSelected(item);
    handleToggleConfirmationModal();
  };

  return (
    <Stack>
      <Stack direction="column" width="100%" sx={{ overflow: "auto" }}>
        <List dense={true} sx={{ width: "100%", height: "300px" }}>
          {dataInfo &&
            dataInfo?.map((item: any) => {
              return (
                <ListItem
                  key={item.id}
                  onClick={() => setItemSelected(item.id)}
                  sx={{
                    cursor: "pointer",
                    backgroundColor:
                      itemSelected === item.id ? "var(--bg-light)" : "white",
                    "&:hover": {
                      backgroundColor: "var(--cream) !important",
                    },
                  }}
                  secondaryAction={
                    /* 
                    userRolData &&
                    userRolData.dashboards.visualizacion_y_edicion ?  */ <Tooltip
                      disableFocusListener
                      disableTouchListener
                      title={t("general.delete")}
                      sx={{ verticalAlign: "center", fontSize: "16px" }}
                    >
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        onClick={() => handleDeleteConfirmation(item)}
                      >
                        <DeleteIcon
                          sx={{
                            color: "var(--bg-neutral-hover) !important",
                            cursor: "pointer",
                            transition: "all 1s linear",
                            "& :hover": { color: "var(--icon-error)" },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                >
                  <ListItemAvatar>
                    <Avatar
                      sx={{
                        backgroundColor: "var(--bg-disabled) !important",
                      }}
                    >
                      <InsertDriveFileIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    sx={{
                      color: "var(--text-main) !important",
                      fontWeight: "bold",
                      maxWidth: "340px",
                    }}
                    primary={
                      <p
                        style={{
                          fontWeight: "bold",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        {item?.nombre_plantilla}
                      </p>
                    }
                    secondary={
                      <Typography
                        color="var(--text-disabled)"
                        component="span"
                        sx={{
                          width: "120%",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          display: "inline-block",
                        }}
                      >
                        {item?.analisis?.label}
                      </Typography>
                    }
                  />
                </ListItem>
              );
            })}
        </List>
      </Stack>
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ width: "100%", alignSelf: "flex-end" }}
      >
        <Button
          title={handleApply ? t("general.cancel") : t("general.close")}
          color="grey"
          type="button"
          onClick={toggleItemsListModal}
        />
        {handleApply && <Button
          title={t("general.accept")}
          color="blue-greeny"
          type="button"
          onClick={handleApply}
          disabled={!itemSelected}
        />}
      </Stack>
      <ConfirmationModal
        open={openConfirmationModal}
        handleClose={handleToggleConfirmationModal}
        handleAccept={handleDeleteItemName}
        message={"eliminar esta configuración"}
        title={t("general.delete")}
      />
    </Stack>
  );
};

export default GetReportConfigModal;
