import { checkArraysOverlap, checkEqualsExpenseTarget } from "../../utils";
import { useEffect } from "react";
import { useParameterization } from "../../../../hooks/useParameterization";
import ConfigCriteriaModal from "./ConfigCriteriaModal";
import Criteria from "../criteria/Criteria";
import CustomSkeleton from "../../../skeleton/CustomSkeleton";
import ExpensesTable from "./ExpensesTable";
import NtExpensesTable from "./NtExpensesTable";
import Stack from "@mui/material/Stack";
import useLanguage from "../../../../context/LanguageProvider";

const ParameterizationTable = () => {
  const { t } = useLanguage();
  const {
    parameterizationState,
    parameterizationDispatch,
    isLoadingRows,
    isLoadingResetCalculation,
    rows,
    rowsNt,
    isLoadingRowsNt,
  } = useParameterization();
  const { selectedStage, tabValue, selectionModel, bucketSelectedOption } =
    parameterizationState;

  const texts = {
    calculationError: t(
      "modelParameterizationTexts.step2.checkPreviousStageCalculationError"
    ),
    expenses: "GASTOS",
    ntExpenses: "GASTOS NO TRASLADADOS",
  };

  const renderExpensesTable = () => {
    const expensesHierarchy = rows?.resultado?.map((row: any) => row.hierarchy);
    const ntExpensesHierarchy = rowsNt?.resultado?.map(
      (rowNt: any) => rowNt.hierarchy
    );

    if (tabValue === texts.expenses) {
      if (isLoadingRows || isLoadingResetCalculation || !selectedStage) {
        return <CustomSkeleton height={5} />;
      } else if (!checkArraysOverlap(expensesHierarchy)) {
        return <ExpensesTable />;
      } else {
        return <p> {texts.calculationError} </p>;
      }
    } else {
      if (isLoadingRowsNt || isLoadingResetCalculation || !selectedStage) {
        return <CustomSkeleton height={5} />;
      } else if (!checkArraysOverlap(ntExpensesHierarchy)) {
        return <NtExpensesTable />;
      } else {
        return <p> {texts.calculationError} </p>;
      }
    }
  };

  //En el caso específico de tabValue siendo igual a GNT, el código compara los datos en rowsNt.resultado y selectionModel usando la función checkEqualsExpenseTarget para evaluar si tienen los mismos receptores. El resultado de esta comparación se utiliza para actualizar el estado isExpenseTargetEqual 

  useEffect(() => {
    if (tabValue === texts.ntExpenses) {
      const result = checkEqualsExpenseTarget(
        rowsNt?.resultado,
        selectionModel
      );
      parameterizationDispatch({
        type: "SET_IS_EXPENSE_TARGET_EQUAL",
        payload: result,
      });
    }
  }, [selectionModel, rowsNt, tabValue, bucketSelectedOption]);  

  return (
    <Stack direction="row" sx={{ width: "100%" }}>
      {renderExpensesTable()}
      {selectedStage && <Criteria />}
      <ConfigCriteriaModal />
    </Stack>
  );
};

export default ParameterizationTable;
