import { ANALYSIS_STATES_URL } from "../../api/axios";
import { transformDataAnalysisStates } from "./transformDataAnalysisStates.tsx";
import { useApiQuery } from "../../hooks/useApiQuery";
import CloseIcon from "@mui/icons-material/Close";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import SimpleTable from "../tablesTools/SimpleTable";
import Stack from "@mui/material/Stack";
import useLanguage from "../../context/LanguageProvider";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const AnalysisStatesModal = ({ item }) => {
  const { t } = useLanguage();

  const { data: analysisPeriodsData, isLoading: isLoadingAnalysisPeriodsData } =
    useApiQuery(ANALYSIS_STATES_URL(item?.id));

  return (
    <Stack width="100%">
      {analysisPeriodsData && (
        <Stack>
          <SimpleTable
            columns={
              transformDataAnalysisStates(
                analysisPeriodsData,
                undefined,
                undefined
              ).columns
            }
            rows={
              transformDataAnalysisStates(
                analysisPeriodsData,
                undefined,
                undefined
              ).rows
            }
            toolbar={false}
            exportButton={false}
            filterButton={false}
            rowHeight={50}
            height={"150px"}
          />
          <Stack
            direction="row"
            mt="7px"
            width="100%"
            justifyContent="space-evenly"
            color="var(--text-main)"
          >
            <Stack direction="row">
              <DoneOutlineIcon
                sx={{
                  color: "var(--icon-success)",
                }}
              />
              {t("Actualizado")}
            </Stack>
            <Stack direction="row">
              <CloseIcon
                sx={{
                  color: "var(--icon-error)",
                }}
              />
              {t("No calculado")}
            </Stack>
            <Stack direction="row">
              <WarningAmberIcon
                sx={{
                  color: "var(--icon-warning)",
                }}
              />
              {t("Desactualizado")}
            </Stack>
            <Stack direction="row">
              <HourglassEmptyIcon
                sx={{
                  color: "var(--icon-info)",
                }}
              />
              {t("Cálculo en proceso")}
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default AnalysisStatesModal;
