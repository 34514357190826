import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;
//Auth URLs
export const LOGIN_URL = "auth/login";
export const REFRESH_URL = "auth/refresh";
export const VERIFICATION_URL = "auth/verificar";
export const RESEND_VERIFICATION_URL = "auth/reenviar_codigo";
export const LOGOUT_URL = "auth/logout";
export const CHANGE_USER_PASS_URL = "auth/change_password";
//Empresa URLs
export const GET_ALL_COMPANIES_URL = "empresa/?todos=true";
export const GET_ALL_COMPANIES_ADMIN_URL = "empresa/?admin=true";
export const COMPANY_URL = "empresa/";
export const PERIODS_URL = "empresa/filter/periodos";
export const PRORRAT_PERIODS_URL = `empresa/filter/periodos?totalmente_prorrateado=${true}`;
export const VIEW_ACCESS_URL = "empresa/acceso-vista";

//Administrator panel URLs
export const GET_ALL_USERS_URL = "auth/usuario";
export const EDIT_USER_URL = (user_id: number) => `auth/usuario/${user_id}`;
export const EDIT_ACTIVE_USER_URL = "auth/usuario/habilitar";
export const EDIT_PASSWORD_URL = "auth/usuario/password";
export const EDIT_COMPANY_URL = (company_id: number) =>
  `empresa/modif/${company_id}`;
export const COPY_COMPANY_URL = (company_id: number) =>
  `empresa/${company_id}/clonar`;
export const ROLES_URL = "/roles/";
export const EDIT_HABILITADO_ROL_URL = (rol_id: number) =>
  `roles/${rol_id}/habilitar`;

//Data Management URLs
export const COLUMNS_FILES_URL = "archivo/nombres";
export const ROWS_FILES_URL = "archivo/archivo-periodos";
export const COLUMNS_VALIDATIONS_URL = "columnsvalidations";
export const ROWS_VALIDATIONS_URL = "rowsvalidations";
export const ROWS_PREVIEW_URL = "archivo";
export const DELETE_FILE_URL = (
  archivo_id: number | undefined,
  periodo_id: number | undefined
) => `archivo/${archivo_id}/periodo/${periodo_id}`;
export const DOWNLOAD_FILES_URL = (descarga: {
  archivo: string | number | undefined;
  periodo: string | number | undefined;
}) => {
  if (descarga.archivo && descarga.periodo) {
    return `archivo/descargar/?archivo=${descarga.archivo}&periodo=${descarga.periodo}`;
  } else if (descarga.archivo && !descarga.periodo) {
    return `archivo/descargar/?archivo=${descarga.archivo}`;
  } else if (!descarga.archivo && descarga.periodo) {
    return `archivo/descargar/?periodo=${descarga.periodo}`;
  } else {
    return `archivo/descargar/`;
  }
};
export const UPLOAD_FILES_URL = "archivo/subir";
export const DELETE_PERIOD_URL = "empresa/periodo";
export const ALLOWED_FILE_NAMES_URL = "archivo/permitidos";
export const REPORT_LOADED_FILES_URL = "archivo/reporte_carga_archivos";

//Expenses Settings URLs
export const EXPENSES_URL = "prorrateo/gastos";
export const EXPENSES_VIEW_URL = "gestion-datos/config-tabla";

//Config Criteria URLs
export const GET_BUCKETS_URL = "empresa/buckets";
export const GET_CRITERIA_TARGET_URL = "empresa/receptores-gasto";
export const GET_CRITERIA_FILE_COLUMN_URL = "archivo/archivo-columna";
export const GET_CRITERIA_FUNCTIONS_URL = "criterios/funciones";
export const TARGET_URL = "empresa/receptores-gasto";
export const GET_CRITERIA_COLUMN_VALUES_URL = "archivo/archivo-columna/valores";
//Criteria Management URL
export const CRITERIA_URL = "criterios/";

//Parametrización Preprorrateo
export const GET_PREPRORRATEO_GASTOS_URL = "/preprorrateo/gastos";
export const GET_PREPRORRATEO_AGRUPADOR_URL =
  "preprorrateo/parametrizacion/agrupador";
export const SCHEMAS_URL = "preprorrateo/esquemas";
export const POST_APPLY_SCHEMA_URL = "preprorrateo/esquema/grupo";
export const GET_GROUPERS_URL = "empresa/agrupadores";
export const POST_SCHEMAS_URL = "preprorrateo/parametrizacion/esquemas";
export const POST_REVERSE_CALCULATION_URL = "preprorrateo/calculo/revertir";
export const POST_PREPRORATION_CALCULATE_URL = (
  destination_type: "bucket_gasto" | "agrupador"
) => `preprorrateo/calculo?tipo_destino=${destination_type}`;

// Parametrización de Prorrateo
export const PRORRATEO_GASTOS_URL = "prorrateo/parametrizacion";
export const STAGES_URL = "prorrateo/parametrizacion/etapa";
export const GET_PRORRATEO_RESULTADOS = "prorrateo/parametrizacion/resultados";
export const GET_PRORRATEO_RESULTADOS_NO_TRASLADADOS =
  "prorrateo/parametrizacion/notrasladado";
export const POST_RESET_CALCULATION_URL =
  "prorrateo/parametrizacion/calculo/reset";
export const POST_PRORRATION_CALCULATE_URL =
  "prorrateo/parametrizacion/calculo";
export const POST_PROPAGATE_CRITERIA_URL =
  "prorrateo/parametrizacion/criterios";
export const DELETE_CRITERIA_URL = "prorrateo/parametrizacion/criterio/reseteo";
export const GET_PREVIOUS_VALIDATIONS_URL =
  "prorrateo/parametrizacion/validaciones";
export const GET_CHARTS_RESULTS_URL = "prorrateo/parametrizacion/grafica";
export const GET_PRORRATION_CRITERIA_URL = "criterios/?tipo=prorrateo";
export const GET_CSV_FILE_URL = (
  bucket_id: number | string,
  etapa: number,
  iteracion: number | undefined
) =>
  `prorrateo/parametrizacion/resultados/exportar?bucket=${bucket_id}&etapa=${etapa}&iteracion=${iteracion}`;
export const GET_GSV_GNT_FILE_URL = `prorrateo/parametrizacion/descarga_gnt`;
export const GET_GROUPER_ROWS_URL = (
  bucket_id: number | string,
  etapa: number,
  agrupador_id: number | string
) =>
  `prorrateo/parametrizacion/detalle_desglosado?bucket=${bucket_id}&etapa=${etapa}&agrupador=${agrupador_id}`;
export const GET_CRITERIA_DATA_URL = (aplicacionprorrateo_id: number) =>
  `/criterios/?aplicacionprorrateo_id=${aplicacionprorrateo_id}`;
export const GET_CRITERIA_DUMMY_URL = (aplicacionprorrateo_id: number) =>
  `prorrateo/parametrizacion/gnt/criterios?aplicacionprorrateo_id=${aplicacionprorrateo_id}`;
export const DATA_FOR_DUMMY_URL = "prorrateo/parametrizacion/gnt/detalledummy";
export const POST_CRITERIA_GNT_URL = "prorrateo/parametrizacion/gnt/criterios";

//Gestor de tareas
export const GET_TASKS_URL = "tareas";
export const DELETE_TASK_URL = (task_id: number) =>
  `tareas/${task_id}/cancelar`;
export const GET_DOWNLOAD_ZIP_FILE = (task_id: number) =>
  `archivo/datalake?id_tarea=${task_id}`;
export const GET_TASK_ERRORS_URL = (task_id: number) =>
  `tareas/${task_id}/informe`;

//Dashboard
export const DASHBOARDS_URL = "informes/dashboards";
export const DELETE_DASHBOARD_URL = (dashboard_id: number) =>
  `informes/dashboards?id=${dashboard_id}`;
export const HIGHCHARTS_URL = "dashboard/dashboard";
export const WATERFALL_URL = "dashboard/waterfall";
export const ANALISIS_VISTAS = "data-model/analisis/vistas";
export const POST_NEW_VIEW = (analisis_id) =>
  `data-model/analisis/${analisis_id}/vistas`;
export const DELETE_VIEW_URL = (vista_id) =>
  `data-model/analisis/vistas/${vista_id}`;
export const ELEMENTS_URL = (vista_id) =>
  `data-model/analisis/vistas/${vista_id}/elementos`;
export const ELEMENT_URL = (vista_id, elemento_id) =>
  `data-model/analisis/vistas/${vista_id}/elementos/${elemento_id}`;
export const PUT_ORDEN_ELEMENTO = (vista_id) =>
  `data-model/analisis/vistas/${vista_id}/elementos`;
export const GET_DASHBOARD_COLUMN_VALUES_URL = (archivo_id) =>
  `archivo/archivo-columna/${archivo_id}/valores`;

//Reporte
export const GET_PERCENTAGES_URL = "informes/porcentajes";
export const GET_PRORRATION_DIFERENCES_TABLE_URL =
  "informes/tabla/prorrateo/diferencias";
export const GET_PRORRATION_ERRORS_TABLE_URL =
  "informes/tabla/prorrateo/errores";
export const GET_PRORRATION_ITERATIONS_TABLE_URL =
  "informes/tabla/prorrateo/historico";
export const GET_PREPRORRATION_SUM_TABLE_URL =
  "informes/tabla/preprorrateo/sumas";
export const GET_PREPRORRATION_ERRORS_TABLE_URL =
  "informes/tabla/preprorrateo/diferencias";
export const GET_KPIS_URL = "informes/indicadores/resultados";
export const GET_REPORT_TABLE_URL = "/informes/tabla";
export const GET_CHARTS_RESULTS_BY_PERIOD_URL = (period: number) =>
  `prorrateo/parametrizacion/grafica?periodo=${period}`;
export const GET_PERIODS_BY_ANALYSIS_URL = (
  analysis_id: string | number | undefined
) => `data-model/analisis/${analysis_id}/periodos`;

//Indicadores
export const INDICADORES_ARCHIVOS = "informes/indicadores/archivos";
export const INDICADORES_GRUPOS = "informes/indicadores/grupos";
export const GET_INDICADORES_GRUPOS_DETALLE = (detalle: boolean) =>
  `informes/indicadores/grupos?detalle=${detalle}`;
export const INDICADORES_GRUPO = (groupId: number) =>
  `informes/indicadores/grupo/${groupId}`;
export const INDICADOR_ARCHIVO = (kpiId: number) =>
  `informes/indicadores/${kpiId}`;
export const PUT_ESTADO_INDICADOR_ARCHIVO = (kpiId: number) =>
  `informes/indicadores/${kpiId}/estado`;
export const GET_INDICADOR_CHART = (kpiId: number | string) =>
  `informes/indicadores/grafico/${kpiId}`;

//Gráficos
export const GRAFICOS_ARCHIVOS = "informes/graficos/archivos";
export const GRUPOS_GRAFICOS = "informes/graficos/grupos";
export const GET_GRAFICOS = (grupo: string) =>
  `informes/graficos/?grupo=${grupo}`;
export const GRAFICOS_GRUPO = (groupId: string) =>
  `informes/graficos/grupo/${groupId}`;
export const GRAFICO_ARCHIVO = (id: number) => `informes/graficos/${id}`;
export const PUT_ESTADO_GRAFICO_ARCHIVO = (id: number) =>
  `informes/graficos/${id}/estado`;

//Tablas
export const TABLAS_URL = "informes/tablas/";
export const GRUPOS_TABLAS = "informes/tablas/grupos";
export const GET_TABLAS = (grupo: string) => `informes/tablas/?grupo=${grupo}`;
export const TABLAS_GRUPO = (groupId: string) =>
  `informes/tablas/grupo/${groupId}`;
export const TABLA_ARCHIVO = (id: number) => `informes/tablas/${id}`;
export const PUT_ESTADO_TABLA_ARCHIVO = (id: number) =>
  `informes/tablas/${id}/estado`;
export const FILTER_VALUES_URL = (id: number) =>
  `informes/tablas/${id}/filtros`;
export const ITEM_TABLA_URL = (tablaId: number, itemId: number) =>
  `informes/tablas/${tablaId}/item/${itemId}`;
export const TABLA_VISTA_PREVIA_URL = (tablaId: number, itemId: number) =>
  `informes/tablas/${tablaId}/item/${itemId}/vista_previa`;

//Data Model
export const MEASURES_URL = (tipo_medida) =>
  `data-model/medidas/?tipo=${tipo_medida}`;
export const DELETE_MEASURE_URL = (measure_id: number | undefined) =>
  `data-model/medidas/${measure_id}`;
export const MEASURE_URL = "/data-model/medidas/";
export const GET_MEASURE_URL = (measure_id: number) =>
  `data-model/medidas/${measure_id}`;
export const ANALYSIS_URL = "/data-model/analisis/";
export const GET_ANALYSIS_URL = (
  analysis_id: number,
  columns_to_use?: boolean
) =>
  columns_to_use
    ? `data-model/analisis/${analysis_id}?columnas_agrupacion=${columns_to_use}`
    : `data-model/analisis/${analysis_id}`;
export const DELETE_ANALYSIS_URL = (analysis_id: number | undefined) =>
  `data-model/analisis/${analysis_id}`;
export const PUT_ANALYSIS_URL = (analysis_id: number | undefined) =>
  `data-model/analisis/${analysis_id}`;
export const CALCULATE_ANALYSIS = "data-model/analisis/calcular";
export const ANALYSIS_PERIODS = (analysis_id: number | string) =>
  `data-model/analisis/${analysis_id}/periodos`;
export const DATA_EXPORT = (analysis_id: number | undefined) =>
  `data-model/analisis/${analysis_id}/descargar`;
export const INCOMING_VALUES = (analysis_id: number | undefined) =>
  `data-model/analisis/${analysis_id}/filtros`;
export const POST_REPORT_CONFIG_URL = "data-model/analisis/template";
export const GET_REPORTS_CONFIG_LIST_URL = "data-model/analisis/template";
export const GET_REPORT_CONFIG_BY_ID_URL = (
  template_id: number | undefined
) => {
  return `data-model/analisis/template/${template_id}`;
};
export const DELETE_REPORT_CONFIG_URL = (template_id: number | undefined) => {
  return `data-model/analisis/template/${template_id}`;
};
export const ANALYSIS_STATES_URL = (analysis_id) => `data-model/analisis/${analysis_id}/estado_periodos`;

//AGRUPACIONES
export const GROUPERS_URL = "/data-model/agrupaciones/";
export const GROUPER_URL = (grouper_id: number | undefined) =>
  `data-model/agrupaciones/${grouper_id}`;

//DELTA

//SALES_FORECAST

export const GET_SALES_FORECAST_URL = "simulador/sales_forecast/";
export const SALES_FORECAST_ID_URL = (sales_forecast_id: number | undefined) =>
  `simulador/sales_forecast/${sales_forecast_id}`;
export const INCOMING_VALUES_SALES_FORECAST = (
  sales_forecast_id: string | number
) => `simulador/sales_forecast/${sales_forecast_id}/filtros`;
export const INCOMING_VALUES_SIMULATIONS = (
  sales_forecast_id: string | number
) => `simulador/simulaciones/${sales_forecast_id}/filtros`;

//SIMULADOR
export const GET_SIMULATIONS_URL = (simulations_type: string | undefined) =>
  `/simulador/simulaciones/?tipo=${simulations_type}`;
export const POST_SIMULATIONS_URL = "/simulador/simulaciones/";
export const PUT_SIMULATIONS_URL = (simulation_id: number | undefined) =>
  `/simulador/simulaciones/${simulation_id}`;
export const RULES_URL = "/simulador/reglas/";
export const GET_RULE_URL = (rule_id: number | undefined) =>
  `/simulador/reglas/${rule_id}`;
export const POST_GET_PARAMS = (
  analysis_id: number | undefined,
  simulation_type: string | undefined
) => `/simulador/${analysis_id}/parametros/?tipo=${simulation_type}`;
export const GET_COLUMN_VALUES = (
  analysis_id: number | undefined,
  column: string | undefined,
  origen: "dataexport" | "dataset" | "sales_forecast"
) => `simulador/${analysis_id}/valores/?columna=${column}&origen=${origen}`;
export const GET_SIMULATION_URL = (simulation_id: number | undefined) =>
  `/simulador/simulaciones/${simulation_id}`;
export const RERUN_URL = (type: string, simulation_id: number | undefined) =>
  `/simulador/${type}/rerun/${simulation_id} `;
export const GET_FILTER_COLUMN_VALUES = (
  analysis_id: number | undefined,
  column: string | undefined
) => `simulador/${analysis_id}/filtros/?columna=${column}`;
export const EXECUTE_SIMULATION = "/simulador/ejecucion/";
export const REQUIRE_FILTERS = (simulation_type: string | undefined) =>
  `simulador/filtros_requeridos/?tipo=${simulation_type}`;
export const GET_SIMULATIONS_EXECUTED = "simulador/resultados/";
export const GET_SIMULATION_RESULT = (simulation_id: string | undefined) =>
  `simulador/resultados/${simulation_id}`;
export const INCOMING_VALUES_SIMULATOR = (analysis_id: number | undefined) =>
  `simulador/${analysis_id}/filtros/`;
export const GET_SIMULATION_LINE_CHART = (analisis_id: string | undefined) =>
  `simulador/linechart/${analisis_id}`;
export const GET_FILTERED_ROUTES_CANCEL = (analisis_id: string | undefined) =>
  `/simulador/${analisis_id}/rutas/`;
export const GET_SIMULATION_SCHEMAS = "simulador/esquemas/";
export const GET_SIMULATION_INFORMATION = "simulador/informacion/";
export const GET_SIMULATION_INDICATORS = "simulador/indicadores/";
export const POST_SCHEMA_URL = "/simulador/esquemas/";
export const GET_SCHEMA_URL = (schema_id: string | number | undefined) =>
  `/simulador/esquemas/${schema_id}`;
export const MODIFICATIONS_TYPES = "/simulador/tipos_modificacion/";
export const POST_GET_AVERAGE_VALUES =
  "simulador/modificacion_ruta/valores_promedios/";
export const SIMULATION_VIEWS = (query: string | undefined) =>
  `/simulador/vistas?tipo=${query}`;
export const SIMULATION_PERIODS = (
  type: string,
  id: null | string | undefined
) => `simulador/${type}/${id}/periodos`;
export const PUT_ORDEN_SIMULATION_ELEMENTS = (vista_id) =>
  `simulador/vistas/${vista_id}/elementos`;

//Validaciones
export const VALIDATIONS_PRORATION_URL = "/validaciones/prorrateo";
export const VALIDATIONS_URL = "/validaciones/";
export const VALIDATION_URL = (
  validation_id: number | string | null | undefined
) => `validaciones/${validation_id}`;
export const GET_VALIDATIONS_RESULTS_URL = `validaciones/resultados/`;
export const GET_VALIDATION_RESULT_URL = (
  validation_id: string | null | undefined
) => `validaciones/${validation_id}/resultados`;
export const CALCULAR_TEMPORAL = "validaciones/calcular";
export const CALCULATE_VALIDATION_URL = (
  validation_id: number | string | null | undefined
) => `validaciones/${validation_id}/calcular/`;

//RECURSOS
export const GENERATED_RESOURCES = "recursos";

//Parametrización de la empresa
export const COMPANY_PARAMETERS_GASTOS_URL = "empresa/parametrizacion/gastos";
export const COMPANY_PARAMETERS_VENTAS_URL = "empresa/parametrizacion/ventas";
export const POST_PARAM_EXPENSES_FILE =
  "empresa/parametrizacion/gastos/muestra";
export const POST_PARAM_SALES_FILE = "empresa/parametrizacion/ventas/muestra";
export const COMPANY_PARAMETERS_PNL_URL = "empresa/parametrizacion/pnl";
export const COMPANY_PARAMETERS_PNL_LEVEL_URL =
  "empresa/parametrizacion/pnl/nivel";
export const COMPANY_PARAMETERS_FILE_URL = "empresa/parametrizacion/archivo";
export const FILE_DESCRIPTION_URL =
  "empresa/parametrizacion/archivo/descripcion";
export const PARAM_OTHER_FILE_URL = (id: number | string) =>
  `empresa/parametrizacion/archivo/${id}/muestra`;
export const PARAM_OTHER_FILE_COLUMNS_URL = `empresa/parametrizacion/archivo/columnas`;
export const RELATIONSHIPS_PARAMS_URL = "empresa/parametrizacion/relaciones";
export const RELATIONSHIP_URL = (id: number | string) =>
  `empresa/parametrizacion/relaciones/${id}`;
export const EXPENSES_TARGET_PARAMS_URL = "empresa/parametrizacion/receptores";
export const EXPENSES_TARGET_URL = (id: number | string) =>
  `empresa/parametrizacion/receptores/${id}`;
export const CURRENCY_LIST_URL = "empresa/parametrizacion/lista-monedas";
export const EXCHANGE_RATE_PARAMS_URL = "empresa/parametrizacion/monedas";
export const DOWNLOAD_TEMPLATE_PARAMS_URL =
  "empresa/parametrizacion/monedas/template";
export const EXCHANGE_RATE_CONFIRMATION_URL =
  "empresa/parametrizacion/monedas/confirmacion";

//Market-Basket
export const MARKET_BASKET_URL = "market-basket/";
export const MARKET_BASKET_COLUMNS_URL = (mb_id) =>
  `market-basket/${mb_id}/columnas`;
export const MARKET_BASKET_VALUES_URL = (mb_id, columna_id) =>
  `market-basket/${mb_id}/columnas/${columna_id}`;
export const MB_INDICATORS_PRECALCULATED_URL = (mb_id) =>
  `market-basket/${mb_id}/indicadores_precalculados`;
export const MB_INDICATORS_URL = (mb_id) =>
  `market-basket/${mb_id}/indicadores`;
export const MB_TABLE_URL = (mb_id) => `market-basket/${mb_id}/tabla`;
export const MB_TREEMAPS_URL = (mb_id) =>
  `market-basket/${mb_id}/grafica_treemap`;
export const MARKET_BASKET_ANALISIS_URL = (mb_id) =>
  `market-basket/${mb_id}/analisis`;
export const MB_BARRAS_URL = (mb_id) => `market-basket/${mb_id}/grafica_barras`;

//Market Basket Config
export const SINGLE_MARKET_BASKET_URL = (id) => `market-basket/${id}`;
export const FILE_COLUMNS_TYPE_URL = "archivo/listar-archivo-columnas";
export const FILE_COLUMNS_AGRUPACION_URL =
  "archivo/listar-archivo-columnas?tipo_columna=AGRUPACION";

//Market Basket Resultados
export const MB_RESULTADOS_URL = (mb_id) => `market-basket/${mb_id}/resultados`;

//Importar elementos
export const GET_MEASURES_FOR_IMPORT_URL = (companyId) =>
  `data-model/medidas/clonar?empresa=${companyId}`;
export const POST_IMPORT_MEASURES_URL = "data-model/medidas/clonar";
export const GET_GROUPS_FOR_IMPORT_URL = (companyId) =>
  `data-model/agrupaciones/clonar?empresa=${companyId}`;
export const POST_IMPORT_GROUPS_URL = "data-model/agrupaciones/clonar";
export const GET_ANALYSIS_FOR_IMPORT_URL = (companyId) =>
  `data-model/analisis/clonar?empresa=${companyId}`;
export const POST_IMPORT_ANALYSIS_URL = "data-model/analisis/clonar";
export const GET_ELEMENTS_FOR_IMPORT_URL = (companyId) => {
  return `empresa/parametrizacion/pnl/clonar?empresa=${companyId}`;
};
export const POST_IMPORT_ELEMENTS_URL = "empresa/parametrizacion/pnl/clonar";

//axios instance
export default axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
