import {
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarExport,
    GridToolbarDensitySelector,
  } from "@mui/x-data-grid";

interface ICustomToolbar {
    toolbar?: boolean;
    columnsButton?: boolean;
    filterButton?: boolean;
    densitySelector?: boolean;
    exportButton?: boolean;
    fileName?: string;
}

function CustomToolbar({toolbar, columnsButton, filterButton, densitySelector, exportButton, fileName}: ICustomToolbar) {
    return (
      <GridToolbarContainer
        sx={{
          color: "#F90D4A",
          display: toolbar ? "flex" : "none",
          flexDirection: "row",
          alignItems: "center",
          height: "40px",
          width: "100%",
        }}
      >
        {/* @ts-ignore */}
        <GridToolbarColumnsButton
          sx={{
            display: columnsButton ? "flex" : "none",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 18px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
        ></GridToolbarColumnsButton>
        {/* @ts-ignore */}
        <GridToolbarFilterButton
          sx={{
            display: filterButton ? "flex" : "none",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
        />
        {/* @ts-ignore */}
        <GridToolbarDensitySelector
          sx={{
            display: densitySelector ? "flex" : "none",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
        />
        <GridToolbarExport 
        csvOptions={{fileName: fileName && fileName }}     
          sx={{
            display: exportButton ? "flex" : "none",
            color: "var(--button-cancel)",
            width: "90px",
            background: "transparent",
            margin: "2px 5px 2px 15px",
            fontSize: "14px",
            fontWeight: "bold",
            alignItems: "center",
            justifyContent: "center",
            "&:hover": {
              color: "#F90D4A",
            },
          }}
        />
      </GridToolbarContainer>
    );
  }

  export default CustomToolbar;