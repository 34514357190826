import { Box, IconButton } from "@mui/material";
import { formatDate } from "../../taskManager/utils";
import { getIconByState } from "../utils";
import { truncateString } from "../../parameterization/utils";
import AnalysisStatesModal from "../AnalysisStatesModal";
import Divider from "@mui/material/Divider";
import SpeedDialTooltipOpen from "../../buttons/SpeedDialTooltipOpen";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useDialog from "../../../hooks/useDialog";
import useLanguage from "../../../context/LanguageProvider";
import {
  card_title_styles,
  left_key_text_styles,
  typography_left_key_styles,
} from "../../../styles/app-styles";
import Dialog from "../../dialog/Dialog";

const AnalysisCard = ({
  item,
  handleEdit,
  handleCopy,
  handleInfo,
  handleDelete,
}: any) => {
  const { t } = useLanguage();
  const [openAnalysisStatesModal, toggleAnalysisStatesModal] = useDialog();

  const handleClickAnalysisStates = () => {
    toggleAnalysisStatesModal();
  };

  return (
    <>
      <Stack sx={{ padding: "3px 5px 5px 5px" }}>
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "42px",
            wordBreak: "break-word",
          }}
        >
          <Tooltip title={item.nombre}>
            <Typography sx={card_title_styles}>
              {truncateString(item?.nombre, 100)}
            </Typography>
          </Tooltip>
        </Stack>
        <Divider sx={{ margin: "5px 0" }} />
        <Stack sx={typography_left_key_styles}>
          {t("dataModelText.analysisManagement.analysisCard.grouper")}
          {item.agrupacion?.length >= 17 ? (
            <Tooltip title={item.agrupacion}>
              <Box sx={left_key_text_styles}>
                {truncateString(`  ${item.agrupacion}`, 20)}
              </Box>
            </Tooltip>
          ) : (
            <Box sx={left_key_text_styles}>
              {truncateString(`  ${item.agrupacion}`, 18)}
            </Box>
          )}
        </Stack>
        <Stack sx={typography_left_key_styles}>
          {t("dataModelText.analysisManagement.analysisCard.measures")}{" "}
          {
            <Box sx={left_key_text_styles}>
              {truncateString(
                item.medidas
                  .map((medida: string) => medida.toUpperCase())
                  .join(", "), // Unir medidas con coma
                48 // Límite de caracteres
              )}
            </Box>
          }
        </Stack>
        {item?.ultima_ejecucion && (
          <Stack sx={typography_left_key_styles}>
            {t("Última ejecución")}:{" "}
            <Box sx={left_key_text_styles}>
              {formatDate(item?.ultima_ejecucion)}
            </Box>
          </Stack>
        )}
        <Stack direction="row" justifyContent={"space-between"}>
          <div>
            <Tooltip arrow placement="right" title={item?.resumen_estado || ""}>
              <IconButton
                sx={{
                  backgroundColor: "var(--cream)",
                }}
                onClick={() => handleClickAnalysisStates()}
              >
                {getIconByState(item.resumen_estado)}
              </IconButton>
            </Tooltip>
          </div>
          <SpeedDialTooltipOpen
            id={item.id}
            handleEdit={handleEdit}
            handleCopy={handleCopy}
            handleInfo={handleInfo}
            handleDelete={handleDelete}
          />
        </Stack>
        <Dialog
          open={openAnalysisStatesModal}
          handleClose={toggleAnalysisStatesModal}
          title={t("Estatus de los análisis")}
          maxWidth="xl"
        >
          <Stack width="700px">
            <AnalysisStatesModal item={item} />
          </Stack>
        </Dialog>
      </Stack>
    </>
  );
};

export default AnalysisCard;
