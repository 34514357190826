export const enTranslations = {
  administratorPanel: {
    sendCode: {
      codes: {
        200: "The code has been successfully sent",
        400: "An error occurred and the code could not be sent",
      },
    },
    postCode: {
      codes: {
        200: "The password has been updated successfully",
        400: "The code is incorrect",
      },
    },

    companies: {
      createCompany: {
        codes: {
          200: "The company has been created successfully",
          201: "The company has been created successfully",
          400: "An error occurred and the company could not be created",
        },
      },
      editCompany: {
        codes: {
          200: "The company has been edited successfully",
          400: "An error occurred and the company could not be edited",
        },
      },
      loadingCompanies: "Loading companies...",
      searchCompanyPlaceholder: "Search by company name",
      activeCompanies: "Active companies",
      activeCompany: "Active company",
      inactiveCompanies: "Inactive companies",
      noActiveCompanies: "There are no active companies",
      noInactiveCompanies: "There are no inactive companies",
      switchLabelActive: "Active",
      switchLabelInactive: "Inactive",
      duplicatedCompanyName: "A company with that name already exists",
      companyNameFormat:
        "The name can only contain numbers, letters, and underscores (_)",
      newCompany: "New company registration",
      editCompanyLabel: "Edit company",
      createCompanyLabel: "Create company",
      creatingCompany: "Creating company...",
      updatingCompany: "Updating company...",
      copyingCompany: "Copying company...",
      companyInformation: "Company information",
      name: "Name",
      visualName: "Visual name",
      editCompanyAction: "edit company",
      createCompanyAction: "create company",
      cleanAll: "Clear all",
      finish: "Finish",
    },

    users: {
      createUser: {
        codes: {
          200: "User created successfully",
          201: "User created successfully",
          400: "An error occurred and the user could not be created",
        },
      },
      editUser: {
        codes: {
          200: "User edited successfully",
          400: "An error occurred and the user could not be edited",
        },
      },
      editPassword: {
        codes: {
          200: "Password updated successfully",
          400: "An error occurred and the password could not be updated",
        },
      },
      incorrectFormat: "Incorrect format",
      incorrectPasswordFormat: "Incorrect password format",
      duplicatedEmail: "A user with this email already exists",
      newPassword: "New password",
      repeatPassword: "Repeat password",
      notMatchingPasswords: "Passwords do not match",
      passwordFormatLabel:
        "*The password must be at least 8 characters long, including one uppercase letter, one lowercase letter, and at least one number or special character.",
      authCode: "Authentication code",
      authCodeSentToEmail:
        "We have sent an authentication code to your email address to validate your identity.",
      incorrectAuthCode: "The authentication code is incorrect",
      rol: "Role",
      active: "Active",
      inactive: "Inactive",
      notPossibleToDisableUserLabel: "It is not possible to disable your user",
      notPossibleToDisableUserError:
        "It is not possible to disable your own user. Only another administrator user can perform this action.",
      accept: "Accept",
      administrator: "Administrator",
      consultant: "Consultant",
      client: "Client",
      editUserLabel: "Edit user",
      newUserLabel: "New user registration",
      userInformation: "User information",
      optionalName: "Name (optional)",
      optionalLastName: "Last name (optional)",
      name: "Name",
      lastName: "Last name",
      email: "Email",
      generatePassword: "Generate password",
      companiesLabel: "Companies",
      userEnabledCompanies: "Companies enabled for the user",
      companyEnabledUsers: "Users enabled for the company",
      multipleOptions: "Choose one or more options...",
      assignedCompany: "Assigned company",
      chooseCompanyLabel: "Choose selected company...",
      enableUser: "Enable user",
      activeUser: "Active user",
      editPasswordLabel: "Edit password",
      editUserAction: "edit the user",
      createUserAction: "create the user",
      createUserLabel: "Create user",
      cleanAll: "Clear all",
      finish: "Finish",
      errorLoadingUsers:
        "An error occurred while loading users. Please try again later.",
      loadingUsers: "Loading users...",
      searchUserLabel: "Search by user's name or email",
      activeUsers: "Active users",
      inactiveUsers: "Inactive users",
      noActiveUsers: "There are no active users",
      noInactiveUsers: "There are no inactive users",
    },
    roles: {
      createRole: {
        codes: {
          200: "The role has been created successfully",
          201: "The role has been created successfully",
          400: "An error occurred and the role could not be created",
        },
      },
      editRole: {
        codes: {
          200: "The role has been edited successfully",
          400: "An error occurred and the role could not be edited",
        },
      },
      deleteRole: {
        codes: {
          200: "The role has been deleted successfully",
          400: "An error occurred and the role could not be deleted",
        },
        loading: "Deleting role...",
      },
      searchRolePlaceholder: "Search by role name",
      activeRoles: "Active roles",
      activeRole: "Active role",
      inactiveRoles: "Inactive roles",
      noActiveRoles: "There are no active roles",
      noInactiveRoles: "There are no inactive roles",
      duplicatedRoleName: "A role with that name already exists",
      roleNameFormat:
        "The name can only contain numbers, letters, and underscores (_)",
      newRole: "New role registration",
      editRoleLabel: "Edit role",
      createRoleLabel: "Create role",
      creatingRole: "Creating role...",
      updatingRole: "Updating role...",
      roleInformation: "Role information",
      name: "Name",
      placeholderName: "Rol name",
      editRoleAction: "edit the role",
      createRoleAction: "create the role",
      cleanAll: "Clear all",
      finish: "Finish",
      deleteRol: "delete the role",
    },
    usersLabel: "USERS",
    companiesLabel: "COMPANIES",
    rolesLabel: "ROLES",
    administratorPanel: "Administrator Panel",
  },
  appBarComponent: {
    adminDashboard: "Administrator Panel",
  },
  avatar: {
    logoutLoading: "Logging out...",
    logout: "Logout",
    changePassword: "Change password",
  },
  basicGrid: {
    noLinesLabel: "You haven't added lines yet",
    column: "Column",
    function: "Function",
    name: "Name",
  },
  buttons: {
    speedDialTooltipOpen: {
      edit: "Edit",
      duplicate: "Duplicate",
      seeMore: "View more",
      delete: "Delete",
      result: "Results",
    },
  },
  calculation: {
    getCompletedStages: {
      loading: "Getting stages...",
      error:
        "An error occurred and it was not possible to retrieve the stages.",
    },
  },
  charts: {
    allCharts: {
      groupsError:
        "An error occurred and the groups could not be downloaded. Please try again later.",
      chartsLabel: "Charts",
      periodsLabel: "Periods",
      downloadingGroups: "Downloading groups...",
      downloadingCharts: "Downloading charts...",
      groupsDownloadigError: "An error occurred while downloading the groups.",
      chartsDownloadingError: "An error occurred while downloading the charts.",
      noActiveChartsError: "There are no charts created or active yet.",
    },
    barInfiniteDrilldownChart: {
      originalExpensePercent: "Percentage relative to original amount",
    },
  },
  chartsManagement: {
    addChart: {
      codes: {
        201: "The chart has been created successfully",
        400: "An error occurred and the chart could not be created",
      },
    },
    editChart: {
      codes: {
        200: "The chart has been updated successfully",
        400: "An error occurred and the chart could not be edited",
      },
    },
    putState: {
      codes: {
        200: "The state has been successfully modified",
        201: "The state has been successfully modified",
        400: "An error occurred and the state could not be modified",
      },
    },
    getGroup: {
      error: "An error occurred and it was not possible to retrieve the groups",
    },
  },
  codeVerification: {
    twoStepsVerification: "Two-Step Verification",
    completeWithTheCodeSentToEmail:
      "Please complete with the code sent to your email. Do not share this code with anyone.",
    codeSentToEmail: "The code has been sent to your email inbox.",
    cancel: "Cancel",
    enter: "Enter",
  },
  company: {
    getCompanyError: "It was not possible to load the company",
    getCompaniesError: "It was not possible to load the companies",
    loadingCompanies: "Loading companies...",
    selectCompany: "Select your company",
    chooseCompany: "Choose company",
    userCompanyError: "It was not possible to get the company for this user",
    companiesError: "It was not possible to retrieve the companies",
    changeCompanyError:
      "An error occurred while changing the company, please try again",
    closingSession: "Closing session...",
    cancel: "Cancel",
    start: "Start",
    codes: {
      200: "The company has been successfully changed",
      400: "An error occurred and the company could not be changed",
      404: "An error occurred and the company could not be changed",
    },
  },
  columns: {
    action: "action",
    analysis: "analysis",
    apportionment_criteria: "apportionment_criteria",
    bucket: "bucket",
    cause_error: "cause_error",
    column: "Column",
    criteria: "Criteria",
    date: "date",
    destination_receiver: "destination_receiver",
    destination: "destination",
    expenses_transferred: "expenses_transferred",
    file: "file",
    iteration: "iteration",
    lastPeriod: "last period",
    mount: "amount",
    name: "name",
    period: "period",
    previous_destination: "previous_destination",
    receiver_criteria: "receiver_criteria",
    referenceName: "reference name",
    stage: "stage",
    stages: "stages",
    state: "state",
    values: "Values",
    cod_rec: "previous stages",
    etapas_anteriores: "receiver codes",
    tipo_receptor: "Destination",
    gasto_anterior: "Receiver type",
    cod_re: "previous",
    anterior: "receiver code",
    gasto: "expense",
    receptor_de: "expense",
    criterio_de: "prorration",
    prorrateo: "criteria",
    gastos: "transferred",
    trasladados: "expenses",
    gast: "receiver",
    agrupador: "grouper",
  },
  companyParam: {
    fileNameToUse: "File name to use",
    stepsTitles: {
      step0: "Exchange rate",
      step1: "Parameterization of EXPENSES file",
      step2: "Parameterization of SALES file",
      step3: "P&L Parameterization",
      step4: "Parameterization of other files",
      step5: "Column typing",
      step6: "File relationships",
      step7: "Expense receivers",
    },
    foreignCurrencyExchangeRate: "Foreign currency exchange rate",
    help1:
      "The exchange rate is expressed in the value of foreign currency per unit of local currency.",
    help2: "The suggested flow for data loading is:",
    help3: "Select country and local currency.",
    help4: "Select all foreign currencies to be used.",
    help5:
      "Add periods (if files are already loaded, those periods will appear automatically, but it is also possible to add more).",
    help6:
      "Manually add exchange rate values by double-clicking on a cell in the table, or upload a file with the values.",
    help7:
      "To upload a file, you must download the template, complete it, and upload it in the same format. From Excel, you can add more currencies as long as the currency code matches an existing one (not recommended). Use only numeric format.",
    help8:
      "Keep in mind that when uploading a file, the values modified after downloading the template will be overwritten, so it is recommended to leave manual modifications for last.",
    addCurrencies: "Add currencies...",
    addValuesFromFile: "Add values from file",
    downloadTemplateDescription:
      "To upload the file with the exchange rates, it is necessary to adhere to the format of periods and currency names. Click on the button below to download a template and fill it in with the corresponding data. Then upload the complete file.",
    fileOrFileSlice: "File or file sample",
    dragFile: "Drag file here or click to select.",
    fileToUpload: "File to upload (click accept to confirm)",
    fileUpload: "File upload",
    downloadTemplate: "Download template",
    templateDownload: "Template download",
    noCurrencies: "No currencies selected.",
    uploadFile: "Upload file",
    fillFromExternalSource: "Fill from external source",
    confirmFillValues:
      "Confirm that you want to fill in values from an external source? This may cause manually updated values to be lost.",
    deleteFile: "Delete file",
    easyNextSteps: "This will facilitate the following steps.",
    identifyKeyColumns: "Identify key columns",
    abmPnl: "P&L Lines (drag and drop to reorder)",
    pnlStructure: "P&L structure",
    abmBuckets: "Buckets",
    transactional: "Transactional",
    descriptive: "Descriptive",
    maxLevelDetail: "Maximum level of detail",
    existentColumn: "Existent column",
    multipleColumns: "Multiple columns",
    addFileAndDescription: "Add file and description",
    otherFilesRows:{
      automaticImport: "Automatically import"
    },
    customerMaster: "Customer Master",
    routeMaster: "Route Master",
    ProductSKUMaster: "Product/SKU Master",
    assetAssignment: "Asset Assignment",
    visitLog: "Visit Log",
    replaceNoNumeric: "Replace non-numeric values with",
    complete: "Complete",
    incomplete: "Incomplete",
    columnTyping: "Column typing (There must be a file loaded)",
    existentCombination: "Existent combination",
    relationshipName: "Relationship name",
    file: "File",
    chooseAnOption: "Choose an option",
    column: "Column",
    deleteRelationship: "Delete relationship",
    newRelationship: "New relationship",
    expenseTargetName: "Expense receiver name",
    deleteExpenseTarget: "Delete expense receiver",
    newTarget: "New receiver",
    required: "Required",
    allowNullValues: "Allow null values",
    onlyUniqueValues: "Only unique values",
    average: "Average",
    value: "Value...",
    selectAll: "Select all",
    assignToMultiple: "Assign to multiple",
    elementType: "Element type",
    newExpense: "New expense",
    incomeType: "Income type",
    expenseType: "Expense type",
    bucketLevel: "Level linked to BUCKET",
    deleteLevels: "Delete levels",
    deleteLevel: "Delete level",
    confirmDeleteLevels:
      "Confirm that you want to modify the BUCKET level? This will delete all levels starting from the level ",
    filterByLevel: "Filter by level",
    addLevel: "Add level",
    editName: "Edit name",
    minimunNumberLevels: "The minimum number of levels is 3.",
    confirmCreateLevel: "Confirm that you want to create a new level?",
    confirmEditLevelName: "Confirm that you want to edit the level name?",
    writeNewLevelName: "Enter the name for the new level...",
    writeNewName: "Enter the new name...",
    deleteThisLevel: "delete this level",
    differentLevelError: "The element cannot be moved to a different level.",
    differentTypeError:
      "The element cannot be moved to a different type of element.",
    differentFileError:
      "The element cannot be moved to another one with a different file.",
    addElement: "Add element",
    addTable: "Add table",
    editTable: "Edit table",
    addSubelement: "Add subelement",
    deleteThisElement: "delete this element",
    deleteElement: "Delete element",
    createPnlElement: "Create P&L element",
    confirmEditName: "Confirm that you want to edit the element's name?",
    postParam: {
      codes: {
        200: "The parameterization has been saved successfully",
        201: "The parameterization has been saved successfully",
        400: "An error occurred and the parameterization could not be saved",
      },
      loading: "Saving parameterization...",
    },
    getParams: {
      loading: "Getting parameterization...",
      codes: {
        200: "The parameterization has been successfully downloaded",
        400: "An error occurred and the parameterization could not be downloaded",
      },
    },
    postParamFile: {
      codes: {
        200: "The file has been successfully uploaded",
        201: "The file has been successfully uploaded",
        400: "An error occurred and the file could not be uploaded",
      },
      loading: "Uploading file...",
    },
  },
  configCriteria: {
    addTargetModal: {
      repeatedTargetNameError: "A target with that name already exists",
      emptyFieldsError: "Fields cannot be left empty",
      succesCreatedTarget: "Expense receiver has been successfully created",
      errorCreatedTarget:
        "An error occurred while creating the receiver, please try again.",
      loadingPostTarget: "Creating expense receiver...",
      newTarget: "New receiver",
      cancel: "Cancel",
      accept: "Accept",
      name: "Name",
      target: "Expense receiver",
      newTargetName: "Name of the new receiver",
      targetPlaceholder: "Column name from the file (e.g., CODE_ROUTE)",
      validatePreviousDestinity: "Validate previous destination",
    },
    configCriteriaComponent: {
      criteriaAdmin: "Criteria administration",
      nonAppliedFilters:
        "There are filters not added. Do you confirm you want to create the criteria anyway?",
      nonAppliedFilterWarning:
        "There are filters not added. Do you want to continue anyway?",
      previous: "Previous",
      resetSteps: "Reset all criteria steps",
      cleanAll: "Clear all",
      finish: "Finish",
      next: "Next",
      editCriteria: "edit the criteria",
      createCriteria: "create the criteria",
      createAndAssignCriteria:
        "Do you confirm that you want to copy the criterion and reassign it to the current row?",
      editCriteriaTitle: "Edit criteria",
      createCriteriaTitle: "Create criteria",
      noFilters: "No filters",
      goBack: "Go back",
      continue: "Continue",
      incomingConditionOptions: ["and", "or"],
      incomingFilterTypeOptionsCriteria: [
        "INCLUIR",
        "EXCLUIR",
        "MENOR A",
        "MENOR O IGUAL A",
        "IGUAL A",
        "MAYOR A",
        "MAYOR O IGUAL A",
        "DIFERENTE A",
      ],
      incomingFilterTypeOptions: ["INCLUIR", "EXCLUIR"],
      incomingValuesOptions: [
        { value: "CROSSDOCKS" },
        { value: "PLANTS" },
        { value: "T1" },
      ],
      steps: [
        "Initial parameterization of criteria",
        "File filters",
        "Validation of EXPENSES file with file",
        "File filters with external information",
      ],
      filterMessages: {
        emptyError: "All fields must be completed to add a filter.",
        duplicatedError:
          "There are already filters with these validation criteria.",
        conditionTooltipInfo:
          "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
        duplicatedCriteriaNameError:
          "A criterion with that name already exists.",
      },
      tooltips: {
        conditionTooltip:
          "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
      },
    },
    filterRow: {
      filterWarning:
        "Only one of the options can be applied to all filters. Modifying this option will affect all selected filters.",
    },
    step1: {
      apportionment: "APPORTIONMENT",
      description: "Description",
      criteriaName: "Criterion name",
      applyIn: "Apply in",
      bucket: "Bucket",
      all: "All",
      expenseTargets: "Expense receivers",
      calcParameterization: "Calculation parameterization",
      file: "File",
      selectFile: "Select file...",
      column: "Column",
      selectColumn: "Select column...",
      function: "Function",
      selectFunction: "Select function...",
      countFunction: "COUNT",
      countUniqueFunction: "COUNT.UNIQUE",
      sumUniqueFunction: "SUMA.UNICO",
      sumProductFunction: "SUM.PRODUCT",
      sumProductFunctionLabel: "Product sum",
      sumUniqueFunctionLabel: "Unique sum",
      criteriaUniqueColumn: "Criterion unique column",
      notModifyValues: "Do not modify negative values",
      convertNegativesToPositives: "Convert negative values to positives",
      convertNegativesToZero: "Convert negative values to zero",
      columnToMultiply: "Column to multiply",
      selectOptions: "Select one or more options...",
      changeFileWarning:
        "Do you confirm that you want to edit the criterion's file? This action will reset steps 2, 3, and 4 of the criterion if they are used.",
      editFileLabel: "Edit criterion file",
      targetExpenses: "Expense Receivers",
    },
    step2: {
      repeatedFilterError:
        "There is already a filter for this column. Adding it will replace the previous filter.",
      include: "INCLUIR",
      exclude: "EXCLUIR",
      previousFilter: "Previous filters",
      in: " in ",
      condition: "Condition",
      filterType: "Filter type",
      type: "Type...",
      values: "Values",
      value: "Value",
      valuePlaceholder: "Enter a numerical value",
      noFiltersWarning: "You haven't added filters yet",
      conditionTooltip: "Sólo es posible aplicar una de las opciones para todos los filtros. Modificar esta opción afectará a todos los filtros seleccionados."
    },
    step3: {
      expensesColumn: "Expense column",
      noValidationsWarning: "You haven't added validations yet",
    },
    step4: {
      crossoverValues: "Crossover values",
      validationFile: "Validation file",
      columnValidationFile: "Validation file column",
      columnFile: "File column",
    },
    virtualSelect: {
      loading: "Loading...",
      noAvailableOptions: "No available options",
      selectValues: "Select one or more values...",
      selectOneValue: "Select a value...",
      registeredRoutesSelectValues: "Select one value...",
    },
    getCriteriaBucket: {
      error:
        "An error occurred and it was not possible to download the criteria buckets",
    },
    getCriteriaTarget: {
      error:
        "An error occurred and it was not possible to download the criteria receivers.",
    },
    getCriteriaFileColumn: {
      error:
        "An error occurred and it was not possible to download the criteria files.",
    },
    getCriteriaFunctions: {
      error:
        "An error occurred and it was not possible to download the criteria functions.",
    },
    getExpensesColumn: {
      error:
        "An error occurred and it was not possible to download the criteria expenses.",
    },
    sendCriteria: {
      loading: "Creating criteria...",
      codes: {
        200: "The criteria has been successfully created",
        201: "The criteria has been successfully created",
        400: "An error occurred and the criteria could not be created",
      },
    },
    putCriteria: {
      loading: "Modifying criteria...",
      codes: {
        200: "The criteria has been successfully modified",
        201: "The criteria has been successfully modified",
        400: "An error occurred and the criteria could not be modified",
      },
    },
  },
  copyGenericText: "_copy",
  criteriaManagement: {
    deleteCriteria: {
      loading: "Deleting criteria...",
      codes: {
        200: "The criteria has been successfully deleted",
        201: "The criteria has been successfully deleted",
        203: "The criteria has been successfully deleted",
        400: "An error occurred and the criteria could not be deleted",
      },
    },
    getCriteria: {
      loading: "Getting criteria...",
      error:
        "An error occurred and it was not possible to download the criteria.",
    },
  },
  criteriaManagementTexts: {
    criteriaCard: {
      receivers: "Receivers",
      filters: "Filters",
      validations: "Validations",
      inUseBuckets: "In use, associated with buckets",
      inUseBucket: "In use, associated with bucket",
      notUsed: "Not used",
    },
    criteriaManagement: {
      lookingCriteria: "Looking for criteria...",
      deletingCriteria: "Deleting criteria...",
      criteriaManagement: "Criteria management",
      sortAlphabet: "ALPHABETICALLY",
      sortDate: "BY DATE",
      filter: "Filter",
      searchByCriteriaBucket: "Search by criterion name or bucket",
      noCriteriaAvailable: "There are no criteria available yet.",
      notGetCriteria:
        "It was not possible to retrieve the criteria. Please try again later.",
      deleteCriteria: "delete the criteria",
      noActiveCriteria: "Inactive Criteria",
      activeCriteria: "Active Criteria",
      noFilters: "No filters",
      bucketsWhereItsUsed: "Buckets where it's used",
    },
    viewInfoModal: {
      criteriaInfo: "General criterion information",
      criteriaBase: "Base for criterion:",
      criteriaColumn: "Criterion column:",
      aplicatedFunction: "Applied function:",
      convertNegativesToPositives: "Convert negatives to positives:",
      convertNegativesToZero: "Convert negatives to zero:",
      columnsToValidate: "Columns to validate as unique:",
      yes: "Yes",
      no: "No",
      columnsToMultiply: "Columns to multiply",
      filterInFile: "Filters in file",
      expensesCrossover: "Expenses value crossover with",
      filterBy: "Filtered by",
      withInfo: "with information from",
      previousFilters: "Previous filters in file",
      crossoverValues: "Crossover values of",
      with: "with",
      previousDestinitiesValidations: "Validation of previous destinations",
      differentiatedTreatment: "Differentiated treatment",
      noDifferentiatedTreatment: "No differentiated treatment",
    },
  },
  dashboard: {
    duplicated: "A dashboard with this name already exists.",
    invalidLink: "The link must be a valid Power BI link.",
    noData: "There are no dashboards to show.",
    get: {
      loading: "Getting dashboards...",
      error: "An error occurred while getting dashboards, please try again.",
    },
    post: {
      codes: {
        200: "The dashboard has been successfully added.",
        201: "The dashboard has been successfully added.",
        400: "An error occurred while adding the dashboard, please try again.",
        500: "An error occurred while adding the dashboard, please try again.",
      },
    },
    delete: {
      success: "The dashboard has been successfully deleted.",
      error:
        "An error occurred while deleting the dashboard, please try again.",
      codes: {
        200: "The dashboard has been successfully deleted.",
        204: "The dashboard has been successfully deleted.",
        400: "An error occurred while deleting the dashboard, please try again.",
      },
    },
    newView: {
      codes: {
        200: "The view has been successfully created.",
        201: "The view has been successfully created.",
        400: "An error occurred while creating the view, please try again.",
        500: "An error occurred while creating the view, please try again.",
      },
      loading: "Creating view...",
      createView: "¿Are you sure you want to create the view?",
    },
    deleteView: {
      codes: {
        200: "The view has been successfully deleted.",
        201: "The view has been successfully deleted.",
        400: "An error occurred while deleting the view, please try again.",
        500: "An error occurred while deleting the view, please try again.",
      },
      loading: "Deleting view...",
      modalTitle: "Delete view",
      selectViewToDelete: "Select the view you want to delete",
      selectView: "Select view...",
    },
    newElement: {
      codes: {
        200: "The element has been successfully created.",
        201: "The element has been successfully created.",
        400: "An error occurred while creating the element, please try again.",
        500: "An error occurred while creating the element, please try again.",
      },
      loading: "Creating element...",
    },
    deleteElement: {
      codes: {
        200: "The element has been successfully deleted.",
        201: "The element has been successfully deleted.",
        400: "An error occurred while deleting the element, please try again.",
        500: "An error occurred while deleting the element, please try again.",
      },
      loading: "Deleting element...",
      confirmationMessage: "Are you sure you want to delete this element?",
      modalTitle: "Delete element",
    },
    editElement: {
      codes: {
        200: "The element has been successfully edited.",
        201: "The element has been successfully edited.",
        400: "An error occurred while editing the element, please try again.",
        500: "An error occurred while editing the element, please try again.",
      },
      loading: "Editing element...",
    },
    importFrom: "Import from:",
    analysis: "ANALYSIS",
    simulation: "SIMULATION",
    salesForecast:"SALES FORECAST",
    chart: "Chart",
    indicator: "Indicator",
    table: "Table",
    createNewElement: "New element",
    putElement: "Edit element",
    typeOfElement: "Type of element",
    selectType: "Select type...",
    columnsQuantity: "Width of the element (number of columns to occupy)",
    rowsQuantity: "Height of the element (number of rows to occupy)",
    toUse: "to use",
    moveElement: "Drag to move the element",
    editName: "Edit name",
    create: "Create",
    import: "Import",
    waterfall: {
      positive: "Positive",
      negative: "Negative",
      intermediateSum: "Intermediate sum",
      totalSum: "Total sum",
    },
    createView: "Create view",
    insertName: "Insert name...",
    placeholderWriteName: "Write the name of the view",
    noDataError: "An error occurred while getting the data, please try again.",
    noElementsYet: "No elements have been added yet.",
  },
  dashboardTexts: {
    dashboard: {
      addDashboard: "Edit Dashboard",
      addNewDashboard: "Add New Dashboard",
    },
    newDashboardModalContent: {
      deletingDashboard: "Deleting dashboard...",
      name: "Name:",
      dashboardName: "Dashboard name...",
      linkDashboard: "Dashboard link...",
      deleteDashboardFromListing: "Delete dashboard from listing.",
      close: "Close",
      deleteDashboard: "Delete Dashboard",
    },
  },
  dataManagement: {
    downloadFile: {
      loading: "Downloading file...",
      codes: {
        200: "The file has been successfully downloaded",
        201: "The file has been successfully downloaded",
        400: "An error occurred while downloading the file",
      },
      empty: "There are no files to download",
    },
    getRowsPreview: {
      loading: "Getting preview...",
      codes: {
        200: "Preview obtained successfully",
        400: "An error occurred while getting the preview",
      },
    },
    getColumnsFiles: {
      loading: "Getting columns...",
      error: "An error occurred while downloading the columns.",
    },
    getRowsFiles: {
      loading: "Getting rows...",
      error: "An error occurred while downloading the rows.",
    },
    getAllowedFileNames: {
      loading: "Getting allowed file names...",
      error: "An error occurred while getting the allowed file names.",
    },
    getCompletedStages: {
      codes: {
        400: "An error occurred while downloading the stages.",
      },
      error: "An error occurred while downloading the stages.",
    },
    deleteFile: {
      codes: {
        200: "The file has been successfully deleted.",
        400: "An error occurred while deleting the file.",
        500: "An error occurred while deleting the file.",
      },
      loading: "Deleting file...",
    },
    deletePeriod: {
      codes: {
        200: "The period has been successfully deleted.",
        400: "An error occurred while deleting the period.",
        500: "An error occurred while deleting the period.",
      },
    },
    uploadModal: {
      restartCalculation: "Reset calculation",
    },
  },
  dataManagementTexts: {
    allowFilesModalContent: {
      fileNameExists: "A file with this name already exists.",
      nameRules:
        "The name can only contain letters, numbers, hyphens, and be up to 30 characters long.",
      nameFileDeleted: "The file name has been successfully deleted.",
      errorAllowFileDeleting:
        "An error occurred while deleting the allowed file name, please try again.",
      successAllowFileDeleting:
        "The allowed file name has been successfully added.",
      errorAddAllowFile:
        "An error occurred while adding the allowed file name, please try again.",
      addNewAllowFile: "Add New Allowed File Name",
      fileName: "File Name...",
      allowedFiles: "Allowed Files",
      deleteAllowedFile: "Delete Allowed File",
      close: "Close",
      delete: "Delete",
      offAllowdFileNames: "off allowed file names",
      deleteFile: "Delete File",
    },
    notAllowedToAddFileModal: {
      cannotAddName: "Cannot add the name",
      notPossibleExpensesName:
        "Cannot add the name PRE_GASTOS to allowed file names.",
      accept: "Accept",
    },
    notAllowedToDeleteFileModal: {
      cannotAddName: "Cannot delete the name",
      notPossibleDelete_1: "Cannot delete ",
      notPossibleDelete_2:
        "from allowed file names because there are files loaded with this name. They need to be deleted first.",
      accept: "Accept",
    },
    dataManagement: {
      taskManager: "/gestor-tareas",
      fileStillLoading:
        "This file is still loading. Please wait a moment and try again.",
      cannotGetPreView: "Could not get the preview of this file.",
      deletingPeriodFiles: "Deleting period files...",
      downloadingFile: "Downloading file...",
      dataManagement: "Data Management",
      errorCouldNotDownload:
        "An error occurred and it was not possible to download the information.",
      uploadFiles: "Upload Files",
      allowedFiles: "Allowed Files",
      cannotGetAllowedFiles: "Could not retrieve allowed files.",
      fileUpload: "File Upload",
      close: "Close",
      deleteSelectedPeriod: "delete the selected period",
      deletePeriod: "Delete Period",
      confirmDeleteFile_1: "Are you sure you want to delete the file",
      confirmDeleteFile_2: "? This will also delete related tasks.",
      deleteFile: "Delete File",
      preview: "Preview",
      uploading: "Uploading",
      files: "files",
      closingWindowStopUpload:
        "Closing the window will stop the upload process, and files that haven't started uploading will be lost. Do you want to stop the upload?",
      closeFileUpload: "Close File Upload",
      fileDownload: "File Download",
      goDownloads: "Go to Downloads",
      downloadProccesStarted: "Download process has started.",
      viewDownloadStatus: `To view the download status and download the file, click on the "Go to Downloads" button. You can also see this from the "Task Manager" under Downloads.`,
      importFromAlpha: "Import from Alpha"
    },
    alphaImportTexts:{
      filesToImport: "Select the files to be imported",
      selectedFolders: "Selected folders",
      selectedFiles: "Selected files",
      noFilesToImport: "There are no files to import."
    },
    linearDeterminate: {
      loading: "Loading",
      completedLoading: "Loading completed",
    },
    successUploadModalContent: {
      connectionError:
        "There was a connection error and the file could not be uploaded.",
      onlyUpload:
        "The file, after being uploaded, needs to be processed. The processing status will be shown in the files and periods table.",
      stopLoad:
        "Refreshing the page, closing this window, or closing the browser will stop the upload process and files that haven't started uploading will be lost.",
      charge: "upload",
      reTry: "Retry",
      cancel: "Cancel",
      chargeAbort: "Upload canceled",
      errorFileNotLoaded:
        "There was an error and the file could not be loaded.",
      loading: "Loading...",
      waiting: "Pending",
    },
    transformDataManagement: {
      downloadAllFilesFrom: "Download all files from",
      preview: "Preview",
      download: "Download",
      delete: "Delete",
      validated: "Validated",
      notValidated: "Not validated",
      notFound: "Not found",
      downloadAllFiles: "Download all files",
      downloadAllPeriodFiles: "Download all period files",
      deletePeriod: "Delete Period",
    },
    uploadModalContent: {
      fileNameFormat_1: "File names must follow the format",
      fileNameFormat_2: "NAME-MONTH-YEAR.csv or NAME-MONTH-YEAR.parquet",
      fileNameFormat_3: "e.g. SALES-02-2022.csv",
      allowedName: "Allowed file names:",
      cannotUploadNames: "Allowed file names have not been loaded.",
      dragSelectFiles: "Drag files here or click to select",
      filesToUpload: "Files to upload:",
      addAllowedFilesName: "Add allowed file names.",
      deleteFileFromListing: "Delete file from the listing.",
      fileAlreadyExistsRestatedCalculation:
        " - This file already exists and will replace the previous one. ",
      itWillBeRestarted: " Period calculation will be restarted.",
      fileAlreadyIncluded: " - This file is already included in the listing",
      fileNameNotAllowed: " - File name not allowed",
      cannotGetAllowedFiles: "Allowed files could not be retrieved",
      cancel: "Cancel",
      filledIllegalNames: "There are files with non-allowed names.",
      accept: "Accept",
    },
  },
  dataModel: {
    getMeasures: {
      error: "An error occurred and measures could not be retrieved.",
      codes: {
        200: "Measures were retrieved successfully.",
        400: "An error occurred and measures could not be retrieved.",
        404: "An error occurred and measures could not be retrieved.",
      },
    },
    getMeasure: {
      error: "An error occurred and the measure could not be retrieved.",
      codes: {
        200: "The measure was retrieved successfully.",
        400: "An error occurred and the measure could not be retrieved.",
        404: "An error occurred and the measure could not be retrieved.",
      },
    },
    getGroups: {
      error: "An error occurred and groups could not be retrieved.",
      codes: {
        200: "Groups were retrieved successfully.",
        400: "An error occurred and groups could not be retrieved.",
        404: "An error occurred and groups could not be retrieved.",
      },
    },
    getGroup: {
      error: "An error occurred and the group could not be retrieved.",
      codes: {
        200: "The group was retrieved successfully.",
        400: "An error occurred and the group could not be retrieved.",
        404: "An error occurred and the group could not be retrieved.",
      },
    },
    getPeriods: {
      error: "An error occurred and the periods could not be obtained",
      codes: {
        200: "The periods were obtained",
        400: "An error occurred and the periods could not be obtained",
        404: "An error occurred and the periods could not be obtained",
      },
    },
    deleteGroup: {
      loading: "Deleting group...",
      codes: {
        200: "The group has been successfully deleted",
        204: "The group has been successfully deleted",
        400: "An error occurred and the group could not be deleted",
      },
    },
    deleteMeasure: {
      loading: "Deleting measure...",
      codes: {
        200: "The measure has been successfully deleted",
        400: "An error occurred and the measure could not be deleted",
      },
    },
    getAnalysis: {
      error: "An error occurred and analyses could not be retrieved",
      codes: {
        200: "Analyses were retrieved successfully",
        400: "An error occurred and analyses could not be retrieved",
        404: "An error occurred and analyses could not be retrieved",
      },
    },
    postNewAnalysis: {
      error: "An error occurred and ",
    },
    deleteAnalysis: {
      loading: "Deleting analysis...",
      codes: {
        200: "The analysis has been successfully deleted",
        201: "The analysis has been successfully deleted",
        400: "An error occurred and the analysis could not be deleted",
      },
    },
    postCalculate: {
      codes: {
        200: "Calculation has been successfully initiated",
        201: "Calculation has been successfully initiated",
        400: "An error occurred and calculation could not be initiated",
      },
    },
    postNewMeasure: {
      loading: "Creating measure...",
      codes: {
        200: "The measure has been successfully created",
        201: "The measure has been successfully created",
        400: "An error occurred and the measure could not be created",
      },
    },
    putMeasure: {
      loading: "Modifying measure...",
      codes: {
        200: "The measure has been successfully modified",
        201: "The measure has been successfully modified",
        400: "An error occurred and the measure could not be modified",
      },
    },
    postNewGrouper: {
      loading: "Creating group...",
      codes: {
        200: "The group has been successfully created",
        201: "The group has been successfully created",
        400: "An error occurred and the group could not be created",
      },
    },
    putGrouper: {
      loading: "Editing group...",
      codes: {
        200: "The group has been successfully edited",
        201: "The group has been successfully edited",
        400: "An error occurred and the group could not be edited",
      },
    },
    postDownloadAnalysis: {
      loading: "Downloading report...",
      codes: {
        200: "The download has started successfully",
        201: "The download has started successfully",
        400: "An error occurred and the analysis download could not be started",
      },
    },
  },
  dataModelText: {
    analysisExecution: {
      analysisExecution: "Analysis Execution",
      startingCalculation: "Loading calculation...",
      analysis: "Analysis",
      selectAnalysisToRun: "Select analyses to run",
      periods: "Periods",
      selectPeriods: "Select periods",
      runSelectedAnalysisPlural: "run the selected analyses",
      runSelectedAnalysisSingular: "run the selected analysis",
      deleteData: "Delete data",
      runAnalysis: "Run analysis",
      noPeriods: "There are no periods with prorated buckets entirely for the analysis.",
    },
    analysisManagement: {
      configAnalysis: {
        creatingAnalysis: "Creating analysis...",
        editingAnalysis: "Editing analysis...",
        analysisAlreadyCalculated:
          "This analysis has already been calculated. Editing it will delete the current calculation.",
        analysisName: "Analysis name",
        analysisAlreadyName: "A measure with that name already exists",
        groupers: "Groupers:",
        chooseGrouper: "Choose grouper...",
        measures: "Measures:",
        cancel: "Cancel",
        accept: "Accept",
        modifyAnalysis: "modify the analysis",
        createNewAnalysis: "create a new analysis",
        newAnalysis: "New analysis",
      },
      transferList: {
        measureDisabled:
          "The measure is disabled because not all necessary primary measures were chosen.",
        analysisAdmin: "Analysis Administration",
        bucketGrouperInMeasures:
          "If a bucket-grouper is used on two chosen measurements, they will be with another color.",
        compositeWithPrimary:
          "Composite measures will only be enabled if all primary measures required for that measure have been chosen.",
        noMeasuresAvailable:
          "There are no measurements available for this type.",
      },
      analysisAdmin: {
        searchingAnalysis: "Searching analysis...",
        analysisAdmin: "Analysis administration",
        periods: "Periods",
        searchByName: "Search by name",
        noAnalysisYet: "No analyses available yet.",
        deleteAnalysisConfirmCalculate:
          "Are you sure you want to delete the analysis? This action will also delete its calculation.",
        deleteAnalysisConfirm: "Are you sure you want to delete the analysis?",
        confirm: "Confirm",
        writeDelete: "Write the word 'delete'",
        delete: "delete",
      },
      analysisCard: {
        grouper: "Grouper:",
        measures: "MEASURES:",
        calculated: "Calculated",
        noCalculated: "Not calculated",
      },
      viewInfoAnalysisModal: {
        grouper: "GROUPER:",
        measures: "MEASURES:",
        analysisInfo: "General analysis information",
      },
    },
    downloadReport: {
      aggregationOperatorsLabels: {
        sum: "SUM",
        average: "AVERAGE",
        maximum: "MAXIMUM",
        minimum: "MINIMUM",
        count: "COUNT",
        count_unique: "COUNT_UNIQUE",
        first: "FIRST",
        last: "LAST",
        unique_values: "UNIQUE_VALUES",
      },
      year: "YEAR",
      month: "MONTH",
      monthly: "MONTHLY",
      quarterly: "QUARTERLY",
      biannual: "SEM-ANNUAL",
      annual: "ANUAL",
      downloadReport: "Download report",
      saveReportConfig: "Save configuration",
      confirmSaveReportConfig:
        "Confirm that you want to save the report download settings?",
      configReportName: "Name for the download configuration",
      getReportConfig: "Get configuration",
      reports: "Reports",
      loading: "Loading...",
      referenceName: "Reference Name",
      referenceNameAlreadyExists:
        "The reference name is already in use. If you continue, the existing one will be overwritten.",
      notAnalysisExecuted: "No analyzes run yet",
      analysis: "Analysis",
      chooseAnalysisToDownload: "Select analysis to download...",
      filters: "Filters",
      columns: "Columns",
      chooseColumns: "Choose columns...",
      periodicity: "Periodicity",
      choosePeriodicity: "Choose periodicity...",
      measures: "Measures",
      selectAllMeasures: "SELECT ALL MEASUREMENTS",
      noOptionsMeasuresAvailable: "There are no measurements available...",
      all: "ALL",
      chooseMeasures: "Choose measurements...",
      aggregationOperators: "Aggregation operators",
      chooseAggregationOperators: "Choose an operator...",
      deleteData: "Delete data",
      download: "Download",
      downloadAnalysisSelected: "download the selected analysis",
      preview: "Preview",
      periods: "Periods",
    },
    groupManagement: {
      step1: {
        addBaseButton: {
          newBase: "New Base",
        },
        mergeModal: {
          firstOcurrence: "first-occurrence",
          lastOcurrence: "last-occurrence",
          dontDelete: "do-not-delete",
          editMerge: "edit merge",
          addMerge: "add merge",
          file: "File",
          selectFile: "Select file",
          alreadyExistBaseWithFile:
            "A base with the selected file already exists",
          columns: "Columns",
          columnsMergePreviousBase: "Columns merge - Previous base",
          chooseColumns: "Choose columns...",
          columnsMergeNewBase: "Columns merge - New base",
          removeDuplicates: "Remove duplicates:",
          keepFirstOcurrence: "Keep first occurrence",
          keepLastOcurrence: "Keep last occurrence",
          doNotDelete: "Do not delete",
          notRecommended:
            "(Not recommended) Not deleting duplicates can cause duplicated values.",
        },
        newBaseFile: {
          columnBeingUsed:
            "The deleted column is being used in a merge, reconfigure error bases to proceed",
          file: "File",
          chooseFile: "Choose file...",
          chooseAnalysis: "Choose analysis...",
          columns: "Columns",
          chooseColumns: "Choose columns...",
          removeBase: "Remove base",
          confirmRemoveBase:
            "Are you sure you want to remove the base? This action will also remove the following bases, and you'll need to reconfigure step 2 if any of them were being used.",
          editBaseFile: "Edit base file",
          confirmEditFiles:
            "Are you sure you want to edit the chosen file for the base? This action will reset step 2 and needs to be reconfigured.",
          editBaseColumns: "Edit base columns",
          confirmEditColumns:
            "Are you sure you want to edit the chosen columns? This action will reset step 2 and needs to be reconfigured.",
        },
        step1: {
          grouperName: "Group Name",
          grouperNameAlreadyExists: "A group with this name already exists",
          nameRules: "The name must be between 3 and 60 characters",
        },
      },
      step2: {
        customColumnInput: {
          nameColumnEmpty: "Column name cannot be empty",
          columnsNameAlreadyExists: "A column with this name already exists",
        },
        modifyColumnsModal: {
          renameColumns: "Rename Columns",
          resetColumns: "Reset Columns",
          columnsOf: "Columns of",
          cancel: "Cancel",
          accept: "Accept",
        },
        step2: {
          columnToJoinResults: "Column to join with results",
          file: "File",
          chooseFile: "Choose file...",
          column: "Column",
          chooseColumn: "Choose column...",
          columnsToUse: "Columns to use",
          chooseColumns: "Choose columns...",
          renameColumns: "Rename Columns",
          preview: "Preview",
          resetColumnTitle: "Reset columns",
          resetColumnMessage: "reset columns",
        },
      },
      baseModal: {
        cancel: "Cancel",
        accept: "Accept",
      },
      configGroup: {
        newGroup: "New group",
        previous: "Previous",
        nonPossibleContinueTwoDataBases:
          "Cannot continue as there are two bases with the same file",
        columnsWithSameName:
          "There are columns with the same name; one of them must be renamed to proceed.",
        finish: "Finish",
        next: "Next",
        editGroup: "Edit group",
        createGroup: "Create group",
      },
      groupAdmin: {
        groupsAdmin: "Group administration",
        searchByName: "Search by name",
        nonGroupsYet: "No groups available yet.",
        removeGroup: "remove the group",
        confirm: "Confirm",
      },
      groupCard: {
        usedColumns: "Used columns:",
      },
      viewGrouperInfo: {
        name: "Name:",
        base: "Base:",
        columns: "Columns:",
        column: "Column:",
        generalInfoGroup: "General group information",
        mergeColumnsPreviousBase: "Merge columns - previous base:",
        mergeColumnsCurrentBase: "Merge columns - current base:",
        removeDuplicates: "Remove duplicates:",
        finalColumns: "Final columns",
        usedColumns: "Used columns:",
      },
    },
    measureManagement: {
      configMeasure: {
        measureInCurrency: "Measure in currency",
        configCompounds: {
          operatorsCalculator: {
            operators: "Operators",
          },
        },
        configCompoundsMeasure: {
          parenthesesMatches: "Make sure all parentheses are properly matched",
          formulaFinishInOperator:
            "Ensure the formula does not end with an operator",
          measureName: "Measure Name",
          measureNameAlreadyExists: "A measure with that name already exists",
          formula: "Formula",
          enterFormula: "Enter the formula",
          measureExpenses: "Expense Measures",
          measureSales: "Sales Measures",
          measureCompounds: "Compound Measures",
          cancel: "Cancel",
          accept: "Accept",
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
        },
        configExpensesMeasure: {
          newMeasureName: "New Measure Name",
          cancel: "Cancel",
          accept: "Accept",
          searchByBucketGrouper: "Search by Bucket or Grouper",
          editMeasure: "Edit measure",
          newMeasure: "New measure",
          newName: "New name",
          repeatedMeasureName: "A measure with that name already exists",
        },
        configSalesMeasure: {
          tabs: {
            sales: "SALES",
            otherBase: "OTHER SOURCE",
          },
        },
        groupByRowSelects: {
          chooseColumn: "Choose column...",
          chooseFunction: "Choose function...",
          columnName: "Column name",
          nameAlreadyExists: "The name already exists"
        },
        otherFontStep1: {
          file: "File",
          selectFile: "Select file",
          mergeColumnsCurrentBase: "Merge columns - Current Base",
          chooseColumns: "Choose columns",
          mergeColumnsSalesBase: "Merge columns - SALES Base",
          numberColumnsSelectedEqualSalesBase:
            "The number of selected columns from the current base must be equal to the SALES base",
          columnsToAddFunctionAggregation:
            "Columns to add and aggregation function",
          avoidDuplicatingValues:
            "To avoid duplicating values, the added information will be grouped by using the chosen merge columns",
          reset: "Reset",
          resetData: "Reset data",
          deleteAllData: "Delete all data",
        },
        otherMeasureFont: {
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
          previous: "Previous",
          finish: "Finish",
          next: "Next",
        },
        salesMeasure: {
          loading: "Loading...",
          measureName: "Measure Name",
          newMeasureName: "New Measure Name",
          measureNameAlreadyExists: "A measure with that name already exists",
          operation: "Operation",
          chooseOperation: "Choose operation...",
          numerators: "Numerators",
          chooseColumns: "Choose columns...",
          chooseColumn: "Choose column...",
          denominators: "Denominators",
          columnsAddInfo:
            "The columns added in each input will be automatically summed.",
          columns: "Columns",
          column: "Column",
          modifyMeasure: "modify the measure",
          createNewMeasure: "create a new measure",
          newMeasure: "New measure",
          cancel: "Cancel",
          accept: "Accept",
        },
      },
      measureAdmin: {
        tabs: {
          expenses: "EXPENSES",
          sales: "SALES",
          compounds: "COMPOUNDS",
          created: "CREATED",
          default: "DEFAULT",
          bucket: "BUCKET",
          bucketAgrupator: "BUCKET AGRUPATOR",
        },
        cannotDeleteMeasureAsBeingUsed:
          "This measure cannot be deleted as it is being used by a compound measure.",
        searchingMeasures: "Searching measures...",
        loadingMeasures: "Loading measure...",
        measureAdmin: "Measure administration",
        searchByName: "Search by name",
        notMeasuresYet: "No measures available yet.",
        removeMeasure: "remove the measure",
        confirm: "Confirm",
      },
      viewInfoCompoundsModal: {
        generalInfoMeasure: "General measure information",
        formula: "Formula",
      },
      viewInfoSalesModal: {
        file: "File",
        currentBaseColumns: "Current base columns:",
        baseColumnsSales: "Sales base columns:",
        operation: "Operation:",
        columns: "Columns:",
        denominatorColumns: "Denominator columns:",
        groupers: "Groupers",
      },
    },
  },
  delta: {
    postSalesForecast: {
      loading: "Creating Sales Forecast...",
      codes: {
        200: "The Sales Forecast has been created successfully",
        201: "The Sales Forecast has been created successfully",
        400: "An error occurred and the Sales Forecast could not be created",
      },
    },
    putSalesForecast: {
      loading: "Editing Sales Forecast...",
      codes: {
        200: "The Sales Forecast has been successfully edited",
        201: "The Sales Forecast has been successfully edited",
        400: "An error occurred and the Sales Forecast could not be edited",
      },
    },
    deleteSalesForecast: {
      codes: {
        200: "The Sales Forecast has been successfully removed",
        201: "The Sales Forecast has been successfully removed",
        400: "An error occurred and the sales forecast could not be deleted",
      },
      loading: "Removing sales forecast...",
    },
    deleteSimulation: {
      codes: {
        200: "The simulation has been successfully removed",
        201: "The simulation has been successfully removed",
        400: "An error occurred and the simulation could not be deleted",
      },
      loading: "Removing the simulation...",
    },
    postSimulation: {
      loading: "Creating the Simulation...",
      codes: {
        200: "The Simulation has been created successfully",
        201: "The Simulation has been created successfully",
        400: "An error occurred and the Simulation could not be created",
      },
    },
    putSimulation: {
      loading: "Editing the Simulation...",
      codes: {
        200: "The Simulation has been edited successfully",
        201: "The Simulation has been edited successfully",
        400: "An error occurred and the Simulation could not be edited",
      },
    },
  },
  deltaTexts: {
    salesForecast: {
      title: "Sales Forecast",
      salesForecastAdmin: {
        noPredictionsYet: "No predictions generated yet",
        startCreatingOne: "Start creating a new one from an analysis",
        newSalesForecast: "New Sales Forecast",
        deleteSalesForecast: "delete Sales Forecast",
        editSalesForecast: "edit Sales Forecast",
        deleteAssociatedSimulationsAndRules: "All associated rules and simulations will be deleted."
      },
      newSalesForecastModal: {
        initialPeriod: "Initial period",
        chooseInitialPeriod: "Choose initial period...",
        all: "ALL",
        salesMeasuresToForecast: "Sales measures to forecast",
        periodsToForecast: "Number of periods to predict",
        choosePeriods: "Choose number of periods",
      },
      salesForecastCard: {
        allPeriodsExecuted: "All enabled periods were executed.",
        notAllPeriodsExecuted: "Not all enabled periods were executed.",
        enabledPeriodsNonExecuted: "Enabled periods not executed",
        none: "None",
      },
    },
    simulation: {
      title: "Simulation Management",
      simulation: "SIMULATION",
      simulationAdmin: {
        theSimulation: "the simulation",
      },
      simulationCard: {
        errorTryAgain: "There was an error, try again.",
        autoRun: "Auto Run",
        lastPeriodDataExportNeedToBePredicted:
          "The last period of the Data Export needs to be predicted",
      },
      simulationConfig: {
        simulationConfig: "Simulations Configuration",
        autoRun: "Autorun",
        onceRuleChosen:
          "Once the Simulation Rule is selected, the corresponding Sales Forecast data will be loaded and locked for modification.",
      },
      viewInfoModal: {
        routeConfig: "Route Configuration",
        configType: "CONFIGURATION TYPE",
        cancelRoute: "CANCEL",
        modificationRoute: "MODIFICATION",
        registeredRoute: "REGISTER",
        filters: "FILTERS",
        modifications: "MODIFICATIONS",
        projectionMode: "PROJECTION MODE",
        params: "PARAMETERS",
        routesToAdd: "ROUTES TO ADD",
        rule: "RULE",
        simulationName: "SIMULATION NAME",
      },
      modifyModicationModal: {
        previouslySelectedValues: "Previously selected values",
      },
      step3NewModifyRouteModal: {
        averageSelectedRoutes: "Average selected routes:",
        averageRoutesBasesOnSelectedValues:
          "Average routes based on selected values:",
      },
      step2SimulationConfig: {
        noConfigurationsYet: "No configurations yet",
        deleteConfigs: "delete configurations",
      },
    },
    simulationRules: {
      assignDriverModal: {
        assignCostDriver: "Assign Cost Driver",
      },
      configSimulationRule: {
        deleteAllCostDriver: "delete cost drivers",
        deleteCostDriver: "Delete cost driver",
      },
      ruleButtons: {
        noRowsWithDriverToDelete:
          "There are no selected rows with drivers to delete",
        deleteDrivers: "Delete Drivers",
        assignDrivers: "Assign Drivers",
      },
      transformDataRuleTable: {
        measure: "MEASURE",
        variability: "VARIABILITY",
        costDriver: "COST DRIVER",
      },
    },
  },
  dialog: {
    cofirmThat: "Confirm that you want to ",
    cancel: "Cancel",
    accept: "Accept",
    confirmTitle: "Confirm",
    filter: "Filter",
  },
  errorTexts: {
    pageNotExist: "This page does not exist",
    backToHome: "Back to home",
  },
  expensesSettings: {
    sendTransformations: {
      loading: "Saving transformations...",
      codes: {
        200: "Transformations have been successfully applied.",
        201: "Transformations have been successfully applied.",
        400: "An error occurred and transformations could not be applied.",
        500: "An error occurred and transformations could not be applied.",
      },
    },
    getExpenses: {
      error: "An error occurred and expenses could not be retrieved.",
    },
    getView: {
      error: "An error occurred and the view could not be retrieved.",
    },
    saveView: {
      loading: "Saving view...",
      codes: {
        200: "View has been successfully saved.",
        201: "View has been successfully saved.",
        400: "An error occurred and the view could not be saved.",
      },
    },
    expensesSettingsTexts: {
      expensesConfig: "Expenses Configuration",
      applyingTrans: "Applying transformations...",
      savingView: "Saving view...",
      filteredExpense: "Filtered Expense",
      totalExpense: "Total Expense",
      noRowsSelected: "No rows selected",
      modify: "Modify",
      errorDownload:
        "An error occurred and the information could not be downloaded.",
      unaddedTransf:
        "There are unadded transformations. Do you confirm that you want to apply transformations anyway?",
      wantToApply:
        "Do you confirm that you want to apply these transformations?",
    },
    modalContent: {
      valueExists: "*Value already exists",
      emptyFieldsCannotAdded: "*Empty fields cannot be added",
      chooseColumn: "Choose column...",
      add: "Add",
      chooseAddValue: "Choose or add a value...",
      newValue: "New value",
      deleteRow: "Delete row",
      noTransToApply: "No transformations to apply.",
      changeWillAffect: "This change will affect ",
      rows: "rows",
      cancel: "Cancel",
      accept: "Accept",
    },
  },
  forms: {
    text: "Text...",
    select: {
      loading: "Laoding...",
      noOptionsAvailable: "No options available",
    },
  },
  general: {
    yes: "Yes",
    no: "No",
    accept: "Accept",
    add: "ADD",
    all: "All",
    apply: "Apply",
    average: "AVERAGE",
    analysis: "Análisis",
    bimonthly: "Bimonthly",
    bucket: "Bucket",
    buckets: "Buckets",
    bucketGrouper: "Bucket grouper",
    cancel: "Cancel",
    chooseColumn: "Choose column...",
    chooseColumns: "Choose columns...",
    clean: "Clean",
    cleanAll: "Clean all",
    close: "Close",
    column: "Column",
    columns: "Columns",
    composed: "Composed",
    confirm: "Confirm",
    consolidateDestinations: "Consolidate destinations",
    consolidatePeriods: "Consolidate periods",
    count: "COUNT",
    country: "Country",
    currencyy: "Currency",
    foreignCurrency: "Foreign currencies",
    create: "Create",
    created: "Created",
    delete: "Delete",
    deleteAll: "Delete all elements",
    divide: "DIVIDE",
    dividingColumn: "Dividing column",
    dividingColumns: "Dividing columns",
    doNotConsolidate: "Do not consolidate",
    edit: "Edit",
    emptyInputs: "All fields must be completed.",
    errorEmptyField: "The field cannot be empty.",
    expenses: "Expenses",
    file: "File",
    filter: "Filter",
    findByName: "Find by name",
    finish: "Finish",
    first: "FIRST",
    function: "Function",
    grouper: "Grouper",
    home: "Home",
    indicator: "Indicator",
    import: "Import",
    last: "LAST",
    loading: "Loading...",
    maximum: "MAXIMUM",
    minimum: "MINIMUM",
    minValueIs: "The minimum value is",
    month: "Month",
    monthly: "Monthly",
    multiply: "MULTIPLY",
    name: "Name",
    next: "Next",
    none: "None",
    noCardsYet: "No cards available yet.",
    previous: "Previous",
    quarterly: "Quarterly",
    repeatedName: "A name with this name already exists.",
    repeatedRowsError: "There are repeated rows.",
    repeatedRowsName: "Rows with the same name cannot be added.",
    requiredDividingColumn: "The dividing column is required.",
    requiredField: "Required field",
    sales: "Sales",
    searchByName: "Search by name",
    seeMore: "see more",
    selectFile: "Select file...",
    selectValue: "Select value...",
    selectValues: "Select values...",
    selectOption: "Select option...",
    selectOptions: "Select options...",
    since: "Since",
    subtract: "SUBTRACT",
    substractingColumns: "Substracting columns",
    sumProduct: "SUM PRODUCT",
    type: "Type",
    uniqueCount: "UNIQUE COUNT",
    uniqueValues: "UNIQUE VALUES",
    units: "Units",
    currency: "Currency",
    percentage: "Percentage",
    until: "Until",
    usedElement: "This item is being used by another and cannot be deleted.",
    value: "Value",
    valueBetween: "The value must be between",
    variable: "Variable",
    writeName: "Write the name...",
    year: "Year",
    equal: "Equal",
    in: "in",
    notIn: "not in",
    greaterThan: "Greater than",
    greaterThanOrEqual: "Greater than or equal to",
    lessThan: "Less than",
    lessThanOrEqual: "Less than or equal to",
  },
  generatedDownloads: {
    downloads: "Downloads",
  },
  header: {
    settings: "Settings",
  },
  highCharts: {
    analysis: "Analysis",
    periods: "Periods",
    loading: "Loading...",
    filterLoading: "Filtering the analysis...",
    chooseAnalysis: "Choose analysis...",
    filters: "Filters",
  },
  icons: {
    delete: "Delete row",
    deleteAll: "Delete all",
    edit: "Edit",
  },
  importElements: {
    importTheElements: "import the elements",
    importElements: "Import elements",
    willDeleteAll: "This will delete all elements and import the new ones.",
  },
  indicators: {
    getGroups: {
      error: "An error occurred and indicator groups could not be retrieved.",
    },
  },
  indicatorsManagement: {
    addGroup: {
      error: "An error occurred and groups could not be retrieved.",
      codes: {
        200: "The group has been successfully added.",
        201: "The group has been successfully added.",
        400: "An error occurred and the group could not be added.",
      },
    },
    addIndicator: {
      codes: {
        201: "The indicator has been successfully created.",
        400: "An error occurred and the indicator could not be created.",
      },
    },
    editIndicator: {
      codes: {
        200: "The indicator has been successfully updated.",
        400: "An error occurred and the indicator could not be edited.",
      },
    },
    deleteIndicator: {
      codes: {
        200: "The indicator has been successfully deleted.",
        400: "An error occurred and the indicator could not be deleted.",
      },
    },
    putState: {
      codes: {
        200: "The state has been successfully modified.",
        201: "The state has been successfully modified.",
        400: "An error occurred and the state could not be modified.",
      },
    },
  },
  indicatorsManagementTexts: {
    addGroupModal: {
      groupNameAlreadyExists: "A group with this name already exists.",
      fieldCannotEmpty: "The field cannot be empty.",
      newGroup: "New group",
      cancel: "Cancel",
      accept: "Accept",
      name: "Name",
      newGroupName: "New group name",
    },
    chartCard: {
      modifyingChartState: "Modifying chart state...",
      baseFile: "Base file",
      analysis: "Analysis",
      axisY: "Y-axis",
      of: "of",
      graphicType: "Chart type",
    },
    configCharts: {
      none: "None",
      creatingGraphic: "Creating chart...",
      modifyingGraphic: "Modifying chart...",
      editGraphic: "Edit chart",
      createGraphic: "Create chart",
      editTheGraphic: "edit the chart",
      createTheGraphic: "create the chart",
      resetAllGraphSteps: "Reset all chart steps",
      cleanAll: "Clean all",
      finish: "Finish",
      next: "Next",
    },
    configIndicators: {
      exclude: "EXCLUIR",
      include: "INCLUIR",
      totalInExpense: "Total in expenses",
      totalInResult: "Total in results",
      totalTransferred: "Total transferred",
      bucketPeriodsWithError: "Bucket-periods with error",
      nameIndicatorExists: "An indicator with this name already exists.",
      creatingIndicator: "Creating indicator...",
      modifyingIndicator: "Modifying indicator...",
      editIndicator: "Edit indicator",
      createIndicator: "Create indicator",
      editTheIndicator: "edit the indicator",
      createTheIndicator: "create the indicator",
      former: "Former",
      resetAllIndicatorSteps: "Reset all indicator steps",
      cleanAll: "Clean all",
      finish: "Finish",
      next: "Next",
    },
    configTables: {
      creatingTable: "Creating table...",
      modifyingTable: "Modifying table...",
      editTable: "Edit table",
      createTable: "Create table",
      editTheTable: "edit the table",
      createTheTable: "create the table",
      resetAllTableSteps: "Reset all table steps",
      cleanAll: "Clean all",
      finish: "Finish",
      next: "Next",
      confirmDeleteElement:
        "All subsequent elements will be deleted. Do you confirm that you want to delete this element?",
      tableSample: "Table sample",
      newAction: "New action",
      steps:{
        initalParametrization: "Initial parameterization of the table",
        limitResults: "Limit results"
      },
      selectionAnAction: "Select an action",
      theAnalysis: "The analysis",
      theSalesForecast: "The sales forecast",
      theSimulation: "The simulation",
      theTemplate: "The file",
      sourceCannotModify: "cannot be modified after adding an element."
    },
    indicatorCard: {
      unitOfMeasurement: "Unit of measurement",
      group: "Group",
      active: "Active",
      inactive: "Inactive",
    },
    indicatorsManagement: {
      tabs: {
        indicators: "INDICATORS",
        graphics: "GRAPHS",
        files: "FILES",
        analysis: "ANALYSIS",
        simulation: "SIMULATION",
        salesForecast: "SALES_FORECAST",
        tables: "TABLES",
      },
      indicatorConfigFile: "Indicator Configuration - File",
      indicatorConfigAnalysis: "Indicator Configuration - Analysis",
      indicatorConfigAnalysisSimulation: "Indicator Configuration - Simulation",
      indicatorConfigAnalysisSalesForecast:
        "Indicator Configuration - Sales Forecast",
        indicatorConfigTableFile: "Table Configuration - File",
        indicatorConfigTableAnalysis: "Table Configuration - Analysis",
        indicatorConfigTableSimulation: "Table Configuration - Simulation",
        indicatorConfigTableSalesForecast:"Table Configuration - Sales Forecast",
      graphicConfigFile: "Graphic Configuration - File",
      graphicConfigAnalysis: "Graphic Configuration - Analysis",
      graphicConfigAnalysisSimulation: "Graphic Configuration - Simulation",
      graphicConfigAnalysisSalesForecast:
        "Graphic Configuration - Sales Forecast",
      indicatorAdmin: "Dashboard Administration",
      uploadingIndicators: "Uploading indicators...",
      uploadingTables: "Uploading tables...",
      uploadingGráficos: "Uploading graphs...",
      deletingIndicator: "Deleting indicator...",
      deletingTable: "Deleting table...",
      deletingGraphics: "Deleting graph...",
      searchByNameIndicatorGroup: "Search by indicator name or group",
      searchByTableName: "Search by table name",
      activeIndicators: "ACTIVE INDICATORS",
      nonActiveIndicators: "No active indicators.",
      inactiveIndicators: "INACTIVE INDICATORS",
      nonInactiveIndicators: "No inactive indicators.",
      activeTables: "ACTIVE TABLES",
      nonActiveTables: "No active tables.",
      inactiveTables: "INACTIVE TABLES",
      nonInactiveTables: "No inactive tables.",
      nonPossibleGetIndicators:
        "Unable to get indicators. Please try again later.",
      nonPossibleGetTables: "Unable to get tables. Please try again later.",
      searchByNameGraphicCoord: "Search by graph name or coordinates",
      activeGraphics: "ACTIVE GRAPHS",
      nonActiveGraphics: "No active graphs.",
      inactiveGraphics: "INACTIVE GRAPHS",
      nonInactiveGraphics: "No inactive graphs.",
      nonPossibleGetGraphics: "Unable to get graphs. Please try again later.",
      deleteTheIndicator: "delete the indicator",
      deleteTheGraphic: "delete the graph",
      deleteTheTable: "delete the table",
      confirm: "Confirm",
    },
    step1: {
      simulation: "Simulation",
      salesForecast: "Sales Forecast",
      coin: "Currency",
      percentage: "Percentage",
      units: "Units",
      creatingIndicatorGroup: "Creating indicator group...",
      description: "Description",
      indicatorName: "Indicator Name",
      unitOfMeasurement: "Unit of Measurement",
      chooseUnit: "Choose unit...",
      chooseCreateGroup: "Choose or create group...",
      parameterizationIndicator: "Indicator Parameterization",
      file: "File",
      analysis: "Analysis",
      chooseFile: "Choose file...",
      chooseAnalysis: "Choose Analysis...",
      chooseSimulation: "Choose Simulation...",
      chooseSalesForecast: "Choose Sales Forecast...",
      column: "Column",
      chooseColumn: "Choose column...",
      function: "Function",
      chooseFunction: "Choose function...",
      inactiveIndactor: "Inactive Indicator",
      activeIndicator: "Active Indicator",
      singleSum: "Single Sum",
      uniqueCriteriaColumn: "Unique Criteria Column",
      productSum: "Product Sum",
      columnToMultiply: "Column to Multiply",
      chooseOneOrMoreOptions: "Choose one or more options...",
      numerator: "Numerator",
      denominator: "Denominator",
    },
    step1Charts: {
      aplicationOptions: "APPLICATION OPTIONS",
      periods: "periods",
      fileColumns: "FILE COLUMNS",
      creatingIndicatorGroup: "Creating indicator group...",
      graphParameterization: "Graph Parameterization",
      graphicTitle: "Graph Title",
      writeGraphicName: "Write graph name",
      graphicNameExists: "A graph with this name already exists",
      file: "File",
      chooseFile: "Choose file...",
      axisY: "Y-axis",
      chooseColumn: "Choose column...",
      baseColumn: "Base column",
      axisYFunction: "Y-axis function",
      baseColumnFunction: "Base column function",
      chooseFunction: "Choose function...",
      axisYNameOptional: "Y-axis name (optional)",
      writeAxisYName: "Write Y-axis name",
      axisX: "X-axis",
      chooseOption: "Choose an option...",
      axisXNameOptional: "X-axis name (optional)",
      writeAxisXName: "Write X-axis name",
      drillDownColumn: "Drilldown column",
      optional: "optional",
      serieColumnOptional: "Series column (optional)",
      valuesColumnOptional: "Values column (optional)",
      none: "None",
      group: "Group",
      chooseCreateGroup: "Choose or create group...",
      activeGraphic: "Active Graph",
      auxiliaryLines: "Auxiliary lines",
      line0AxisY: "Line 0 Y-axis",
      averageLineAxisX: "Average line X-axis",
      averageLineAxisY: "Average line Y-axis",
    },
    viewInfoModal: {
      columns: {
        column: "Column",
        values: "Values",
        criteria: "Criteria",
        function: "Function",
        name: "Name",
      },
      exlude: "Exclude",
      include: "Include",
      fileColumn: "File column",
      analysis: "Analysis",
      externFileColumn: "External file column",
      criteriaBaseColumn: "Base Criteria Column",
      generalInfoGraph: "General Graph Information",
      generalInfoIndicator: "General Indicator Information",
      unitOfMeasurement: "Unit of Measurement",
      graphType: "Graph Type",
      group: "Group",
      baseFile: "Base File",
      baseColumn: "Base Column",
      axisY: "Y-axis",
      axisYName: "Y-axis Name",
      appliedFunction: "Applied Function",
      validateUniqueColumn: "Validate Unique Column",
      multiplyColumn: "Multiply Columns",
      axisX: "X-axis",
      axisXName: "X-axis Name",
      drilldownColumn: "Drilldown Columns",
      serialColumns: "Series Columns",
      valuesColumn: "Values Column",
      filtersOnFile: "Filters on File",
      filterOf_one: "Filtering of",
      filterOf_two: "with information from",
      prefilterOnFile: "Prefilters on File",
      crossoverValues: "Crossover Values of",
      with: "with",
    },
    validateWaterfallRow: {
      rowNameAlreadyExists: "A row with this name already exists",
      repeteadLines: "There are repeated lines",
      allFieldsMustBeCompleted: "All fields must be completed",
    },
  },
  indicatorsView: {
    getIndicators: {
      error:
        "An error occurred and it was not possible to retrieve the indicators",
      loading: "Getting indicators...",
    },
    getIndicatorChart: {
      codes: {
        200: "Chart successfully retrieved",
        400: "An error occurred and it was not possible to retrieve the chart for the indicator",
      },
    },
    indicators: "Indicators",
    periods: "Periods",
    nonPossibleGetIndicators:
      "Unable to get the indicators. Please try again later.",
    noIndicatorsCreated: "No indicators have been created yet.",
  },
  language: {
    languages: "Languages",
  },
  login: {
    loginLoading: "Logging in...",
    getInto: "Log In",
    login: "Log In",
    email: "Email",
    password: "Password",
    loginError: "The username or password is incorrect.",
    loginProblem: "An error occurred while logging in. Please try again.",
    rememberAuth: "Remember authentication code",
    storeAuth:
      "Store authentication code for one week for two-step authentication",
    trust: "Trust this device",
    trustLogin:
      "Keep the session active on this device for 24 hours. Use only on non-public devices.",
  },
  marketBasket: {
    mergeFiles: "Merge files",
    channel: "Channel",
    skuColumn: "SKU column",
    transactionColumn: "Transaction column",
    transactionIdentifierColumn: "Transaction identifier column",
    extraColumns: "Extra columns",
    extraColumnsForAnalysis: "Extra columns for analysis",
    charts: "Charts",
    destinyIndicators: "Destiny indicators",
    brand: "Marca",
    analysisLevel: "Analysis level",
    currencySales: "Currency sales",
    unitSales: "Unit sales",
    periodicity: "Periodicity",
    primarySkuMetrics: "Primary SKU metrics",
    secondarySkuMetrics: "Secondary SKU metrics",
    primaryDestinyMetrics: "Primary destiny metrics",
    secondaryDestinyMetrics: "Secondary destiny metrics",
    objectives: "Objectives",
    optional: "Optional",
    advancedParameters: "Advanced parameters",
    advancedParametersOfAlgoritm: "Advanced parameters of algorithm",
    initialParameterization: "Initial parameterization",
    skuLevelMetrics: "SKU metrics",
    destinyLevelMetrics: "Destiny metrics",
    chartsa: "Charts",
    indicators: "Indicators",
    admin: {
      title: "Market Basket Administration",
      searchByName: "Search by name",
      noAnalysisYet: "No analyses available yet.",
      deleteAnalysisConfirm: "Are you sure you want to delete the analysis?",
      confirm: "Confirm",
      analysisAdmin: "Analysis Administration",
      periods: "Periods",
      analysisInfo: "General analysis information",
      viewInfoModal: {
        title: "Analysis Information",
        mergeFiles: "Merge files",
        channel: "Channel",
        skuColumn: "SKU column",
        transactionColumn: "Transaction column",
        extraColumns: "Extra columns",
        charts: "Charts",
        destinyIndicators: "Destiny indicators",
        brand: "Marca",
        analysisLevel: "Analysis level",
        currencySales: "Currency sales",
        unitSales: "Unit sales",
        periodicity: "Periodicity",
        primarySkuMetrics: "Primary SKU metrics",
        secondarySkuMetrics: "Secondary SKU metrics",
        primaryDestinyMetrics: "Primary destiny metrics",
        secondaryDestinyMetrics: "Secondary destiny metrics",
        objectives: "Objectives",
        advancedParameters: "Advanced parameters",
      },
      delete: {
        codes: {
          200: "The analysis has been successfully deleted",
          400: "An error occurred and the analysis could not be deleted",
        },
        loading: "Deleting analysis...",

        confirmationModal: {
          title: "Delete analysis",
          message: "Are you sure you want to delete the analysis?",
        },
      },
      getAnalysis: {
        codes: {
          200: "Analyses were retrieved successfully",
          400: "An error occurred and analyses could not be retrieved",
        },
        loading: "Getting analyses...",
      },
    },
    config: {
      title: "Market Basket Configuration",
      analysisName: "Analysis name",
      codes: {
        200: "The analysis has been successfully created",
        201: "The analysis has been successfully created",
        400: "An error occurred and the analysis could not be created",
      },
      calculate: {
        codes: {
          200: "Calculation has been successfully initiated",
          201: "Calculation has been successfully initiated",
          400: "An error occurred and calculation could not be initiated",
        },
      },
      loading: "Creating analysis...",
      calculating: "Calculating analysis...",
      confirmationModal: {
        title: "Save analysis",
        message: "Are you sure you want to save the analysis?",
      },
      indicators: "Indicators",
      customerChain: "Customer chain",
      pointOfSale: "Point of sale",
      fileOrAnalysisWithInformation: "File or analysis with information",
      warningText:
        "Modifying or deleting the file will clear all related fields.",
      addFont: "Add file",
      otherFiles: "Other files to merge with the main file.",
      primaryMetricsAtSkuLevel: "Primary metrics at SKU level",
      secondaryMetricsAtSkuLevel: "Secondary metrics at SKU level",
      secondaryMetricsCalculation: "Secondary metrics calculation",
      primaryMetricsAtDestinyLevel: "Primary metrics at destiny level",
      secondaryMetricsAtDestinyLevel: "Secondary metrics at destiny level",
      groupingVariable: "Grouping variables",
      chooseIndicator: "Choose indicator...",
      newChart: "New chart",
      clickOnIndicator:
        "In order to see the monthly evolution detail, click on the indicator.",
      indicatorExample: "Indicator example",
      generateIndicatorFor: "Generate indicator for",
      generateDrilldownMonthlyEvolution: "Generate drilldown monthly evolution",
      monthlyEvolutionDrilldown: "Monthly evolution drilldown",
      objectivesCollection: "Objectives collection",
      columnTargetValue: "Column with target value",
      ownerIdentifierTarget: "Owner identifier of the target",
      targetColumnInAnalysis: "Target column in analysis",
      ownerIdentifierTargetInAnalysis:
        "Owner identifier of the target in analysis",
      confirmCleanAll: "Are you sure you want to clean all fields?",
      minimumSupportApriori: "Minimum Apriori Support",
      minimumSupportSet: "Minimum Set Support",
      minimumSupportAntecedent: "Minimum Antecedent Support",
      minimumSupportConsequent: "Minimum Consequent Support",
      minimumConfidence: "Minimum Confidence",
      minimumLift: "Minimum Lift",
      unitError: "There are indicators without assigned unit of measurement.",
    },
    view: {
      rateNotLessMinus100: "The rate cannot be less than -100%",
      valueNotLessZero: "The value cannot be less than 0",
      simulateScenario: "Simulate scenario",
      deleteSimulation: "Delete simulation",
      modifyRateOrValues:
        "To simulate a scenario, modify the rate or sales volume values.",
      oportunityForSku: "Opportunity for SKUs already purchased",
      forSku: "for the SKU",
      scenariosSimulation: "Scenarios simulation",
      simulationDescription:
        "To simulate a scenario, select the rows in the table to adjust and click on 'Simulate Scenario'. The results will be reflected in the graphs.",
      COGS_PORCENTUAL: "PERCENTUAL COGS",
      PRECIO_UNITARIO: "UNIT PRICE",
      VENTA_BRUTA_POTENCIAL: "POTENTIAL GROSS SALES",
      CONTRIBUCION_BRUTA_POTENCIAL: "POTENTIAL GROSS CONTRIBUTION",
      VOLUMEN_PROMEDIO: "AVERAGE VOLUME",
      CONTRIBUCION_BRUTA_UNITARIA: "UNIT GROSS CONTRIBUTION",
      ROTACION_PROMEDIO: "AVERAGE TURNOVER",
      ANTECEDENTS: "ANTECEDENTS",
      ANTECEDENT_SUPPORT: "ANTECEDENT SUPPORT",
      SKU_SUPPORT: "SKU SUPPORT",
      SKU_ANTECEDENTS_SUPPORT: "SKU AND ANTECEDENTS SUPPORT",
      CONFIDENCE: "CONFIDENCE",
      LIFT: "LIFT",
      LEVERAGE: "LEVERAGE",
      CONVICTION: "CONVICTION",
      ZHANGS_METRIC: "ZHANG'S METRIC",
      ROTACION_CLIENTE: "CUSTOMER TURNOVER",
      MES_DESDE_ULTIMA_COMPRA: "MONTHS SINCE LAST PURCHASE",
      VENTA_MONEDA_PROMEDIO: "AVERAGE SALES CURRENCY",
      VENTA_UNIDADES_PROMEDIO: "AVERAGE SALES UNITS",
      VENTA_UNIDADES: "SALES UNITS",
      VENTA_MONEDA: "SALES CURRENCY",
      CLIENTES_CON_OPORTUNIDAD: "CUSTOMERS WITH OPPORTUNITY",
      OPORTUNIDAD: "OPPORTUNITY",
      OPORTUNIDAD_PARA_FILTRO: "OPPORTUNITY FOR FILTER",
    },
  },
  menuItemsOptions: {
    companyParameterization: {
      title: "Company Parameterization",
      url: "parametrizacion-empresa",
    },
    dataManagement: {
      title: "Data Management",
      url: "administracion-datos",
    },
    validations: {
      title: "Validations",
      adminValidations: {
        title: "Validations Management",
        url: "administracion-validaciones",
      },
      configCodeMatching: {
        title: "Code Matching Configuration",
      },
      matchesDashboard: {
        title: "Matches Dashboard",
        url: "configuracion/cruce-codigos",
      },
    },
    criteriaManagement: {
      title: "Criteria Management",
      url: "administracion-criterios",
    },
    preProrationParameterization: {
      title: "Pre-Proration Parameterization",
      url: "parametrizacion-preprorrateo",
    },
    configPreProratedExpenses: {
      title: "Configure Pre-Prorated Expenses",
      url: "configurar-gastos-preprorrateo",
    },
    configProRataExpenses: {
      title: "Configure Pro-Rata Expenses",
      url: "configuracion-gastos",
    },
    modelParameterization: {
      title: "Model Parameterization",
    },
    parameterization: {
      title: "Parameterization",
    },
    controlPanel: {
      title: "Control Panel",
      url: "tablero-control",
    },
    dashboard: {
      title: "Dashboard",
      url: "",
    },
    taskManager: {
      title: "Task Manager",
      url: "gestor-tareas",
    },
    indicatorManagement: {
      title: "Dashboard Management",
      url: "administracion-dashboard",
    },
    dataExportModelling: {
      title: "Data Export Modelling",
      url: "",
    },
    simulator: {
      title: "Delta",
    },
    simulatorCollapseList: {
      salesForecast: {
        title: "Sales Forecast",
        url: "/delta/sales-forecast",
      },
      simulationRules: {
        title: "Simulation rules",
        url: "/delta/administracion-reglas",
      },
      simulations: {
        title: "Simulations Management",
        url: "/delta/simulaciones",
      },
      registeredRoutes: {
        title: "Routes administration",
        url: "/delta/administracion-rutas",
      },
      simulationsResults: {
        title: "Simulations results",
        url: "/delta/resultados-simulacion",
      },
    },
    generatedDownloads: {
      title: "Downloads",
      url: "descargas-generadas",
    },
    parameterizationCollapseList: {
      assignStages: {
        title: "Assign Stages",
        url: "parametrizacion-modelo/asignacion-etapas",
      },
      criteriaCalculation: {
        title: "Criteria and Calculation",
        url: "parametrizacion-modelo/calculo",
      },
      calculationReport: {
        title: "Calculation Report",
        url: "parametrizacion-modelo/reporte",
      },
    },
    dashboardCollapseList: {
      indicators: {
        title: "Indicators",
        url: "dashboard/indicadores",
      },
      graphics: {
        title: "Graphics",
        url: "dashboard/graficos",
      },
      analysis: {
        title: "Analysis",
        url: "dashboard/highcharts",
      },
      powerBi: {
        title: "Power BI",
        url: "dashboard/powerbi",
      },
    },
    dataExportModellingCollapseList: {
      measureManagement: {
        title: "Measure Management",
        url: "data-modelling/administracion-medidas",
      },
      groupingsManagement: {
        title: "Groupings Management",
        url: "data-modelling/administracion-agrupaciones",
      },
      analysisManagement: {
        title: "Analysis Management",
        url: "data-modelling/administracion-analisis",
      },
      analysisExecution: {
        title: "Analysis Execution",
        url: "data-modelling/ejecucion-analisis",
      },
      downloadReport: {
        title: "Download Report",
        url: "data-modelling/descargar-reporte",
      },
    },
    administrator: {
      title: "Administrator",
      url: "/administrador",
    },
    marketBasketCollapseList: {
      title: "Market Basket",
      admin: {
        title: "Market Basket Administration",
        url: "market-basket/administracion-analisis",
      },
      execution: {
        title: "Market Basket Execution",
        url: "market-basket/ejecucion-analisis",
      },
      analysis: {
        title: "Market Basket Analysis",
        url: "market-basket/analisis",
      },
    },
  },
  modelParameterization: {
    sendStages: {
      loading: "Saving stages...",
      codes: {
        200: "Stages were assigned successfully.",
        201: "Stages were assigned successfully.",
        400: "An error occurred and it was not possible to assign the stages.",
      },
    },
    postCalculateProrration: {
      codes: {
        200: "Calculation started successfully",
      },
    },
    postResetCalculation: {
      codes: {
        200: "Calculation reset successfully",
        400: "An error occurred and it was not possible to reset the calculation",
      },
    },
    propagateCriteria: {
      loading: "Propagating criteria...",
      codes: {
        200: "Criteria propagated successfully",
        400: "An error occurred and it was not possible to propagate the criteria",
      },
    },
    deleteCriteria: {
      singularCodes: {
        200: "Criteria deleted successfully",
        400: "An error occurred and it was not possible to delete the criteria",
      },
      singularLoading: "Deleting criteria...",
      pluralCodes: {
        200: "Criteria deleted successfully",
        400: "An error occurred and it was not possible to delete the criteria",
      },
      pluralLoading: "Deleting criteria...",
    },
    dataForDummy: {
      codes: {
        200: "Data loaded successfully",
        400: "An error occurred and it was not possible to load the data",
      },
    },
    getGrouperRows: {
      loading: "Getting information...",
      codes: {
        400: "An error occurred and it was not possible to download the rows.",
      },
    },
    getCriteriaData: {
      loading: "Getting information...",
      codes: {
        400: "An error occurred and it was not possible to download the criteria information.",
      },
    },
    getProrationResults: {
      codes: {
        200: "Results retrieved successfully.",
        400: "An error occurred and it was not possible to download the results",
        500: "An error occurred and it was not possible to download the results",
      },
      loading: "Getting results...",
    },
  },
  modelParameterizationTexts: {
    buckets: {
      error:
        "An error occurred and it was not possible to download the buckets",
    },
    calculateBucketButton: {
      rowsWithoutAssignedCriteria:
        "All rows must have an assigned criteria to start Bucket calculation.",
      amountIsZero: "Bucket calculation cannot start if the expense is $0",
      calculated: "Calculated",
      notCalculated: "Calculate bucket",
      calculateActualBucket: "calculate the current bucket",
      calculateBucketWithNotTransferred:
        "calculate bucket with untransferred expenses",
    },
    calculations: {
      title: "Model Parameterization: Criteria Assignment and Calculation",
      periods: "Periods",
    },
    charts: {
      origin: "Origin",
      percentage: "Percentage",
      stage: "Stage",
      coin: "Currency",
      formatterTitle: "Percentage relative to the origin expense",
    },
    criteriaModelContent: {
      propagatingCriteria: "Propagating criteria...",
      selectReceiver: "Select receiver",
      selectCriteria: "Select criteria",
      apportionmentCriteria: "Proration Criteria",
      destinationCriteria: "Assign criteria differentiated by destination",
      only50rows: "Valid for up to 50 rows only",
      completeFields: "Complete all fields",
      finalRecipientOfExpense: "FINAL RECIPIENT OF EXPENSE",
      chooseReceiver: "Choose receiver...",
      confirm: "Confirm",
      cancel: "Cancel",
      confirmationMessage:
        "Confirm that you want to assign the criteria? This action will reset the calculation if it was already started.",
    },
    customModelCell: {
      errorDownload:
        "An error occurred and it was not possible to download the information.",
    },
    customToolbar: {
      confirmationWithRowsFirstPart:
        "Do you confirm you want to delete the criteria of ",
      confirmationWithRowsSecondPart:
        "rows? This action will reset the calculation.",
      confirmationsWithoutRows:
        "Do you confirm you want to delete the criteria of the row? This action will reset the calculation.",
      downloadingCSV: "Downloading CSV file...",
      deletenCriteria: "Deleting criteria",
      exportCSV: "Export to CSV",
      totalCost: "Total Expense",
      noCostDifInStageZero: "No differentiated expenses in stage 0.",
      noCostDif: "No groupers have differentiated expenses.",
      difByDestiny: "Differentiate by destination",
      noRowsSelectedWithCriterias: "No selected rows with criteria to delete.",
      deleteCriterias: "Delete criteria",
      noRowsSelected: "No rows selected.",
      difReceivers:
        "Criteria cannot be assigned to groupers with different receivers.",
      noCriteriaStage0: "Criteria cannot be assigned to stage 0.",
      assingCriteria: "Assign Criteria",
      confirm: "Confirm",
      close: "Close",
      accept: "Accept",
    },
    customTypographyTableText: {
      mount: "amount",
    },
    dummyGrouperGroup: {
      groupers: "Groupers",
      expenseColumn: "Expense Column",
      salesFileColumn: "Sales File Column",
    },
    iterationsButtons: {
      iterations: "ITERATIONS",
    },
    getGroupingColDef: {
      grouper: "Grouper",
      collapse: "Collapse",
      expand: "Expand",
    },
    calculationTabValue: {
      nonTransferredExpenses: "NON-TRANSFERRED EXPENSES",
    },
    modelTable: {
      succesEditName: "Name edited successfully",
      errorEditName: "An error occurred while editing the name",
      grouper: "grouper",
      all: "all",
      withoutCritera: "without criteria",
      editName: "Edit name",
      newName: "New name...",
      apply: "Apply",
      editingName: "Editing name...",
      putNewName: {
        codes: {
          200: "Name edited successfully",
          400: "An error occurred while editing the name",
        },
        loading: "Editing name...",
      },
      getCriteriaTargetError:
        "An error occurred and the targets could not be retrieved",
      getCriteriaError:
        "An error occurred and the criteria could not be retrieved",
    },
    onChangeSelectValue: {
      stage: "stage",
      column: "column",
      doNotSend: "do not send",
    },
    useRadioGroup: {
      doNotValidate: "doNotValidate",
      doNotValidateLabel: "Do Not Validate",
      flaw: "flaw",
      flawLabel: "By Default",
      other: "other",
      otherLabel: "Other Column",
      file: "File",
      column: "Column",
      chooseColumn: "Choose column",
    },
    resetButton: {
      toReset: "Reset",
      resetOptions: "RESET OPTIONS",
      currentStageCalculation: "current stage calculation",
      resetCurrentStageCalculation: "Reset current stage calculation",
      allCalculation: "all calculation",
      resetAllCalculation: "Reset all calculation",
      calculationStageBucket: "calculation and bucket stages",
      resetCalculationStageBucket: "Reset calculation and bucket stages",
      allCalculationBucketStages: "all calculation and bucket stages",
      resetAllCalculationStages: "Reset all calculation and bucket stages",
      currentIterationCalculation: "current iteration calculation",
      resetCurrentIterationCalculation: "Reset current iteration calculation",
    },
    stagesButtons: {
      stage: "STAGE",
    },
    step2: {
      tabValueExpenses: "EXPENSES",
      tabValueExpensesNotTransferred: "NON-TRANSFERRED EXPENSES",
      calculateEverything: "calculate everything",
      calculateEverythingTitle: "Calculate Everything",
      startingCalculation: "Loading calculation...",
      resetting: "Resetting...",
      checkPreviousStageCalculationError:
        "There was an error and duplicate groupers were left. Check calculation from the previous stage.",
      assignCriteria: "Assign Criteria",
      resetCalcultatonStageBucket: "reset calculation and bucket stages",
      resetAllCalculationsStages: "reset all calculation and bucket stages",
      confirmPartOne: "Do you confirm you want to ",
      confirmPartTwo: " This action will redirect you to stage assignment",
      confirm: "Confirm",
      stillExpensesNotTransferredStage:
        "There are still non-transferred expenses in stage",
      confirmStartCalculationAnyway:
        "Do you confirm you want to start the calculation anyway",
      confirmStartCalculation: "Confirm calculation start",
      editCriteria: "Edit criteria",
      apportionment: "APPORTIONMENT",
      reassingNewCriteriaInAllGroupersWithReceiverAndOldCriteria:
        "Do you want to reassign the new criteria in all groupers with the same receiver and old criteria?",
      reassingCriteriaToRows: "Reassign criteria to rows",
      close: "close",
      resetCalculationCurrentIteration: "reset current iteration calculation",
      all: "all",
      calculateCurrentBucket: "calculate current bucket",
    },
    switchListSecondary: {
      onlyGroupersWithDifferentiatedExpenses:
        "Only groupers with differentiated expenses are shown.",
      recordedInGrouperMessagePartOne:
        "records in this grouper. For performance, the limit to differentiate expenses by destination is ",
      recordedInGrouperMessagePartTwo: "records",
      noGroupersWithDifferentiatedExpenses:
        "No groupers have differentiated expenses.",
    },
    totalAmount: {
      expense: "Expense",
    },
    transformData: {
      destination: "destination",
      oldReceiverCode: "old receiver code",
      totalExpense: "Total Expense",
    },
    nestedList: {
      flaw: "flaw",
      relatedGroupers: "Related Groupers:",
    },
    viewDummyModal: {
      expensesColumn: "Expense Column",
      fileSalesColumn: "File Sales Column",
      stage: "stage",
      dummyDirectTransfer: "Dummy Direct Transfer",
      criteriaInfo: "Criteria Information",
      finalRecieverExpense: "Final Receiver of Expense",
    },
    columnsTreeData: {
      getColumns: {
        grouper: "agrupador",
        amount: "monto",
        stages: "etapas",
        amountExpense: "MONTO GASTO",
      },
      getGroupingColDef: {
        bucketExpense: "BUCKET EXPENSE",
        collapse: "Collapse",
        expand: "Expand",
      },
    },
    formattedRows: {
      missingAssingStages: "Missing stage assignment",
      assignStages: "Assigned stages",
    },
    stages: {
      stages: "Stages",
      nowRowsSelected: "No rows selected",
      assignToSelected: "Assign to selected",
      stage: "stage",
    },
    stagesSettings: {
      stagesSettingTitle: "Model Parameterization: Stage Assignment",
      periods: "Periods",
    },
    step1: {
      stagesGreaterThan10: "The number of stages cannot be greater than 10",
      stagesGreaterThan0: "The number of stages must be greater than 0",
      missingToAssingStages: "Some groupers are missing stage assignments.",
      editingCauseCleared:
        "Editing the number of stages for a grouper will clear its criterion assignment if it had one.",
      assigningStages: "Assigning Stages",
    },
    treeDataCustomGroupingColumn: {
      successfullyEditName: "The name has been successfully edited",
      errorEditName: "An error occurred while editing the name",
      all: "all",
      noRows: "no rows",
      errorNotPossibleToDownload:
        "An error occurred and it was not possible to download the information",
      editName: "Edit name",
      newName: "New name...",
      apply: "Apply",
      unifiedBucketsGroupers:
        "If the new bucket name matches the name of an existing bucket, they and their groupers will be unified. This action cannot be undone.",
      editingName: "Editing name...",
    },
    customCheckbox: {
      all: "All",
      withoutCriteria: "without criteria",
      rowsWithoutCriteria: "Rows without assigned criteria",
      withoutRow: "without row",
      rowsWithoutStages: "Rows without assigned stage",
    },
    customGridTreeDataGroupingCell: {
      editName: "Edit name:",
    },
    utils: {},
    dummyTransfer: "DUMMY TRANSFER",
    validationDropdown: {
      codeValidation: "Code Validation",
      stage: "Stage",
    },
  },
  pageinProgress: {
    underDevelopment: "This page is still under development",
  },
  periods: {
    multipleSelectCheckmarks: {
      periods: "Periods",
    },
    enabledPeriodsTable: {
      year: "Year",
      enabledPeriods: "Enabled Periods",
      notEnabledPeriods: "Not Enabled Periods",
      nonExistingPeriods: "Non-existing Periods",
    },
    periodsDialogContent: {
      filterPeriodsSuccess: "Filters for periods applied successfully.",
      errorSendingPeriods: "Error sending periods",
      applyingPeriods: "Applying periods...",
      filter: "Filter",
      noPeriodsExported: "No periods exported",
      selectAll: "Select All",
      cleanAll: "Clean All",
      enabledPeriods: "Enabled Periods",
      onlyWithExpenses: "only those with expenses are shown",
      noPeriodsAvailableYet: "There are no periods available yet",
      cancel: "Cancel",
      apply: "Apply",
    },
    periodsFilter: {
      periods: "Periods",
    },
  },
  persistLogin: {
    logoutLoading: "Logging out...",
    role: "Client",
  },
  preprorration: {
    preprorrationCalculate: {
      loading: "Loading calculation...",
      codes: {
        200: "Calculation started successfully",
        201: "Calculation started successfully",
      },
    },
  },
  preprorationParameterization: {
    getPreprorationBuckets: {
      loading: "Getting buckets...",
      error:
        "An error occurred and it was not possible to download the buckets.",
    },
    getPreprorationGrouper: {
      loading: "Getting groupers...",
      error:
        "An error occurred and it was not possible to download the groupers.",
    },
    schemaInfoModal: {
      bucketExpenseUnderscore: "EXPENSE_BUCKET",
      bucketExpense: "EXPENSE BUCKET",
      grouper: "GROUPER",
      percentage: "Percentage",
      criteriaOutline: "Criteria Outline",
    },
    api: {
      getPreprorationCriterias: {
        errorReceiveCriteria: "Error receiving criteria",
      },
    },
    newSchemaContent: {
      criteria: "criterion",
      percentage: "Percentage",
      schemaWithNameAlreadyExists: "A schema with this name already exists",
      necessaryCompleteFields: "All fields must be completed",
      alreadyCriteriaApplied:
        "A criterion is already applied to the selected destination",
      percentageCannotLessOne: "Percentage cannot be less than 1%",
      alreadyPercentageApplied:
        "A percentage is already applied to the selected destination",
      percentageCannotExceedOneHundred:
        "The total percentage cannot exceed 100%",
      necessaryCompletFieldsForSchema:
        "All fields must be completed to create a schema",
      totalPercentageMustBeOneHundred:
        "The total percentage of criteria must be 100%",
      schemaName: "Schema Name",
      bucketExpense: "Expense Bucket",
      bucketDestination: "destination bucket",
      grouperDestination: "destination grouper",
      add: "Add",
      chooseAddValue: "Choose or add value...",
      chooseCriteria: "Choose criterion...",
      cancel: "Cancel",
      save: "Save",
      choosePercentage: "Choose percentage...",
    },
    preprorationParameterization: {
      tabs: {
        bucketExpense: "Expense Bucket",
        grouper: "Grouper",
        noExpensesToPreprorate: "There are no expenses to preprorate in",
      },
      schemaTabs: {
        selectSchema: "Select Schema",
        createSchema: "Create Schema",
      },
      criteria: "Criterion",
      applySchemas: {
        codes: {
          200: "Schemas applied successfully",
          201: "Schemas applied successfully",
          400: "An error occurred and it was not possible to apply the schemas",
        },
      },
      createSchema: {
        codes: {
          200: "Schema created successfully",
          201: "Schema created successfully",
          400: "An error occurred and it was not possible to create the schema",
        },
      },
      reverseCalculationMessages: {
        codes: {
          200: "Calculation reversed successfully",
          201: "Calculation reversed successfully",
          400: "An error occurred and it was not possible to reverse the calculation",
        },
      },
      successApplySchemas: "Schemas applied successfully.",
      successApplySchema: "Schema applied successfully.",
      errorApplySchema: "Error applying schema",
      successCreateSchema: "Schema created successfully",
      errorCreateSchema: "Error creating schema",
      bucketExpensesUnderscore: "EXPENSE_BUCKET",
      successReversedCalculation: "Calculation reversed successfully.",
      errorReversedCalculation: "Error reversing calculation",
      schemasToApplyMissing:
        "Calculation cannot be performed, missing schemas to apply",
      noDataToCalculation: "No data to perform calculation",
      applyingSchemas: "Applying schemas...",
      applyingSchema: "Applying schema...",
      creatingSchema: "Creating schema...",
      reversingCalculation: "Reversing calculation...",
      settingUpPreproration: "Preproration parameterization",
      periods: "Periods",
      allRowsNeedSchemas:
        "All rows must have applied schemas to start calculation",
      calculate: "Calculate",
      reverseCalculation: "Reverse calculation",
      assingSchema: "Assign schema",
      errorDownloadingInfo:
        "An error occurred and it was not possible to download the information.",
      noExpensesToPreprorate: "There are no expenses to preprorate in",
      criteriaOutline: "Criteria Outline",
      startCalculation: "start preproration calculation",
      resetCalculation: "reset preproration calculation",
      confirmCalculation: "Confirm calculation",
      confirmReset: "Confirm reset",
    },
    selectSchemaContent: {
      bucketExpense: "Expense Bucket",
      bucketExpenseUnderscore: "EXPENSE_BUCKET",
      grouper: "Grouper",
      criteria: "Criterion",
      chooseExistingSchema: "Choose an existing schema",
      selectSchema: "Select schema...",
      detail: "Detail",
      schemaWillAplly: "The schema will be applied to",
      row: "row",
      selected: "selected",
      cancel: "Cancel",
      apply: "Apply",
    },
  },
  report: {
    percentage: "Percentage",
    incorrectCalculation: "Incorrect Calculation",
    expensesTotal: "Total Expenses",
    resultsTotal: "Total Results",
    totalTransferred: "Total Transferred",
    bucketPeriodsError: "Bucket-Period Errors",
    getPercentages: {
      loading: "Getting period data...",
      error: "An error occurred while fetching period data, please try again.",
    },
    getKpis: {
      loading: "Getting KPIs...",
      error: "An error occurred while fetching KPIs, please try again.",
    },
    getFiles: {
      loading: "Getting files...",
      error: "An error occurred while fetching files, please try again.",
    },
    getAnalysis: {
      loading: "Getting analysis...",
      error: "An error occurred while fetching analysis, please try again.",
    },
    getPeriods: {
      loading: "Getting periods...",
      codes: {
        200: "Periods retrieved successfully.",
        400: "An error occurred while fetching periods, please try again.",
      },
      error: "An error occurred while fetching periods, please try again.",
    },
    getCharts: {
      loading: "Getting charts...",
      error: "An error occurred while fetching charts, please try again.",
    },
  },
  reportTexts: {
    graphicsTab: {
      failedDownload: "Information could not be downloaded",
      downloadingIndicators: "Downloading indicators...",
      resultsIndicators: "RESULTS INDICATORS",
      period: "Period",
    },
    preprorrationErrorsTable: {
      failedDownload: "Information could not be downloaded",
      preProrrated: "PREPRORRATION",
    },
    preprorrationReportTab: {
      tabs: {
        transferredPercentage: "TRANSFERRED PERCENTAGE",
        destinationsByGroup: "DESTINATIONS BY GROUP",
      },
    },
    preprorrationSumTable: {
      failedDownload: "Information could not be downloaded",
      preProrrated: "PREPRORRATION",
    },
    transformDataForSumTable: {
      columns: {
        group: "Group",
        destination: "Destination",
        period: "Period",
        amountExpense: "Expense Amount",
        destinationType: "Destination Type",
        percentage: "Percentage",
        grouper: "Grouper",
        transferred: "Transferred",
        amountExpenseResult: "Result Expense Amount",
        amountExpenseOrigin: "Origin Expense Amount",
        relativeError: "Relative Error",
        correctCalculation: "Correct Calculation",
      },
      coin: "Currency",
    },
    prorrationDifTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationErrorTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationIterationTable: {
      failedDownload: "Information could not be downloaded",
      apportionment: "APPORTIONMENT",
    },
    prorrationReportTab: {
      tabs: {
        percentageTransfer: "PERCENTAGE TRANSFER",
        notTransferredExpenses: "NOT TRANSFERRED EXPENSES",
        transferredPerIteraionExpenses: "TRANSFERRED EXPENSES PER ITERATION",
      },
    },
    transformDataForDifTable: {
      columns: {
        period: "Period",
        bucketExpense: "Expense Bucket",
        grouper: "Grouper",
        mount: "Amount",
        result: "Result",
        difference: "Difference",
        transferred: "Transferred",
      },
    },
    transformDataForErrorsTable: {
      destination1: "DESTINATION_1",
      destination: "DESTINATION",
      expenseMount: "Expense Amount",
      coin: "Currency",
    },
    getColumnsForErrorsTable: {
      columns: {
        bucket: "Bucket",
        destination: "Destination",
        stage: "Stage",
        period: "Period",
      },
    },
    transformDataForIterationTable: {
      columns: {
        solved: "Solved",
        expenseMount: "Expense Amount",
        coin: "Currency",
      },
    },
    getColumnsForIterationTable: {
      columns: {
        mount: "Amount",
        grouper: "Grouper",
        channel: "Channel",
        period: "Period",
        stage: "Stage",
        bucket: "Bucket",
        iteration: "Iteration",
      },
    },
    calculationReport: {
      tabs: {
        graphics: "GRAPHICS",
        approrateReport: "APPORTIONMENT REPORT",
        preProrateReport: "PREPRORATION REPORT",
      },
      calculationReport: "Calculation Report",
    },
    kpi: {
      coin: "Currency",
      units: "Units",
      totalInExpenses: "Total in Expenses",
      totalInResults: "Total in Results",
      totalTransferred: "Total Transferred",
      bucketPeriodsError: "Bucket-Period Errors",
    },
    kpiError: {
      incorrectCalculation: "Incorrect Calculation",
    },
    periodChart: {
      periods: "Periods",
      percentageExchange: "% Change",
      percentage: "Percentage",
    },
  },
  simulator: {
    postRule: {
      loading: "Creating rule...",
      codes: {
        200: "The rule was created successfully",
        201: "The rule was created successfully",
        400: "An error occurred and the rule could not be created",
      },
    },
    putRule: {
      codes: {
        200: "The rule was updated successfully",
        201: "The rule was updated successfully",
        400: "An error occurred and the rule could not be updated",
      },
    },
    getRules: {
      error: "An error occurred and the rules could not be retrieved",
      codes: {
        200: "The rules were retrieved",
        400: "An error occurred and the rules could not be retrieved",
        404: "An error occurred and the rules could not be retrieved",
      },
    },
    deleteRules: {
      error: "An error occurred and the rule could not be deleted",
      codes: {
        200: "The rule was removed",
        400: "An error occurred and the rule could not be deleted",
        404: "An error occurred and the rule could not be deleted",
      },
    },
    getSchemas: {
      error: "An error occurred and the schematics could not be obtained",
      codes: {
        200: "The schematics were obtained",
        400: "An error occurred and the schematics could not be obtained",
        404: "An error occurred and the schematics could not be obtained",
      },
    },
    postSchema: {
      loading: "Creating schema...",
      codes: {
        200: "The schema has been created successfully",
        201: "The schema has been created successfully",
        400: "An error occurred and the schema could not be created",
      },
    },
    putSchema: {
      codes: {
        200: "The schema has been modified successfully",
        201: "The schema has been modified successfully",
        400: "An error occurred and the schema could not be modified",
      },
    },
    deleteSchema: {
      error: "An error occurred and the schema could not be deleted",
      codes: {
        200: "Scheme was deleted",
        400: "An error occurred and the schema could not be deleted",
        404: "An error occurred and the schema could not be deleted",
      },
    },
    postRoute: {
      loading: "Creating route...",
      codes: {
        200: "The route was created successfully",
        201: "The route was created successfully",
        400: "An error occurred and the route could not be created",
      },
    },
    putRoute: {
      codes: {
        200: "The route was updated successfully",
        201: "The route was updated successfully",
        400: "An error occurred and the route could not be updated",
      },
    },
    getRoutes: {
      error: "An error occurred and the routes could not be retrieved",
      codes: {
        200: "The routes were retrieved",
        400: "An error occurred and the routes could not be retrieved",
        404: "An error occurred and the routes could not be retrieved",
      },
    },
    deleteRoutes: {
      error: "An error occurred and the configuration could not be deleted",
      codes: {
        200: "Configuration was deleted",
        400: "An error occurred and the configuration could not be deleted",
        404: "An error occurred and the configuration could not be deleted",
      },
    },
    getAnalysisValues: {
      error: "An error occurred and the parameters could not be retrieved",
      codes: {
        200: "The parameters were retrieved",
        400: "An error occurred and the parameters could not be retrieved",
        404: "An error occurred and the parameters could not be retrieved",
      },
    },
    getSimulationsResults: {
      error: "An error occurred and the simulations could not be obtained",
      codes: {
        200: "The simulations were obtained",
        400: "An error occurred and the simulations could not be obtained",
        404: "An error occurred and the simulations could not be obtained",
      },
    },
    getSimulationResult: {
      error: "An error occurred and the simulation could not be obtained",
      codes: {
        200: "Simulation obtained",
        400: "An error occurred and the simulation could not be obtained",
        404: "An error occurred and the simulation could not be obtained",
      },
    },
  },
  simulatorTexts: {
    simulations: {
      simulationAdmin: {
        simulationAdmin: "Simulations Management",
        noSimulationsYet: "No simulations available yet.",
        step1SimulationConfig: {
          simulationName: "Simulation Name",
        },
      },
      simulationConfig: {
        simulationConfig: "Simulations Configuration",
      },
    },
    salesForecast: {
      newSalesForecastModal: {
        create: "Create",
        salesForecast: "Sales Forecast",
        cannotRepitAnalysis: "An analysis cannot be repeated.",
      },
    },
    simulationsRulesAdmin: {
      simulationAdmin: "Simulation Rules",
      loading: "Loading...",
      deletingRule: "Deleting rule...",
      searchByName: "Search by name",
      noSimulationsRulesYet: "No simulations available yet.",
      deleteSimulationRule: "Delete simulation rule",
      confirm: "Confirm",
      cannotDeleteRuleAsBeingUsed:
        "This rule cannot be deleted, since it is being used.",
      cannotDeleteSchemaAsBeingUsed:
        "This schema cannot be deleted, since it is being used.",
      deleteAssociatedSimulation: "All associated simulations will be deleted."
    },
    configSimulationRule: {
      simulationRuleConfig: "Simulation Rule Configuration",
      loading: "Loading...",
      simulationName: "Simulation Name",
      ruleName: "Rule Name",
      alreadyExisteRuleName: "A rule with this name already exists",
      analysisToUse: "Analysis to use",
      chooseAnalysis: "Choose Analysis",
      noValuesCalculated: "The values ​​necessary to create the simulation rule have not been calculated. Please check the data and try again.",
      costVariability: "Cost Variability",
      assignMultiple: "Assign to multiple",
      dependentVariable: "Cost driver...",
      cancel: "Cancel",
      edit: "Edit",
      save: "Save",
      editSimulationRule: "Edit rule",
      createSimulationRule: "Create a new simulation rule",
      newSimulationRule: "New rule",
      compundsMeasures: "COMPOSITE MEASURES",
      salesMeasures: "SALES MEASURES",
      default: "DEFAULT",
      quantityRoutes: "ROUTE_QUANTITY",
      selectAll: "ALL",
      selectAllWithOutDriver: "W/O DRIVER",
      selectAllWithOutPL: "W/O P&L",
      editRuleWillDeleteSimulationsPeriods: "The associated simulation periods will be rerun"
    },
    simulationRuleCard: {
      analysisToUse: "Analysis to Use:",
    },
    viewInfoRuleModal: {
      infoSimulationRule: "Simulation Rule Information",
      rule: "Rule",
      analysisToUse: "Analysis to Use:",
      columns: {
        measure: "MEASURE",
        variability: "VARIABILITY",
        costDriver: "COST DRIVER",
        plLine: "P&L LINE",
      },
    },
    inputSlider: {
      fixed: "fixed",
      variable: "variable",
    },
    assignVariabilityModal: {
      assignVariability: "Assign Variability",
      cancel: "Cancel",
      accept: "Accept",
      dependentVariable: "Cost driver...",
      plLine: "Assign to P&L line...",
    },
    registerRoutes: {
      configRegisterRoute: {
        registeredRoute: "ROUTE REGISTRATION",
        canceledRoute: "ROUTE CANCELATION",
        modifiedRoute: "ROUTE MODIFICATION",
        registered: "REGISTRATION",
        canceled: "CANCELATION",
        modification: "MODIFICATION",
        registeredRouteConfig: "Route Configuration",
        loading: "Loading...",
        configName: "Configuration Name",
        routeNameAlreadyExists: "A route with that name already exists",
        simulationRule: "Simulation rule",
        chooseSimulationRule: "Choose simulation rule...",
        simulationSchema: "Simulation Schema",
        chooseSimulationSchema: "Choose simulation schema...",
        simulationSchemaCannotChange:
          "Once a configuration is added, the simulation schema cannot be changed.",
        newRegisteredRoute: "Route Registration",
        modifyAclaration: "To edit, select only one route entry at a time.",
        modify: "Modify",
        delete: "Delete",
        noRegistrationConfigYet: "There are no Route Registration settings yet",
        noCancelationConfigYet: "There are no Route Cancelation settings yet",
        noModificationConfigYet: "There are no Route Modification settings yet",
        cancel: "Cancel",
        edit: "Edit",
        save: "Save",
        editConfiguration: "edit the configuration",
        saveConfiguration: "save the configuration",
        newRegisterRoute: "Route configuration",
        deleteRegisterRouteConfiguration: {
          title: "Delete configuration",
          message: "delete the configuration",
        },
        columnGroupingModel: {
          balance: "Balance",
          currentRoutes: "Current Routes",
          registeredRoutes: "Registered Routes",
          newRoutes: "Total Routes",
          simulationParams: "Simulation Parameters",
          volumeProjectioMode: "Volume Projection Mode",
          averageMonthlyVolumePerRoute: "Average Monthly Volume per Route",
          averageMonthlyVolumePerRouteActual: "AMVxR: Current",
          averageMonthlyVolumePerRouteAdjustment: "AMVxR: Adjustment",
          averageMonthlyVolumePerClient: "Average monthly volume per Client",
          averageMonthlyVolumePerClientActual: "AMVxC: Current",
          averageMonthlyVolumePerClientAdjustment: "AMVxC: Adjustment",
          averagePrice: "Average Price",
          clientePerRoute: "Customer per Route",
          clientePerRouteActual: "CxR: Current",
          clientePerRouteAdjustment: "CxR: Adjustment",
        },
      },
      inputAdjustmentRate: {
        adjustmentRate: "Adjustment Rate",
        adjustedValue: "Adjusted Value",
      },
      newRouteModal: {
        noRoutesSelectedFiltered:
          "There are no routes for the selected filters",
        steps: {
          paramSelection: "Filter selection",
          settings: "Settings",
          routesSelection: "Routes selection",
          routeResults: "Route results",
          cancelRoutes: "Routes to cancel",
          routesToModify: "Routes to modify",
        },
        rows: {
          volumeProjectionMode: "Volume Projection Mode",
          averageMonthlyVolumePerRouteActual: "AMVxR: Current",
          averageMonthlyVolumePerRouteAdjustment: "AMVxR: Adjustment",
          volumePerOutletActual: "AMVxC: Current",
          volumePerOuletAdjustment: "AMVxC: Adjustment",
          averagePrice: "Average Price",
          customerPerRouteActual: "CxR: Current",
          customerPerRouteAdjustment: "CxR: Adjustment",
          actualRoutes: "Current Routes",
          registerRoutes: "Registered Routes",
          newRoutes: "Total Routes",
        },
        goBack: "Go Back",
        finish: "Finish",
        continue: "Continue",
        closeModalWithInfo:
          "Close the route configuration, even if there is important information",
        routeRegistration: "Route Registration",
        loading: "Loading...",
      },
      registerRouteCard: {
        schema: "Schema",
      },
      registerRoutesAdmin: {
        searchByName: "Search by Name",
        registerRoute: "Routes Administration",
        loading: "Loading...",
        notRegisteredRouteYet: "No route configurations available yet",
        deleteRegisterRoute: "Delete route configuration",
        confirm: "Confirm",
      },
      step1NewRouteModal: {
        paramSelection: "FILTER SELECTION",
        column: "Column",
        selectColumn: "Select column...",
        values: "Values",
        chooseValue: "Choose value...",
        noFilterYet: "You haven't added any aditional filters yet.",
      },
      step2NewRouteModal: {
        volumenProjectionModeOptions: {
          averageMonthlyVolumePerClient: "Average Monthly Volume per Client",
          totalRoute: "Total Route",
        },
        adjustments: "Adjustments",
        volumeProjectionMode: "Volume Projection Mode",
        chooseProjectionMode: "Choose mode...",
        totalAverageMonthlyVolumePerRoute:
          "Total Average Monthly Volume per Route",
        averageMonthlyVolumePerClient: "Average Monthly Volume per Client",
        clientsPerRoute: "Clients per Route",
        averagePrice: "Average Price",
      },
      step3NewRouteModal: {
        totalRoutesResult: "TOTAL ROUTES RESULTS",
        actualRoutes: "Current Routes",
        routesToAdd: "Routes to Add",
        newRoutes: "Total Routes",
        cancelRoutes: "ROUTES TO CANCEL",
      },
      viewInfoRegisterRouteModal: {
        registerRouteInfo: "Route Configuration Information",
        loading: "Loading...",
        registerRouteName: "Route Configuration Name",
        simulationSchema: "Simulation Schema",
        numbersNewRoutes: "Number of New Routes",
        volumeProjectionMode: "Volume Projection Mode",
        requiredFilters: "Required Filters",
        aditionalFilters: "Aditional Filters",
        params: "Parameters",
        actualValue: "Actual Value",
        adjustmentValue: "Adjusted Value",
      },
      getColumnsRegisteredRoutes: {
        projectionMode: "PROJECTION MODE",
        routeRegistrations: "ROUTE REGISTRATIONS",
        customersByCurrentRoute: "CUSTOMERS BY CURRENT ROUTE",
        customersByAdjustedRoute: "CUSTOMERS BY ADJUSTED ROUTE",
        averagePrice: "AVERAGE PRICE",
        averageMonthlyVolumePerCurrentRoute:
          "TOTAL AVERAGE MONTHLY VOLUME BY CURRENT ROUTE",
        averageMonthlyVolumePerAdjustedRoute:
          "TOTAL AVERAGE MONTHLY VOLUME PER ROUTE ADJUSTED",
        currentAverageVolumePerClient:
          "AVERAGE MONTHLY VOLUME PER CURRENT CUSTOMER",
        adjustedAverageVolumePerClient:
          "AVERAGE MONTHLY VOLUME PER ADJUSTED CUSTOMER",
      },
    },
    canceledRoutes: {
      editingOptions: "EDITING OPTIONS",
      assignRetentionsModal: {
        assign: "Assign",
        salesRetentionPercentage: "SALES RETENTION PERCENTAGE",
      },
      modifyCancelRouteModal: {
        modifyRetentions: "Modify retentions",
        modify: "modify",
        assignToVarios: "ASSIGN TO VARIOUS",
        retentionPercentageInfo:
          "Sales retention percentage will be distributed to active routes",
      },
      newCancelRouteModal: {
        cancelRoute: "Route Cancelation",
        loading: "Loading...",
      },
      step1NewCancelRouteModal: {
        chooseReferenColumn: "Choose reference column",
        chosenCedis:
          "Once a reference column is selected, it will be applied to all other settings.",
      },
      step2NewCancelRouteModal: {
        routesSelection: "ROUTE SELECTION",
      },
      step3NewCancelRouteModal: {
        routesToCanceled: "ROUTES TO CANCEL",
      },
      step3ModelTable: {
        clients: "CLIENTS",
        salesRetention: "SALES RETENTION %",
      },
    },
    modifiedRoutes: {
      modifiedRoute: "Route Modification",
      step3NewModifyRouteModal: {
        noValuesYet: "You haven't assigned any value yet.",
        valueSelectedInfo:
          "After selecting the value(s), the route averages based on that/those selected value(s) will be displayed.",
      },
      modifyModificationRouteModal: {
        modifyRoutes: "Modify routes",
      },
    },
    simulationRules: {
      nonSimulationsYet: "No results available yet.",
      simulationResults: "Simulation Results",
    },
    simulationResultById: {
      previousMonth: "VS. PREVIOUS MONTH",
      noChangesSimulation: "VS. SIMULATED W/O CHANGES",
      graphics: "GRAPHICS",
      cleanFilters: "Clean filters",
      noFiltersYet: "No filters yet",
      loading: "Loading...",
      simulationResultCardById: {
        before: "BEFORE",
        after: "AFTER",
        variation: "VAR",
      },
      filterModal: {
        simulationResultFilter: "Filters for simulation result",
        cancel: "Cancel",
        apply: "Apply",
      },
      waterfallSimulationResult: {
        titleText: "Impact by P&L component",
      },
      columns: {
        measure: "MEASURE",
        previousMonth: "PREVIOUS MONTH",
        simulatedMonthwithoutchanges: "SIMULATED MONTH W/O CHANGES",
        simulatedMonth: "SIMULATED SCENARIO MONTH",
        percentageVariation: "PERCENTAGE VARIATION",
        absoluteVariation: "ABSOLUTE VARIATION",
      },
      results: "Results",
      simulationResultInfoModal: {
        routeConfiguration: "ROUTE CONFIGURATIONS",
        schema: "SCHEMA",
        type: "TYPE",
        costVariability: "COST VARIABILITY",
        ruleSimulation: "RULE SIMULATION",
      },
    },
    simulationSchema: {
      viewInfoSchemaModal: {
        schemSimulation: "Simulation Scheme",
        loading: "Loading...",
        name: "NAME",
        rule: "RULE",
        principalColumns: "MAIN COLUMNS",
        defaultIndicators: "DEFAULT INDICATORS",
        cards: "CARD FORMAT",
        personalizedIndicators: "CUSTOM INDICATORS",
        variability: "TABLE FORMAT",
      },
      simulationSchemaConfig: {
        steps: {
          principalColumns: "Main Columns",
          indicators: "Indicators in Card Format",
          kpis: "Indicators in Table Format",
        },
        schemaConfig: "Schema Configuration",
        loading: "Loading...",
        saving: "Saving...",
        cancel: "Cancel",
      },
      simulationSchemaAdmin: {
        schemaAdmin: "Simulation Schemes",
        deletingSchema: "Deleting schema...",
        noSchemasYet: "No schemes available yet.",
        deleteSchema: "delete simulation schema",
      },
      step3NewSimulationSchema: {
        defaultKpis: "Default KPIs",
        personalizedKpis: "Personalized KPIs",
        noPersonalizedKpisYet: "You have not added any Custom KPIs yet.",
        kpiTableExample: "KPIs table example",
        newKpi: "New Kpi",
      },
      step2NewSimulationSchema: {
        defaultIndicators: "Default Indicators",
        indicatorExample: "Indicator example",
        personalizedIndicators: "Personalized Indicators",
        noPersonalizedIndicators:
          "You have not added any Custom Indicators yet.",
        newIndicator: "New indicator",
      },
      step1NewSimulationSchema: {
        chooseReferenceColumn: "Choose reference column...",
        schemaName: "Schema name",
        schemaNameAlreadyExists: "A schema with that name already exists",
        principalColumns: "Main Columns",
        dependency: "Dependency",
      },
      personalizedIndicatorInfoModal: {
        close: "Close",
        operator: "Operator",
        function: "Function",
        denominators: "Denominators",
      },
      newPersonalizedKpiModal: {
        newKpi: "New Kpi",
        add: "Add",
        kpiName: "Kpi name",
        kpiNameAlreadyExists: "A kpi with this name already exists",
        numerator: "Numerators",
        chooseMeasures: "Choose measurements...",
        denominators: "Denominators",
        chooseDenominators: "Choose denominators...",
        denominatorsRepresentation:
          "The denominators represent the columns you selected in step 1.",
        measureUnit: "Unit of measure",
        chooseMeasureUnit: "Choose measurement unit...",
        newDenominator: "New Denominator",
      },
      newPersonalizedIndicatorModal: {
        measurement_units: {
          coin: "COIN",
          kilograms: "KILOGRAMS",
          units: "UNITS",
        },
        newIndicator: "New indicator",
        add: "Add",
        indicatorName: "Indicator name",
        indicatorNameAlreadyExists:
          "An indicator with this name already exists",
        unitOfMeasurement: "Unit of measurement",
        chooseUnitOfMeasurement: "Choose unit of measurement...",
      },
      newDenominatorModal: {
        newDenominator: "New denominator",
        add: "Add",
        denominatorName: "Name of the denominator",
        denominatorNameAlreadyExists:
          "A denominator with this name already exists",
        denominatorNameMatchesWithInformation:
          "Name cannot match a principal column",
        measures: "Measures",
        chooseMeasures: "Choose measurements...",
      },
    },
    routeAdministration:{
      getColumnsCancelRoutes: {
        route: "ROUTES",
        retentionSales: "SALES RETENTIONS %"
      }
    }
  },
  stagesSettings: {
    withoutStages: "There are groupers without assigned stages.",
    noFiles: "No files uploaded.",
    editName: {
      codes: {
        200: "Name edited successfully",
        400: "An error occurred while editing the name",
      },
    },
  },
  tablesManagement: {
    addTable: {
      codes: {
        201: "The table has been created successfully",
        400: "An error occurred and it was not possible to create the table",
      },
    },
    editTable: {
      codes: {
        200: "The table has been edited successfully",
        400: "An error occurred and it was not possible to edit the table",
      },
    },
    deleteTable: {
      codes: {
        200: "The table has been deleted successfully",
        400: "An error occurred and it was not possible to delete the table",
      },
    },
    putState: {
      codes: {
        200: "The state has been modified successfully",
        201: "The state has been modified successfully",
        400: "An error occurred and it was not possible to modify the state",
      },
    },
    getGroup: {
      error: "An error occurred and it was not possible to retrieve the group",
    },
  },
  tablesTools: {
    errorRows: "An error occurred and data could not be loaded.",
    noRows: "No data available.",
    transformDataSimpleTable: {
      message: "Message",
    },
  },
  tabs: {
    preproration: "PREPRORATION",
    proration: "PRORATION",
    downloads: "DOWNLOADS",
    datamodel: "DATAMODEL",
    simulation: "SIMULATION",
    salesForecast: "SALES FORECAST",
    marketBasket: "MARKET BASKET",
  },
  taskManager: {
    loadingCancelTask: "Cancelling task...",
    loadingDownloadFile: "Downloading...",
    error:
      "An error occurred and it was not possible to download the information.",
    errorDetail: "Error Detail",
    ejecutionDetail: "Ejecution Detail",
    noDetailYet: "Aún no tenemos información detallada disponible...",
    cancelTitle: "Cancel Task",
    cancelThisTask: "Cancel this task",
    saveViewButtonTooltip:
      "Save column width and visibility configuration for this user.",
    getTasks: {
      error: "An error occurred and it was not possible to retrieve tasks",
    },
    getDownloads: {
      error: "An error occurred and it was not possible to retrieve downloads",
    },
    getSimulations: {
      error:
        "An error occurred and it was not possible to retrieve simulations",
    },
    getSalesForecast: {
      error:
        "An error occurred and it was not possible to retrieve sales forecast",
    },
    cancelTask: {
      codes: {
        200: "Task canceled successfully",
        201: "Task canceled successfully",
        204: "Task canceled successfully",
        400: "An error occurred and it was not possible to cancel the task",
        500: "An error occurred and it was not possible to cancel the task",
      },
    },
    getTaskDetails: {
      codes: {
        200: "Task details retrieved",
        400: "An error occurred and it was not possible to retrieve task details",
      },
      loading: "Retrieving task details...",
    },
    states: {
      pending: "Pending",
      running: "Running",
      finishedWithErrors: "Finished with errors",
      finishedSuccessfully: "Finished successfully",
      error: "Calculation error",
      downloadError: "Download error",
      canceled: "Canceled",
    },
    actions: {
      viewDetail: "View Detail",
      notViewYet: "No detail yet",
      cancel: "Cancel",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
    },
  },
  titles: {
    taskManager: "Task Manager",
  },
  validations: {
    validationsAdmin: {
      title: "Validations management",
      tabs: {
        codeMatching: "CODE MATCHING",
      },
      notCodeMatchingYet: "No code matching yet available.",
      getCodeMatching: {
        error: "An error occurred and it was not possible to retrieve codes",
        loading: "Getting codes...",
      },
      codeMatchingCard: {
        file1: "File 1:",
        file2: "File 2:",
        matchIndicator: "Indicator:",
        completeMatchIndicator: "Match Indicator:",
      },
      viewInfoModal: {
        title: "General Validation Information",
      },
      deleteValidation: {
        codes: {
          200: "Validation successfully deleted",
          400: "An error occurred, and the validation could not be deleted",
        },
        loading: "Deleting validation...",
        confirmationModal: {
          title: "Delete validation",
          message: "Do you confirm that you want to delete the validation?",
        },
      },
    },
    configValidations: {
      subtitleColumns: "Columns",
      titleIndicatorMatchSelect: "Indicator Match Select",
      matchName: "Code Matching Name",
      errorEmptyName: "The name cannot be empty",
      errorDuplicatedName: "The name already exists",
      textIndicatorMatchSelect: {
        percentaje: "% of codes from",
        in: "in",
      },
      placeholderIndicatorMatchSelect: "Select crossover indicator...",
      codes: {
        200: "Validation saved successfully.",
        201: "Validation saved successfully.",
        400: "An error occurred, and it was not possible to save the validation.",
      },
      calculate: {
        codes: {
          200: "Validation calculated successfully",
          201: "Validation calculated successfully",
          400: "An error occurred, and it was not possible to calculate the validation",
        },
      },
      loading: "Saving validation...",
      calculating: "Calculating...",
      confirmationModal: {
        title: "Save validation",
        message: "Do you confirm that you want to save the validation?",
      },
      notEqualColumnsLength:
        "The number of selected columns must be equal for both files.",
      getValidation: {
        codes: {
          200: "Validation obtained successfully",
          400: "An error occurred, and it was not possible to obtain the validation",
        },
        loading: "Obtaining validation...",
      },
    },
    matchCodeResults: {
      getValidations: {
        error:
          "An error occurred and it was not possible to retrieve validations",
      },
      getVennModalInfo: {
        codes: {
          200: "Information obtained successfully",
          400: "An error occurred, and it was not possible to obtain the information",
        },
        loading: "Obtaining information...",
      },
    },
  },
  verificationCode: {
    codes: {
      200: "The code is correct",
      400: "The code is incorrect",
      500: "There was an error and the code could not be sent, please try again",
    },
    loading: "Sending authentication code...",
    resend: {
      codes: {
        200: "The code has been successfully resent",
        400: "The code could not be resent",
        500: "There was an error and the code could not be resent, please try again",
      },
    },
  },
  virtualizedTable: {
    saveViewButton: "Save View",
    transformData: {
      appliedSchema: "APPLIED_SCHEMA",
      totalExpense: "TOTAL_EXPENSE",
    },
    toolbars: {
      columns: "Columns",
      selectColumns: "Select columns",
      findColumn: "Find column",
      columnTitle: "Column title",
      reorderColum: "Reorder column",
      columnsPanelShowAllButton: "Show all",
      columnsPanelHideAllButton: "Hide all",
      filters: "filters",
      filterPlaceHolder: "Filter values",
      addFilter: "Add filter",
      filterHide: "Hide filters",
      filterShow: "Show filters",
      filterOperator: "Operator",
      andFilter: "And",
      orFilter: "Or",
      filterInputLabel: "Value",
      density: "density",
      compact: "compact",
      standard: "standard",
      comfortable: "comfortable",
      export: "export",
      print: "Print",
      asCSV: "Download as CSV",
    },
  },
  unauthorized: {
    title: "Unauthorized",
    message: "You do not have permission to access this page",
    goBack: "Go Back to Homepage",
  },
  useLogout: {
    errorLogout: "Error signing out",
  },
};
