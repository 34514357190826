import { CustomToolbar } from "./CustomToolbar";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { getGroupingColDef } from "./getGroupingColDef";
import { GlobalStyles } from "@mui/material";
import { useParameterization } from "../../../../hooks/useParameterization";
import { useEffect, useState } from "react";
import CustomCheckbox from "./CustomCheckbox";
import CustomNoRowsOverlay from "../../../tablesTools/NoRows";
import EditNameModal from "./EditNameModal";
import ErrorRows from "../../../tablesTools/ErrorRows";
import useDialog from "../../../../hooks/useDialog";
import useLanguage from "../../../../context/LanguageProvider";
import { getModelColumns, getTreeDataPath } from "./modelColumns";
import {
  checkRowsWithoutAssignedCriteria,
  selectAllRows,
  selectRowsWithoutCriteria,
  updateGroupedSelection,
} from "../../utils";
import {
  table_modal_global_styles,
  table_modal_styles2,
} from "../../../../styles/app-styles";

export type ModelRowType = {
  id: number;
  hierarchy: string[];
  agrupador_id: number;
  desglosados_id: number;
  destino_anterior: string;
  receptor_destino: string;
  destino: string;
  monto: number;
  receptor_criterio: string;
  criterio_prorrateo: string;
};
interface ITreeDataCustomGroupingColumn {
  rowsResult: any;
  total: number;
  refetchRowsData: () => void;
  error: boolean;
  iteration?: any | undefined;
}

export default function ModelTable({
  iteration,
  error,
  rowsResult,
  total,
  refetchRowsData,
}: ITreeDataCustomGroupingColumn) {
  const { t } = useLanguage();
  const {
    parameterizationState,
    parameterizationDispatch,
    handleToggleEditCriteriaModal,
  } = useParameterization();
  const {
    tabValue,
    selectionModel,
    isExpanded,
    bucketSelectedOption,
    selectedStage,
  } = parameterizationState;
  const apiRef = useGridApiRef();
  const [openEditNameDialog, handleToggleEditNameDialog] = useDialog();
  const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
  const [selectedBuckets, setSelectedBuckets] = useState<any[]>([]);

  console.log(rowsResult, "rowsResult en ModelTable");
  

  /*************************** Modelo de selección ****************************/

  /**
   * Maneja la selección de filas según el modelo de selección proporcionado.
   *
   * @param {Array} newSelectionModel - Modelo de selección conteniendo identificadores de filas seleccionadas.
   */

  const handleSelection = (newSelectionModel: any[]) => {
    if (rowsResult?.length) {
      const ids = new Set(newSelectionModel);
      const selectedRowData = rowsResult.filter((row: any) =>
        ids.has(row["id"])
      );
      updateGroupedSelection(
        selectedRowData,
        selectedBuckets,
        rowsResult,
        parameterizationDispatch,
        setSelectedBuckets
      );
    }
  };

  const handleExpand = () => {
    parameterizationDispatch({
      type: "HANDLE_EXPAND",
    });
  };

  /********************************* Editar nombre **********************************/

  const handleOpenEditDialog = (id: number, value: string) => {
    parameterizationDispatch({
      type: "SET_NEW_NAME",
      payload: value,
    });
    setIdToEdit(id);
    handleToggleEditNameDialog();
  };

  /********************************* Criterios ***************************************/

  const handleCopyCriteriaModal = (criteria: any) => {
    parameterizationDispatch({
      type: "SET_CONFIG_MODE",
      payload: "COPY",
    });
    parameterizationDispatch({
      type: "SET_CRITERIA",
      payload: criteria,
    });
    handleToggleEditCriteriaModal();
  };

  const setRowCopyCriteria = (row: any) => {
    parameterizationDispatch({
      type: "SET_ROW_COPY_CRITERIA",
      payload: row,
    });
  };

  //Se encarga de verificar si hay filas sin criterio asignado cuando cambian las rows
  useEffect(() => {
    if (rowsResult) {
      if (
        tabValue === "GASTOS NO TRASLADADOS"
      ) {
        parameterizationDispatch({
          type: "SET_NT_ROWS_WITHOUT_ASSIGNED_CRITERIA",
          payload: checkRowsWithoutAssignedCriteria(rowsResult),
        });
      } else {
        parameterizationDispatch({
          type: "SET_ROWS_WITHOUT_ASSIGNED_CRITERIA",
          payload: checkRowsWithoutAssignedCriteria(rowsResult),
        });
      }
    }
  }, [rowsResult, tabValue]);

  /********************************* Columnas tabla **********************************/

  const getModelTableColumns = () => {
    return selectedStage
      ? tabValue ===
        "GASTOS NO TRASLADADOS"
        ? getModelColumns(
            rowsResult,
            selectedStage,
            handleCopyCriteriaModal,
            setRowCopyCriteria,
            tabValue
          )
        : getModelColumns(
            rowsResult,
            selectedStage,
            handleCopyCriteriaModal,
            setRowCopyCriteria
          )
      : [];
  };

  return (
    <div style={{ minHeight: "300px", width: "100%" }}>
      <GlobalStyles styles={table_modal_global_styles} />
      <DataGridPro
        autoHeight
        components={{
          BaseCheckbox: CustomCheckbox,
          NoRowsOverlay: error !== null ? ErrorRows : CustomNoRowsOverlay,
          Toolbar: () => (
            <CustomToolbar
              columnsButton={true}
              refetchRowsData={refetchRowsData}
              rows={rowsResult}
              total={total}
            />
          ),
        }}
        componentsProps={{
          baseCheckbox: {
            selectallrows: { selectAllRows },
            selectrowswithoutcriteria: { selectRowsWithoutCriteria },
            criteria: "true",
            rowsresult: rowsResult,
          },
          toolbar: { csvOptions: { allColumns: true } },
          panel: {
            sx: {
              [`& .MuiDataGrid-columnsPanel > div:first-of-type`]: {
                display: "none",
              },
            },
          },
        }}
        checkboxSelection
        disableSelectionOnClick
        treeData
        apiRef={apiRef}
        rows={rowsResult ? rowsResult : []}
        columns={getModelTableColumns()}
        getTreeDataPath={getTreeDataPath}
        groupingColDef={getGroupingColDef(
          handleOpenEditDialog,
          handleExpand,
          isExpanded,
          rowsResult,
          bucketSelectedOption,
          selectedStage
        )}
        hideFooter
        onSelectionModelChange={(newSelectionModel) => {
          handleSelection(newSelectionModel);
        }}
        selectionModel={selectionModel}
        disableVirtualization
        sx={table_modal_styles2(rowsResult)}
      />
      <EditNameModal
        openEditNameDialog={openEditNameDialog}
        handleToggleEditNameDialog={handleToggleEditNameDialog}
        refetchRowsData={refetchRowsData}
        idToEdit={idToEdit}
        rowsResult={rowsResult}
      />
    </div>
  );
}
