import { Box } from "@mui/material";
import { form_subtitle_styles } from "../../../styles/app-styles";
import { transformDataSimpleTable } from "../../tablesTools/transformDataSimpleTable";
import Stack from "@mui/material/Stack/Stack";
import useLanguage from "../../../context/LanguageProvider";
import VirtualizatedTable from "../../virtualizatedTable/VirtualizedTable";

const DashboardTable = ({ id, dataGetElement }) => {
  const { t } = useLanguage();

  return (
    <Stack width="100%">
      <Box
        sx={{
          ...form_subtitle_styles,
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        {dataGetElement?.nombre || ""}
      </Box>
      <VirtualizatedTable
        columns={
          transformDataSimpleTable(
            dataGetElement?.data,
            undefined,
            undefined,
            t
          ).columns
        }
        rows={
          transformDataSimpleTable(
            dataGetElement?.data,
            undefined,
            undefined,
            t
          ).rows
        }
        rowsPerPageOptions={[5, 10, 20]}
        rowHeight={35}
        tableHeight={"75vh"}
        hideFooter={true}
        toolbar={true}
        columnsButton={true}
        filterButton={true}
        exportButton={true}
        setSelectedRows={() => <></>}
        checkboxSelection={false}
      />
    </Stack>
  );
};

export default DashboardTable;
