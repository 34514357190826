import { checkArraysOverlap, handleRefetchRowsDataNt } from "../../utils";
import { useParameterization } from "../../../../hooks/useParameterization";
import ModelTable from "./ModelTable";

const ExpensesTable = () => {
  const {
    parameterizationState,
    parameterizationDispatch,
    rowsNt,
    errorRowsNt,
    getProrationResultsNoTrasladados
  } = useParameterization();
  const { selectedIteration } = parameterizationState;

  const rowsResult =
    rowsNt && !!selectedIteration && !!selectedIteration?.iteracion &&
    checkArraysOverlap(
      rowsNt?.resultado?.map((rowNt: any) => rowNt.hierarchy)
    ) === false &&
    rowsNt.resultado;

  const refetchRowsDataNT = () => {
    handleRefetchRowsDataNt(
      parameterizationState,
      parameterizationDispatch,
      getProrationResultsNoTrasladados
    );
  };

  return (
    <ModelTable
      iteration={selectedIteration?.iteracion}
      rowsResult={rowsResult}
      total={rowsNt && rowsNt.monto_total}
      refetchRowsData={refetchRowsDataNT}
      error={errorRowsNt}
    />
  );
};

export default ExpensesTable;
